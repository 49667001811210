// Dating_new.jsx
import React from 'react';
import ProfileView from "./ProfileView/ProfileView";
import DatingSettings from "./Settings/Settings";
import "./Dating_new.scss";
import DatingNavbarTop from "./Navbar/DatingNavbarTop";
import { MobileToggleContext } from './context/mobileToggleContext';

const Dating_new = () => {
    return (
        <div className="Dating_new">
            <MobileToggleContext>
                <DatingSettings />
                <ProfileView />
            </MobileToggleContext>
        </div>
    )
}

export default Dating_new;
