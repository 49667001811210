
import React, {useState} from 'react';
import './UserSettings.scss';
import SNavbar from './SNavbar/SNavbar';
import TabButtons from './TabButtons/TabButtons';
import TabContents from './TabContents/TabContents';

const Settings = () => {
    const [buttonClickState, setButtonClickState] = useState('Profile');
    const handleButtonClick = (buttonName) => {
        setButtonClickState(buttonName);
    };

    return (
        <div className="UserSettings">
            <SNavbar/>

            <div className="controls">

                <TabButtons handleButtonClick={handleButtonClick}/>

                <TabContents buttonClickState={buttonClickState}/>
            </div>
        </div>
    );
};

export default Settings;