import React, { useState, useEffect } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase";
import "../style/OldNews.scss";

const OldNews = () => {
    const [newsData, setNewsData] = useState([]);
    const [filteredNewsData, setFilteredNewsData] = useState([]);

    useEffect(() => {
        const fetchNewsData = async () => {
            try {
                const newsCollectionRef = collection(db, "news");
                const querySnapshot = await getDocs(newsCollectionRef);
                const newsArray = querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));

                setNewsData(newsArray);
                setFilteredNewsData(newsArray); // Initialize filtered data with all news
            } catch (error) {
                console.error("Error fetching news data:", error);
            }
        };

        fetchNewsData();
    }, []);

    const CurrentDate = () => {
        const date = new Date();
        const day = String(date.getDate()).padStart(2, "0");
        const month = getMonthName(date.getMonth());
        const year = date.getFullYear();
        const hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, "0");
        const period = hours >= 12 ? "PM" : "AM";
        const formattedHours = (hours % 12) || 12;

        return `${day} ${month} ${year} - ${formattedHours}:${minutes} ${period}`;
    };

    const handleFilterNews = (method, value) => {
        const filteredNews = newsData.map((newsItem) => ({
            ...newsItem,
            news: newsItem.news.filter((news) => news[method] === value && news.isPublished === true),
        }));
        setFilteredNewsData(filteredNews); // Update filteredNewsData instead of newsData
    };

    const handleClearFilters = () => {
        setFilteredNewsData(newsData); // Reset filteredNewsData to all news
    };

    const formatNewsDate = (timestamp) => {
        if (!timestamp) {
            return "Invalid Date";
        }

        const date = new Date(timestamp);
        const day = String(date.getDate()).padStart(2, "0");
        const month = getMonthName(date.getMonth());
        const year = date.getFullYear();
        const hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, "0");
        const period = hours >= 12 ? "PM" : "AM";
        const formattedHours = (hours % 12) || 12;

        return `${day} ${month} ${year} - ${formattedHours}:${minutes} ${period}`;
    };

    const getMonthName = (month) => {
        const monthNames = [
            "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];
        return monthNames[month];
    };

    return (
        <div className="oldNewsage">
            <div className="news-full-body">

                <div className="news-paper">
                    <div className="news-paper-psudo">
                        <div className="news-paper-title">
                            <h3 style={{ fontWeight: 900 }}>Campus Hush-Fush</h3>
                            <p className="date">{ CurrentDate() }</p>
                            <div className="filter">
                                {newsData && newsData.length > 0 && (
                                    <>
                                        <button onClick={() => handleClearFilters()}>Home</button>
                                        {newsData.map((newsItem) => {
                                            const renderedNewsTypes = new Set(); // Keep track of rendered news types
                                            return newsItem.news
                                                .filter((news) => news.isPublished === true)
                                                .filter((news) => news.newsType !== "Confession")
                                                .map((news) => {
                                                    if (!renderedNewsTypes.has(news.newsType)) {
                                                        renderedNewsTypes.add(news.newsType);
                                                        return (
                                                            <button key={news.id} onClick={() => handleFilterNews("newsType", news.newsType)}>
                                                                {news.newsType}
                                                            </button>
                                                        );
                                                    }
                                                    return null; // Render nothing for already rendered news types
                                                });
                                        })}
                                    </>
                                )}
                            </div>
                            <div className="marqueeOuter">
                                <div className="trending-tag">
                                    Today's Trending News
                                </div>
                                <marquee behavior="scroll" direction="left" scrollamount="5">
                                    <p className="marquee">
                                        Exclusive! Campus Hush-Fush, Developed by Prashant Kumar, Set to Unveil Beta Version App on 03 Aug 2023 – Be Ready for a Sneak Peek Into Campus Secrets, Despite Potential Lag and Data Resets!
                                    </p>
                                </marquee>
                            </div>

                        </div>
                        <div className="news-content">
                            <div className="slider">
                                <div className="slide-track">
                                    <div className="slide">
                                        <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/1.png" height="100" width="250" alt="" />
                                    </div>
                                    <div className="slide">
                                        <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/2.png" height="100" width="250" alt="" />
                                    </div>
                                    <div className="slide">
                                        <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/3.png" height="100" width="250" alt="" />
                                    </div>
                                    <div className="slide">
                                        <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/4.png" height="100" width="250" alt="" />
                                    </div>
                                    <div className="slide">
                                        <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/5.png" height="100" width="250" alt="" />
                                    </div>
                                    <div className="slide">
                                        <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/6.png" height="100" width="250" alt="" />
                                    </div>
                                    <div className="slide">
                                        <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/7.png" height="100" width="250" alt="" />
                                    </div>
                                    <div className="slide">
                                        <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/1.png" height="100" width="250" alt="" />
                                    </div>
                                    <div className="slide">
                                        <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/2.png" height="100" width="250" alt="" />
                                    </div>
                                    <div className="slide">
                                        <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/3.png" height="100" width="250" alt="" />
                                    </div>
                                    <div className="slide">
                                        <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/4.png" height="100" width="250" alt="" />
                                    </div>
                                    <div className="slide">
                                        <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/5.png" height="100" width="250" alt="" />
                                    </div>
                                    <div className="slide">
                                        <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/6.png" height="100" width="250" alt="" />
                                    </div>
                                    <div className="slide">
                                        <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/7.png" height="100" width="250" alt="" />
                                    </div>
                                </div>
                            </div>

                            <div className="news-posts">
                                {/* Use the filteredNewsData array to populate the div */}
                                {filteredNewsData && filteredNewsData.length > 0 ? (
                                    filteredNewsData
                                        .sort((a, b) => b.id.localeCompare(a.id)) // Sort by news item ID in descending order
                                        .map((newsItem) => {
                                            const sortedNews = newsItem.news
                                                .filter((news) => news.isPublished === true && news.newsType !== "Confession")
                                                .sort((a, b) => b.date - a.date); // Sort news items by date in descending order

                                            return (
                                                <div key={newsItem.id} className="news-Card">
                                                    {sortedNews.map((news) => (
                                                        <div key={news.id} className="news-card-content">
                                                            {news.coverImage && (
                                                                <div className="top">
                                                                    <img src={news.coverImage} alt="" />
                                                                </div>
                                                            )}

                                                            <div className="bottom">
                                                                <div className="author-time">
                                                                    <p className="newstime">
                                                                        {news.date ? formatNewsDate(news.date) : "Invalid Date"}
                                                                    </p>

                                                                    <p className="newsauthor">{news.author}</p>
                                                                </div>
                                                                <h2 className="newshead">{news.newsheading}</h2>
                                                                <p className="newstype">{news.newsType}</p>
                                                                <p className="newsContent">{news.newsContent}</p>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            );
                                        })
                                ) : (
                                    <p>No news data available.</p>
                                )}

                            </div>

                        </div>
                    </div>

                </div>
            </div>


        </div>
    );
};

export default OldNews;