import React, { useState } from 'react';

const Food_OrderSummary = () => {
    const [isCollapsed, setIsCollapsed] = useState(false);

    const ToggleWidth = () => {
        const orderSummaryElement = document.querySelector('.order-summary');
        const contentElement = document.querySelector('.content');

        if (isCollapsed) {
            orderSummaryElement.style.width = '20%';
            contentElement.style.display = 'block';
            orderSummaryElement.style.backgroundSize = "100%";
        } else {
            orderSummaryElement.style.width = '1%';
            orderSummaryElement.style.backgroundSize = "300%";
            contentElement.style.display = 'none';
        }

        setIsCollapsed(!isCollapsed);
    }

    return (
        <div className="order-summary" style={{ width: '20%', transition: 'width 0.3s' }}>
            <i className='fa fa-bars' aria-hidden='true' onClick={ToggleWidth}></i>
            <div className="content">
                <h3> My Order </h3>
                <img src="https://cdni.iconscout.com/illustration/premium/thumb/empty-cart-4816550-4004141.png?f=webp" alt="empty" />
                <p>Your cart is empty and no history found.</p>
                <button>
                    Continue Shopping <i className='fa fa-shopping-cart' aria-hidden='true'></i>
                </button>
            </div>
        </div>
    );
};

export default Food_OrderSummary;
