const { createSlice } = require("@reduxjs/toolkit");

export const RoadMapSelectSlice = createSlice({
    name: "RoadMapSelect",
    initialState: {
        stage: 0,
    },
    reducers: {
        setStage: (state, action) => {
            state.stage = action.payload;
        },
    }
});
export const {setStage} = RoadMapSelectSlice.actions;
export default RoadMapSelectSlice.reducer;
