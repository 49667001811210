import React from "react";
import { Icon } from '@iconify/react';
import "../../style/GroupChat/RoomSettings.scss";

const RoomSettings = ({ ROOM_CODE }) => {

    const instagramlink = () => {
        window.open('https://www.instagram.com/unidating_uc', '_blank');
    }

    const youtubeLink =  () =>{
        window.open("https://www.youtube.com/channel/UCUh3A9fQkuTRtmVVRf3O_Ng?sub_confirmation=1&feature=subscribe-embed-click", '_blank')
    }

    return (
        <div className="RoomSettings">
            <div className="roomMode">
                <input type="text" name="roomCode" value={ROOM_CODE} />
                <input type="text" name="roomName" value={ROOM_CODE.slice(0, -5)} />
                <button>Set Public</button>
            </div>

            <div className="share">
                <p onClick={youtubeLink}>
                    <Icon icon="logos:youtube-icon" />
                </p>
                <p onClick={instagramlink}>
                    <Icon icon="skill-icons:instagram" />
                </p>
            </div>

        </div>
    );
};

export default RoomSettings;
