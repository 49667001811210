import React from "react";

import "../style/Shop.scss";
import foodBeingPrepared from "../img/shop.png"

const Shop = () => {
    return (
        <div className="Shop">
            <div className="main">
                <img src={foodBeingPrepared} alt="no food today" />
                <h3>Your One-Stop Marketplace for Selling, Exchanging, and Borrowing!</h3>
                <p>We're crafting a hub for selling, borrowing, exchanging, and exploring more. Stay tuned! If you're a React, Firebase, SCSS whiz with great UI/UX knowledge, you can work with me to make it happen!</p>
                <button onClick={()=>{window.open("https://www.linkedin.com/in/03prashantpk/", "_blank")}}>Contact</button>
            </div>

        </div>
    );
}

export default Shop;