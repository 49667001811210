import React, { useContext, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import "../style/Support.scss";


const formatTime = (date) => {
  const hours = date.getHours() % 12 || 12;
  const minutes = date.getMinutes();
  const ampm = date.getHours() >= 12 ? "pm" : "am";
  return `${hours}:${minutes < 10 ? "0" : ""}${minutes}${ampm}`;
};

const Support = () => {
  const { currentUser } = useContext(AuthContext);
  const [name, setName] = useState(currentUser ? currentUser.displayName : "Full Name");
  const [email, setEmail] = useState(currentUser ? currentUser.email : "");
  const [message, setMessage] = useState("");
  const [formData, setFormData] = useState({
    type: "",
    title: "",
    name: "",
    email: "",
    description: "",

  });

  const handleInputChange = (event) => {
    const { id, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [id]: value }));
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    setMessage("Sending Please Wait...");

    const { type, title, description } = formData;

    if (!type || !title || !description) {
      setMessage("Please fill in all fields.");
      return;
    }

    const currentDateTime = new Date();
    const formattedDate = `${currentDateTime.getDate()} ${new Intl.DateTimeFormat("en-US", {
      month: "long"
    }).format(currentDateTime)} ${currentDateTime.getFullYear()} - ${formatTime(currentDateTime)}`;

    const supportData = {
      name: name,
      email: email,
      type: type,
      title: title,
      description: description,
      date: formattedDate,
      resolved: false,
    };

    try {
      const response = await fetch("https://unichatbackend.onrender.com/support", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(supportData),
      });

      if (response.ok) {
        const result = await response.text();
        setMessage(result);
        setFormData({
          type: "",
          title: "",
          name: "",
          email: "",
          description: "",

        });
      } else {
        setMessage("Something went wrong. Please try again later.");
      }
    } catch (error) {
      setMessage("Something went wrong. Please try again later.");
    }
  };

  return (
    <div className="support">
      <div className="support-container">
        <div className="title">
          <h4>Support</h4>
          <p>Report a Bug or Request a feature</p>
        </div>

        <div className="form">
          <form onSubmit={handleFormSubmit}>
            <div className="form-group">
              <label htmlFor="type">I would Like to</label>
              <select id="type" value={formData.type} onChange={handleInputChange} required>
                <option value="">Select One</option>
                <option value="Reported A Bug">Report a Bug</option>
                <option value="Delete My Account">Delete Account</option>
                <option value="Requested A Feature">Request a Feature</option>
                <option value="Other">Other</option>
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="title">Title:</label>
              <input type="text" id="title" value={formData.title} onChange={handleInputChange} placeholder="Write a title" required />
            </div>

            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <input type="email" id="email" value={email} onChange={(event) => setEmail(event.target.value)} placeholder="The email you are attempting to register." required />
            </div>

            <div className="form-group">
              <label htmlFor="name">Name:</label>
              <input type="text" id="name" value={name} onChange={(event) => setName(event.target.value)} placeholder="The name you are attempting to register." />
            </div>

            <div className="form-group">
              <label htmlFor="description">Description:</label>
              <textarea id="description" value={formData.description} onChange={handleInputChange} cols="25" rows="4" placeholder="Describe..." required></textarea>
            </div>

            <button type="submit">Submit</button>

            {message && <p className="message">{message}</p>}
            <p className="faq">Unable to register? <a href="./doc/unableToRegister.pdf" target="_blank" rel="noopener noreferrer">Read This</a></p>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Support;
