import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../context/AuthContext";
import { doc, setDoc, updateDoc, arrayUnion, getDoc } from "firebase/firestore";
import { db } from "../firebase";
import "../style/setConfession.scss";
// import unique id
import { v4 as uuidv4 } from "uuid";

const SetConfession = ({ setSetvisibility }) => {
    const { userDataFromDb } = useContext(AuthContext);
    const [confessionText, setConfessionText] = useState("");
    const [selectedSharedAs, setSelectedSharedAs] = useState("");
    const [selectedType, setSelectedType] = useState("");
    const [alertMessage, setAlertMessage] = useState("");
    const [countWords, setCountWords] = useState(0);
    const [isSaving, setIsSaving] = useState(false);
    const closePopUp = () => setSetvisibility(false);

    const countWord = (str) => {
        const words = str.split(/\s+/).filter(word => word.length > 0);
        setCountWords(words.length);
    }

    const handleConfessionSubmit = async () => {
        if (!selectedSharedAs || !selectedType) {
            setAlertMessage("Please fill in all required fields correctly.");
            return;
        }
        if (countWords > 200) {
            setAlertMessage("Confession should be less than 200 words.");
            return;
        }

        if(countWords < 10){
            setAlertMessage("Confession cannot be less than 10 words.");
            return;
        }

        setIsSaving(true);

        const confessionData = {
            id: uuidv4(),
            shared_as: selectedSharedAs,
            type: selectedType,
            uid: userDataFromDb.uid,
            date: Date.now(),
            confession: confessionText,
            likes: 0,
            isApproved: false
        };

        const confessionRef = doc(db, "confession", "confessions_data");

        try {
            const confessionDoc = await getDoc(confessionRef);
            if (confessionDoc.exists()) {
                await updateDoc(confessionRef, {
                    confessions: arrayUnion(confessionData)
                });
            } else {
                await setDoc(confessionRef, {
                    confessions: [confessionData]
                });
            }

            setAlertMessage("Confession submitted successfully!");
        } catch (error) {
            console.error("Error submitting confession:", error);
            setAlertMessage("An error occurred while submitting the confession.");
        }

        setIsSaving(false);
        setConfessionText("");
        setSelectedSharedAs("");
        setSelectedType("");
    }


    useEffect(() => {
        const alertClearInterval = setInterval(() => {
            if (alertMessage !== "") {
                setAlertMessage("");
            }
        }, 3000);
    
        return () => clearInterval(alertClearInterval);
    }, [alertMessage]);

    return (
        <div className="setConfession">
            <div className="inner">
                <button className="close" onClick={closePopUp}><i className="fal fa-window-close"></i></button>
                <p>Share Your Secrets and Unburden Your Soul!</p>
                <div className="form-inputs border">
                    <p>
                        <input
                            type="checkbox"
                            id={userDataFromDb.displayName}
                            checked={selectedSharedAs === userDataFromDb.displayName}
                            onChange={() => setSelectedSharedAs(userDataFromDb.displayName)}
                        />
                        <label htmlFor={userDataFromDb.displayName}>As {userDataFromDb.displayName}</label>
                    </p>
                    <p>
                        <input
                            type="checkbox"
                            id="Anonymous"
                            checked={selectedSharedAs === "Anonymous"}
                            onChange={() => setSelectedSharedAs("Anonymous")}
                        />
                        <label htmlFor="Anonymous">As Anonymous</label>
                    </p>
                </div>
                <div className="form-inputs">
                    <p>
                        <input
                            className="type"
                            type="checkbox"
                            id="Love"
                            checked={selectedType === "Love"}
                            onChange={() => setSelectedType("Love")}
                        />
                        <label htmlFor="Love">Love</label>
                    </p>
                    <p>
                        <input
                            className="type"
                            type="checkbox"
                            id="Anger"
                            checked={selectedType === "Anger"}
                            onChange={() => setSelectedType("Anger")}
                        />
                        <label htmlFor="Anger">Anger</label>
                    </p>
                    <p>
                        <input
                            className="type"
                            type="checkbox"
                            id="Sad"
                            checked={selectedType === "Sad"}
                            onChange={() => setSelectedType("Sad")}
                        />
                        <label htmlFor="Sad">Sad</label>
                    </p>
                    <p>
                        <input
                            className="type"
                            type="checkbox"
                            id="Happy"
                            checked={selectedType === "Happy"}
                            onChange={() => setSelectedType("Happy")}
                        />
                        <label htmlFor="Happy">Happy</label>
                    </p>
                    <p>
                        <input
                            className="type"
                            type="checkbox"
                            id="Other"
                            checked={selectedType === "Other"}
                            onChange={() => setSelectedType("Other")}
                        />
                        <label htmlFor="Other">Other</label>
                    </p>
                    <p>
                        <input
                            className="type"
                            type="checkbox"
                            id="IDontKnow"
                            checked={selectedType === "IDontKnow"}
                            onChange={() => setSelectedType("IDontKnow")}
                        />
                        <label htmlFor="IDontKnow">I don't know</label>
                    </p>
                </div>
                <div className="form-inputs">
                    <textarea
                        value={confessionText}
                        onChange={(e) => {
                            const sanitizedInput = e.target.value.replace(/<\/?[^>]+(>|$)/g, "");
                            setConfessionText(sanitizedInput);
                            countWord(sanitizedInput);
                        }}
                        cols="30"
                        rows="10"
                    ></textarea>
                    {confessionText !== "" && /<[^>]+>/.test(confessionText) && (
                        <p style={{ color: "red" }}>Codes are not allowed</p>
                    )}
                </div>
                <button onClick={handleConfessionSubmit} disabled={isSaving}>
                    {isSaving ? "Saving..." : "I Confess"}
                </button>
                {alertMessage && <div className="alert">{alertMessage}</div>}
            </div>
        </div>
    )
}

export default SetConfession;
