import React, { useState } from "react";
import "../../style/GroupChat/ShowToast.scss";

function ShowToast(props) {
  const { key, data } = props;
  
  return (
    <div className="show__toast">
      <p >{data}</p>
    </div>
  );
}

export default ShowToast;
