import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Food_Item from './Food_item';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';

const placeholder = 'https://via.placeholder.com/150';

const universities = [
  { id: 'lovely-professional-university', name: 'Lovely Professional University', image: "https://www.lpu.in/about_lpu/images/infra/acedemics/large/school.jpg" },
  { id: 'gla-university-mathura', name: 'GLA Mathura', image: "https://images.shiksha.com/mediadata/images/articles/1663131815phpZ3WZxB.jpeg" },
];

const Food_MostPopular = () => {
  const navigate = useNavigate();
  const [university, setUniversity] = useState(universities[0].id);

  const handleUniversityChange = (event) => {
    const selectedUniversity = event.target.value;
    setUniversity(selectedUniversity);
    navigate(`/nearby-restaurants/${selectedUniversity}`);
  };

  const handleUniversityCardClick = (selectedUniversity) => {
    setUniversity(selectedUniversity);
    navigate(`/nearby-restaurants/${selectedUniversity}`);
  };

  const items = [
    { image: "https://assets2.kansascitysteaks.com/dyn-images/pdp_hero/Burger_SU18-ca9fa7f3da1cef488ae2303889ec6b69.jpg", name: 'Steak Burger', price: '79', vendor :"Bun House", ratings: getRandomRating() },
    { image: "https://upload.wikimedia.org/wikipedia/commons/c/c8/Pizza_Margherita_stu_spivack.jpg", name: 'Tomato Pizza',vendor: "Pizza World", price: '399', ratings: getRandomRating() },
    { image: "https://www.indianhealthyrecipes.com/wp-content/uploads/2023/05/red-sauce-pasta-recipe.jpg", name: 'Pasta', price: '100',vendor: "Garam Chai", ratings: getRandomRating() },
    { image: "https://i.ytimg.com/vi/HToinNNWISU/maxresdefault.jpg", name: 'Honey Chilli Potato', price: '80',vendor: "Oven Express", ratings: getRandomRating() },
    { image: "https://vegecravings.com/wp-content/uploads/2016/07/Virgin-Mojito-Recipe-Step-By-Step-Instructions-scaled-e1646158805560.jpeg", name: 'Mozitos', price: '90',vendor: "Nescafe", ratings: getRandomRating() },
    { image: "https://www.terrafood.co.in/cdn/shop/files/HomestyleVegThali.jpg?v=1687767182&width=713", name: 'Veg Thali', price: '120',vendor: "Kitchen Ette", ratings: getRandomRating() },
    { image: "https://food.fnr.sndimg.com/content/dam/images/food/fullset/2018/4/30/0/FNK_Ice-Cream-in-a-Bag_s4x3.jpg.rend.hgtvcom.1280.720.suffix/1525105614384.jpeg", name: 'Ice Creams', price: '49-999',vendor: "Amul Production", ratings: getRandomRating()},
    { image: "https://static.toiimg.com/thumb/61943940.cms?imgsize=310813&width=800&height=800", name: 'Chai Samose', price: '50',vendor: "Talk Of The Town", ratings: getRandomRating()},
    { image: "https://c.ndtvimg.com/2023-03/fqpicf98_momos_625x300_15_March_23.jpg?im=FaceCrop,algorithm=dnn,width=620,height=350", name: 'Steam Momos', price: '60',vendor: "China T", ratings: getRandomRating()},
  ];

  function getRandomRating() {
    return Math.floor(Math.random() * 5) + 1;
  }

  return (
    <div className="most-popular">
        <h2>Select University</h2>
      <div className="university-cards">
        {universities.map((uni) => (
          <div
            key={uni.id}
            className={`card ${university === uni.id ? 'selected' : ''}`}
            onClick={() => handleUniversityCardClick(uni.id)}
          >
            <img src={uni.image || placeholder} alt={uni.name} />
            <h3>{uni.name}</h3>
            <button>
                {/* food icon */}
                <i  className='fa fa-cutlery' aria-hidden='true'></i>
                <i class="fas fa-burger-soda"></i> Explore
            </button>
          </div>
        ))}
      </div>
      <div className="dropdown-container">
        <span>
            Select University:
        </span>
        <select onChange={handleUniversityChange} value={university}>
            <option value="">Select</option>
          {universities.map((uni) => (
            <option key={uni.id} value={uni.id}>
              {uni.name}
            </option>
          ))}
        </select>
      </div>
      <hr />
      <h2>Most Popular</h2>
      
      <div className="items">
        {items.map((item, index) => (
          <Food_Item key={index} {...item} />
        ))}
      </div>
    </div>
  );
};

export default Food_MostPopular;
