import React, { useContext, useState, useEffect } from "react";
import { doc, getDoc } from "firebase/firestore";
import { AuthContext } from "../context/AuthContext";
import { db } from "../firebase";
import Dating from "../components/Dating";
import "../style/DatingProfile.scss";
import { v4 as uuidv4 } from "uuid";

const DatingProfile = () => {
    const { userDataFromDb } = useContext(AuthContext);
    const [activeSection, setActiveSection] = useState("swipe");
    const [likedArray, setLikedArray] = useState([]);
    const [likedByArray, setLikedByArray] = useState([]);
    const [notInterestedArray, setNotInterestedArray] = useState([]);
    const [matchedUserData, setMatchedUserData] = useState([]);
    const [matches, setMatches] = useState(0);

    const displayName = userDataFromDb?.displayName || "";

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const userDocRef = doc(db, "dating", userDataFromDb.uid);
                const userDocSnapshot = await getDoc(userDocRef);

                if (userDocSnapshot.exists()) {
                    const userData = userDocSnapshot.data();
                    setLikedArray(userData.liked || []);
                    setLikedByArray(userData.likedby || []);
                    setNotInterestedArray(userData.notInterested || []);

                    const matchedUsers = userData.liked.filter(id => userData.likedby.includes(id));
                    setMatchedUserData(matchedUsers);

                    const matchedUserDataPromises = matchedUsers.map(async userId => {
                        const userRef = doc(db, "users", userId);
                        const userSnapshot = await getDoc(userRef);
                        return userSnapshot.data();
                    });

                    const matchedUserData = await Promise.all(matchedUserDataPromises);
                    setMatchedUserData(matchedUserData);

                    setMatches(matchedUsers.length);
                }
            } catch (error) {
                console.error("Error fetching user data:", error);
            }
        };

        fetchUserData();
    }, [userDataFromDb.uid]);

    return (
        <div className="DatingPage">
            <div className="buttons">
                <button
                    style={{ background: activeSection === "swipe" ? "linear-gradient(75deg, #286eff, #033391)" : "linear-gradient(75deg, #f153bd, #e272d5, #d116f1)" }}
                    onClick={() => setActiveSection("swipe")}
                >
                    Start Swiping
                </button>
                <button
                    style={{ background: activeSection === "matches" ? "linear-gradient(75deg, #286eff, #033391)" : "linear-gradient(75deg, #f153bd, #e272d5, #d116f1)" }}
                    onClick={() => setActiveSection("matches")}
                >
                    Matches
                </button>
                <button
                    onClick={() => setActiveSection("stats")}
                    style={{ background: activeSection === "stats" ? "linear-gradient(75deg, #286eff, #033391)" : "linear-gradient(75deg, #f153bd, #e272d5, #d116f1)" }}
                >
                    Stats
                </button>
                <button
                    style={{ background: activeSection === "none" ? "linear-gradient(75deg, #286eff, #033391)" : "linear-gradient(75deg, #f153bd, #e272d5, #d116f1)" }}
                    onClick={() => { alert("Coming Soon") }}
                >
                    Profile Settings
                </button>
            </div>
            <div className="main-continer">
                <div className="left">
                    <div className="container">
                        <div className="img">
                            <img src={userDataFromDb.photoURL} alt="profilePicture" />
                        </div>
                        <div className="user-details">
                            <h2>{displayName}</h2>
                            <p>{userDataFromDb?.userName} - {userDataFromDb?.gender === "male" ? "M" : "F"}</p>
                            <p className="setdating" style={{ color: userDataFromDb?.isDating === true ? "green" : "red" }}>Dating {userDataFromDb?.isDating === true ? "Enabled" : "Disabled"}</p>
                        </div>
                        <div className="button">
                            <button
                                style={{ background: activeSection === "swipe" ? "linear-gradient(75deg, #286eff, #033391)" : "linear-gradient(75deg, #f153bd, #e272d5, #d116f1)" }}
                                onClick={() => setActiveSection("swipe")}
                            >
                                Start Swiping
                            </button>

                            <button
                                onClick={() => setActiveSection("stats")}
                                style={{ background: activeSection === "stats" ? "linear-gradient(75deg, #286eff, #033391)" : "linear-gradient(75deg, #f153bd, #e272d5, #d116f1)" }}
                            >
                                Stats
                            </button>
                            <button
                                style={{ background: activeSection === "matches" ? "linear-gradient(75deg, #286eff, #033391)" : "linear-gradient(75deg, #f153bd, #e272d5, #d116f1)" }}
                                onClick={() => setActiveSection("matches")}
                            >
                                Matches
                            </button>
                            <button
                                style={{ background: activeSection === "none" ? "linear-gradient(75deg, #286eff, #033391)" : "linear-gradient(75deg, #f153bd, #e272d5, #d116f1)" }}
                                onClick={() => { alert("Coming Soon") }}
                            >
                                Profile Settings
                            </button>
                        </div>
                    </div>
                </div>
                <div className="right">
                    <div className="swipe" hidden={activeSection !== "swipe"}>
                        <Dating />
                    </div>

                    <div className="matches" hidden={activeSection !== "matches"}>
                        <h2>Matches</h2>
                        <div className="matched-data">
                            {matchedUserData.map(user => (
                                <div className="matched-card" key={user.uid + uuidv4()}>
                                    <div className="top">
                                        <img src={user.photoURL} alt="image" />
                                    </div>

                                    <div className="center-data">
                                        <h3>{user.displayName}</h3>
                                        <p>{user.userName} - {user?.gender === "male" ? "M" : "F"}</p>
                                    </div>

                                    <div className="chat-icon">
                                    <i className="fas fa-comments-alt"></i>
                                    </div>
                                </div>
                            ))}
                        </div>

                    </div>

                    <div className="Stats" hidden={activeSection !== "stats"}>
                        <h2>Stats</h2>
                        <div className="stats-data">
                            <div className="stats-card">
                                Likes
                                <p>{likedArray.length}</p>
                            </div>

                            <div className="stats-card">
                                Matches
                                <p>{matchedUserData.length}</p>
                            </div>

                            <div className="stats-card">
                                Liked By
                                <p>{likedByArray.length}</p>
                            </div>
                            
                            <div className="stats-card">
                                Left Swiped
                                <p>{notInterestedArray.length}</p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DatingProfile;
