import React, { useState } from "react";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../firebase";
import { Link } from "react-router-dom";

const PasswordReset = () => {
  const [email, setEmail] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const [error, setError] = useState(null);

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    setError(null);

    try {
      await sendPasswordResetEmail(auth, email);
      setEmailSent(true);
    } catch (err) {
      console.log("Firebase Error:", err.code, err.message);
      setError("Failed to send reset email. Please check the email address.");
    }
  };

  return (
    <div className="formContainer">
      <div className="formWrapper">
        <div className="form-head">
          <span className="logo"><span className="u">U</span>ni<span className="c">C</span>hat</span>
        </div>
        <div className="form-body">
          <span className="title">Password Reset</span>

          <form onSubmit={handlePasswordReset}>
            <input
              required
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button type="submit">Reset Password</button>

          </form>
          <p className="error">
            {error && <span>{error}</span>}
          </p>

          
            <p className="Warning resend">
            {emailSent && (
              "Password reset email sent. Reset in seconds."
              )}
              </p>
        
          <p style={{paddingTop: "8px"}}>
            You do have an account? <Link to="/login">Login</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default PasswordReset;
