import React, { useState, useEffect } from 'react';
import '../style/Networking.scss';
import initparticles from '../animation';

const Networking = () => {

    useEffect(() => {
        initparticles();
    }, []);

    const [timeLeft, setTimeLeft] = useState(48 * 60 * 60 * 1000); // 48 hours in milliseconds

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(prevTimeLeft => {
                if (prevTimeLeft <= 1000) {
                    clearInterval(timer);
                    return 0;
                }
                return prevTimeLeft - 1000;
            });
        }, 1000);
        
        return () => clearInterval(timer);
    }, []);

    const formatTime = (milliseconds) => {
        const totalSeconds = Math.floor(milliseconds / 1000);
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;

        return `${hours}h ${minutes}m ${seconds}s`;
    };

    return (
        <div className="page-container">
            <div className="card">
                <h1 className="heading textcontainer">
                    <span className='particletext bubbles'>UniChat: Coming Soon!</span>
                </h1>
                <h2 className="subheading">Brace yourselves...</h2>
                <div className="countdown">
                    {timeLeft > 0 ? (
                        <span>Live in {formatTime(timeLeft)}</span>
                    ) : (
                        <span>We are live now!</span>
                    )}
                </div>
                <div className="quotes textcontainer">
                    <p className="quote particletext confetti">"Prepare to connect like never before. UniChat is about to take your networking game to the next level!"</p>
                    <p className="quote particletext confetti">"Who needs coffee breaks when you have UniChat breaks? Stay tuned!"</p>
                </div>
                <div className="contact">
                    <p>We are putting the final touches! For early access, follow us on social media or join our waitlist at <a href="mailto:info@unichat.com">info@unichat.com</a>.</p>
                    <button className="cta">Join Waitlist</button>
                </div>
            </div>
        </div>
    );
}

export default Networking;
