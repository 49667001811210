import React, { useContext, useEffect, useState } from 'react';
import { signOut } from "firebase/auth";
import { auth, db, storage } from '../firebase';
import { AuthContext } from '../context/AuthContext';
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { Link } from 'react-router-dom';
import "../style/ChatNavbar.scss";

const formatDate = (dateString) => {
  if (!dateString) {
    return "Hang on...";
  }

  const date = new Date(dateString);

  if (isNaN(date.getTime())) {
    return "Hang on...";
  }

  const day = date.getDate();
  const month = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(date);
  const year = date.getFullYear();
  return `${day} ${month} ${year}`;
};

const Navbar = () => {
  const { currentUser, handleLogout, userDataFromDb, userDataFromMongoDb } = useContext(AuthContext);
  const { displayName, photoURL, uid } = currentUser;
  const [userDetails, setUserDetails] = useState(null);
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [newDisplayName, setNewDisplayName] = useState(displayName);
  const [selectedGender, setSelectedGender] = useState('');
  const [selectedDating, setSelectedDating] = useState(userDataFromDb.isDating || false);

  const [updating, setUpdating] = useState(false);
  const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2MB in bytes
  const [error, setError] = useState("");

  const handleLogoutClick = async () => {
    try {
      await signOut(auth);
      handleLogout();
    } catch (error) {
      console.error("Error logging out:", error);
    }
  }

  const ShowAndHideMenu = () => {
    setIsMenuVisible((prevValue) => !prevValue);
  }

  const handleEditClick = () => {
    setEditMode(true);
  }

  const randomNum = Math.floor(Math.random() * 1000000);
  const date = new Date().getTime();

  const handleUpdateClick = async () => {
    try {
      if (editMode && userDataFromDb) {
        setUpdating(true); // Set updating to true when starting the update process

        if (selectedImage) {
          const storageRef = ref(storage, `${"Profile_Pictures/" + displayName + "/" + displayName + randomNum + date}`);
          const uploadTask = uploadBytesResumable(storageRef, selectedImage);

          uploadTask.on(
            "state_changed",
            (snapshot) => {
              const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              setUploadProgress(progress);
            },
            (error) => {
              console.error("Error uploading profile picture:", error);
              setUpdating(false); // Set updating to false if there's an error
            },
            async () => {
              const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
              await updateDoc(doc(db, "users", uid), {
                photoURL: downloadURL,
              });

              setUserDetails((prevDetails) => ({
                ...prevDetails,
                photoURL: downloadURL,
              }));

              setSelectedImage(null);
              setUploadProgress(0);
              setUpdating(false); // Set updating to false when the update is complete
            }
          );
        }

        if (newDisplayName !== userDataFromDb.displayName) {
          await updateDoc(doc(db, "users", uid), {
            displayName: newDisplayName,
          });

          setUserDetails((prevDetails) => ({
            ...prevDetails,
            displayName: newDisplayName,
          }));
        }

        if (selectedGender !== userDataFromDb.gender) {
          await updateDoc(doc(db, "users", uid), {
            gender: selectedGender,
          });

          setUserDetails((prevDetails) => ({
            ...prevDetails,
            gender: selectedGender,
          }));
        }

        // Update dating status if changed
        if (selectedDating !== userDataFromDb.isDating) {
          await updateDoc(doc(db, "users", uid), {
            isDating: selectedDating,
          });

          setUserDetails((prevDetails) => ({
            ...prevDetails,
            isDating: selectedDating,
          }));
        }

        setEditMode(false);
      }
    } catch (error) {
      console.error("Error updating user details:", error);
      setUpdating(false); // Set updating to false if there's an error
    }
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];

    if (!file) {
      return; // No file selected, do nothing
    }

    // Check file type
    const validTypes = ["image/png", "image/jpg", "image/jpeg", "image/gif"];
    if (!validTypes.includes(file.type)) {
      // alert("Please select a valid image file (png, jpg, jpeg, or gif).");
      setError("Please select a valid image file (png, jpg, jpeg, or gif)");
      return;
    }

    // Check file size
    if (file.size > MAX_FILE_SIZE) {
      // alert("Please select an image file that is less than 2MB in size.");
      setError("Please select an image file that is less than 2MB in size.");
      return;
    }
    

    setSelectedImage(file);
  };

  setTimeout(() => {
    setError("");
  }, 11000);

  return (
    <div className='navbar'>
      <div className="user">
        <img src={userDataFromMongoDb.photoURL || userDataFromDb.photoURL} alt={userDataFromDb.displayName} onClick={ShowAndHideMenu} />
        <div className="userData">
          <h4>{userDataFromDb.displayName}</h4>
          <p className='joined'>
            {userDataFromDb ? `${formatDate(userDataFromDb.accountCreated)}` : "Loading..."}
          </p>
        </div>
        <div className="right">
          <i className="fas fa-bars" onClick={ShowAndHideMenu}></i>
        </div>

        {isMenuVisible && (
          <div className="menu">
            {userDataFromDb && (
              <form>
                <div className='userProfileData'>
                  <div className="image">
                    <label htmlFor="profileImageUpdate" className="custom-file-upload">
                      {selectedImage ? (
                        <div className="selectedImage">
                          <img src={URL.createObjectURL(selectedImage)} alt="Selected Avatar" className="selectedImage" />
                        </div>
                      ) : (
                        <>
                          <img src={userDataFromMongoDb.photoURL || userDataFromDb.photoURL} alt={userDataFromDb.displayName} />
                          {editMode && (
                            <i className="fal fa-camera"></i>
                          )}
                        </>
                      )}
                    </label>
                    {editMode && (
                      <input type="file" id='profileImageUpdate' style={{ display: "none" }} onChange={handleImageChange} />
                    )}

                  </div>

                  <div className="content-top">
                    {editMode ? (
                      <>
                        <p>
                          <input
                            type="text"
                            className='UpdateName'
                            placeholder='Display Name'
                            value={newDisplayName}
                            onChange={(e) => setNewDisplayName(e.target.value)}
                          />
                          {editMode && (
                            <>
                              <select
                                value={selectedGender}
                                onChange={(e) => setSelectedGender(e.target.value)}
                              >
                                <option value="">Select Gender</option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                              </select>

                              <select
                                value={selectedDating.toString()} // Convert selectedDating to a string
                                onChange={(e) => setSelectedDating(e.target.value === 'true')}
                              >
                                <option value="">Enable Dating?</option>
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                              </select>

                              

                            </>

                          )}

                        </p>
                      </>
                    ) : (
                      <>

                        <p className='name'>{userDataFromDb.displayName}</p>
                        <p className='username'>{userDataFromDb.userName}</p>
                        <p className='dating'>Dating <span style={{ color: userDataFromDb.isDating ? "Green" : "#d24141" }} >{userDataFromDb.isDating ? "Enabled" : "Disabled"}</span> </p>
                        <p className='joined'>{userDataFromDb ? `${formatDate(userDataFromDb.accountCreated)}` : "Loading..."}</p>
                      </>
                    )}


                  </div>
                </div>
              </form>
            )}

            {uploadProgress > 0 && <progress value={uploadProgress} max="100" />} {uploadProgress > 0 && uploadProgress.toFixed(2) + "%"}
            <p className='error'>{error}</p>

            <div className="buttons">
              <button onClick={handleLogoutClick} className='logout'>Logout <i className="fal fa-sign-out"></i></button>
              {editMode ? (
                <>
                  <button className='updatebtn' onClick={handleUpdateClick} disabled={updating}>Update <i className="fal fa-save"></i></button>
                  <button className='canclebtn' onClick={() => { setEditMode(false) }}>Cancel <i className="fal fa-history"></i></button>
                </>

              ) : (
                <button
                  className='editbtn'
                  onClick={userDataFromDb.demoUser ? () => { alert("Editing Not Allowed!") } : handleEditClick}
                  disabled={updating}
                >
                  Edit <i className="fal fa-edit"></i>
                </button>

              )}
                
              <button className='exitbtn' onClick={ShowAndHideMenu}><i className="fal fa-times-circle"></i></button>
              
              <Link to="/settings" className="settings">
                <i className="fal fa-cog " ></i> Settings
              </Link>
            </div>
            
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
