const FoodItem = ({ image, name, price, ratings, vendor }) => {
    const filledStars = Math.floor(ratings);
    const emptyStars = 5 - filledStars;
  
    return (
      <div className="item">
        <img src={image} alt={name} />
        <span>{name}</span>
        <span className="vendor"><i class="fas fa-store"></i> {vendor}</span>
        <span className="price">₹{price}</span>
        <p>
          {/* Ratings */}
          <span>
            {[...Array(filledStars)].map((_, index) => (
              <i key={index} className="fas fa-star filled"></i>
            ))}
            {[...Array(emptyStars)].map((_, index) => (
              <i key={index} className="far fa-star"></i>
            ))}
          </span>
        </p>
        <button>Add</button>
      </div>
    );
  };
  
  export default FoodItem;