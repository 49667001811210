// Messages.js
import React, { useContext, useEffect, useState } from "react";
import { ChatContext } from "../context/ChatContext";
import { auth, db, storage } from "../firebase";
import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from "firebase/storage";
import { doc } from "firebase/firestore";
import Message from "./Message";
import { onSnapshot, updateDoc } from "firebase/firestore";
import "../style/MessagesBox.scss";
import noChat from "../img/no-chat2.png" ;
import noSelect from "../img/noSelect.png" ;

const Messages = () => {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const { data } = useContext(ChatContext);

  useEffect(() => {
    if (data.chatId) {
      const unSub = onSnapshot(doc(db, "chats", data.chatId), (docSnapshot) => {
        if (docSnapshot.exists()) {
          setMessages(docSnapshot.data().messages || []);
        } else {
          setMessages([]);
        }
        setLoading(false);
      });

      return () => {
        unSub();
      };
    }
  }, [data.chatId]);

  const handleDeleteMessage = async (messageId) => {
    try {
      const chatRef = doc(db, "chats", data.chatId);

      // Filter out the deleted message from the current messages array in the state
      setMessages((prevMessages) => prevMessages.filter((msg) => msg.id !== messageId));

      // Get the message to be deleted
      const messageToDelete = messages.find((msg) => msg.id === messageId);

      // Update the chat document and remove the message
      await updateDoc(chatRef, {
        messages: messages.filter((msg) => msg.id !== messageId),
      });

      console.log("Message deleted successfully!");

      // Check if the message has 'img' or 'file' field and delete the corresponding file from storage
      if (messageToDelete.img) {
        const imgRef = ref(storage, messageToDelete.img);
        await deleteObject(imgRef);
        console.log("Image deleted successfully!");
      } else if (messageToDelete.file) {
        const fileRef = ref(storage, messageToDelete.file);
        await deleteObject(fileRef);
        console.log("File deleted successfully!");
      }
    } catch (error) {
      console.error("Error deleting message:", error);
    }
  };

  const quotes = [
    "Start a Conversation with "+ data.user.displayName +", Where minds connect, sparks fly.",
    "Start a Conversation with "+ data.user.displayName +", Where ideas find their voice.",
    "Start a Conversation with "+ data.user.displayName +", Where connections become meaningful.",
    "Start a Conversation with "+ data.user.displayName +", Where friendships are forged.",
    "Start a Conversation with "+ data.user.displayName +", Where conversations bloom like flowers.",
    "Start a Conversation with "+ data.user.displayName +", Where stories unfold.",
    "Start a Conversation with "+ data.user.displayName +", Where laughter knows no bounds.",
    "Start a Conversation with "+ data.user.displayName +", Where adventures await.",
    "Start a Conversation with "+ data.user.displayName +", Where dreams take flight.",
    "Start a Conversation with "+ data.user.displayName +", Where knowledge finds a home.",
    "Start a Conversation with "+ data.user.displayName +", Where hearts resonate in harmony.",
    "Start a Conversation with "+ data.user.displayName +", Where possibilities are limitless.",
    "Start a Conversation with "+ data.user.displayName +", Where passions ignite.",
    "Start a Conversation with "+ data.user.displayName +", Where empathy bridges gaps.",
    "Start a Conversation with "+ data.user.displayName +", Where creativity knows no boundaries."
  ];
  
  const getRandomQuote = (userDisplayName) => {
    if(userDisplayName === undefined || userDisplayName === null || userDisplayName === "") {
      return (
        <div className="no-chat">
        <p>Select a profile, Start a conversation.</p>
        <p>Dont be shy, say hi!</p>
        <p>Designed & Developed with ❤️, ☕ and 🍕</p> 
      </div>
      );
    }
    const randomIndex = Math.floor(Math.random() * quotes.length);
    return quotes[randomIndex];
  };

  // console.log(data)

  return (
    <div className="messages">
      {data.chatId ? (
        messages.length > 0 ? (
          messages.map((m) => (
            <Message key={m.id} message={m} onDelete={() => handleDeleteMessage(m.id)} />
          ))
        ) : (

          <div className="no-chat">
            <div className="no-chat-inner">
              <img src={data.user.displayName ? noChat : noSelect} alt="no-chat" style={{width: data.user.displayName ? "35%" : "100%"}} />
              <p className="quote">{getRandomQuote(data.user.displayName)}</p>
            </div>
          </div>

        )
      ) : (
        <p>No Chats Selected</p>
      )}
    </div>
  );
};

export default Messages;
