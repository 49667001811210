import React from "react";
import "../../style/GroupChat/MessageItem.scss";

function MessageItem(props) {
  const { isAuthor, data } = props;

  const getFirstCharacterOfName = (name) => {
    const [firstCharacter] = name;
    return firstCharacter;
  }

  const renderContent = (content) => {
    const isUrl = isURL(content);

    if (isUrl) {
      if (isYouTubeLink(content)) {
        const videoId = extractVideoId(content);
        return (
          
          <iframe
            width="350px"
            height="200px"
            src={`https://www.youtube.com/embed/${videoId}`}
            title="YouTube Video"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
            className=""
          ></iframe>

        );
      } else {
        return (
          <a href={content} target="_blank" rel="noopener noreferrer">
            {content}
          </a>
        );
      }
    }

    return content;
  };

  const isURL = (str) => {
    return str.startsWith("http://") || str.startsWith("https://");
  };

  const isYouTubeLink = (url) => {
    return url.includes("youtube.com/watch?v=") || url.includes("youtu.be/");
  };

  const extractVideoId = (url) => {
    if (url.includes("youtube.com/watch?v=")) {
      const params = new URLSearchParams(new URL(url).search);
      return params.get("v");
    } else if (url.includes("youtu.be/")) {
      return url.split("/").pop();
    }
    return "";
  };

  return (
    <div className={`media__item ${isAuthor === "you" ? "you" : "other"}`}>
      <div className="item__meta__data">
        <div className="item__author">
          {isAuthor === "you" ? "You" : getFirstCharacterOfName(data.USER_NAME)}
        </div>
        <div className="item__time">{data.TIME} - {data.USER_NAME}</div>
      </div>
      <div className="item__content content text">
        <p>{renderContent(data.CONTENT)}</p>
      </div>
    </div>
  );
}

export default MessageItem;
