import React from 'react';
import Food_Sidebar from '../components/Food/Food_Sidebar';
import Food_Header from '../components/Food/Food_Header';
import Food_Banner from '../components/Food/Food_Banner';
import Food_Categories from '../components/Food/Food_Categories';
import Food_MostPopular from '../components/Food/Food_MostPopular';
import Food_OrderSummary from '../components/Food/Food_OrderSummary';
import '../style/Food.scss'

const Food = () => {
  return (
    <div className="food-container">
      {/* <Food_Sidebar /> */}
      <div className="main-content">
        <Food_Header />
        <Food_Banner />
        {/* <Food_Categories /> */}
        <Food_MostPopular />
      </div>
      <Food_OrderSummary />
    </div>
  );
};

export default Food;
