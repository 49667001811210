import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Food_Sidebar from '../components/Food/Food_Sidebar';
import Food_Header from '../components/Food/Food_Header';
import '../style/NearbyRest.scss';
import "../style/Food.scss";
import Food_OrderSummary from '../components/Food/Food_OrderSummary';
import Food_Banner from '../components/Food/Food_Banner';

const NearbyRestaurants = () => {
  const { university } = useParams();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [priceRange, setPriceRange] = useState([0, 500]);
  const [showAllCategories, setShowAllCategories] = useState(true);

  const restaurants = {
    'lovely-professional-university': [
      { 
        id: 1, 
        name: 'Fresh Ice Creams', 
        address: '123, Fake Street, Jalandhar', 
        category: 'Indian Special', 
        type: 'veg', 
        image: "https://food.fnr.sndimg.com/content/dam/images/food/fullset/2018/4/30/0/FNK_Ice-Cream-in-a-Bag_s4x3.jpg.rend.hgtvcom.1280.720.suffix/1525105614384.jpeg",
        vendor : "Amul",
        price: 250,
        ratings: 4.5, 
        openingHours: '11:00 AM - 11:00 PM'
      },
      { 
        id: 2, 
        name: 'Amritsari Kulcha', 
        address: '123, Phony Street, Jalandhar', 
        category: 'Indian Special', 
        type: 'veg',
        vendor : "Kulcha & Naan",
        image: "https://images.squarespace-cdn.com/content/v1/5eb11493bd92bb2624085cf9/1590466205981-3KLBJQROFJMFWNKWBEBA/IMG_1503.jpg",
        price: 150,
        ratings: 4.0,
        openingHours: '9:00 AM - 10:00 PM'
      },
      { 
        id: 3, 
        name: 'Fast Foo Company', 
        address: '123, Main Street, Jalandhar', 
        category: 'Fast Food', 
        type: 'non-veg', 
        price: 200,
        image: "https://theburgercompany.co/wp-content/uploads/2022/06/BANNER-3.jpg",
        vendor : "Burger Com",
        ratings: 3.5,
        openingHours: '12:00 PM - 11:00 PM'
      },
      { 
        id: 4, 
        name: 'Pizza Hut',
        image: "https://scontent.fixc2-1.fna.fbcdn.net/v/t39.30808-6/356414020_9773815699326540_4247192174280340581_n.jpg?stp=dst-jpg_p526x296&_nc_cat=101&_nc_cb=99be929b-ddd1f5c1&ccb=1-7&_nc_sid=5f2048&_nc_ohc=Uy5UqZnObJsQ7kNvgGhBhen&_nc_ht=scontent.fixc2-1.fna&oh=00_AYDa3yIIZSKOLgu7pkNa8ZphpHjJetEl3dFIVCdbwK3ynA&oe=664D6332",
        address: '123, Market Street, Jalandhar', 
        category: 'Pizza', 
        type: 'veg', 
        price: 300,
        ratings: 4.2,
        openingHours: '10:00 AM - 12:00 AM'
      },
      { 
        id: 5, 
        name: 'KFC',
        image: "https://media.timeout.com/images/106069779/750/422/image.jpg", 
        address: '123, High Street, Jalandhar', 
        category: 'Fast Food', 
        type: 'non-veg', 
        price: 250,
        ratings: 3.8,
        openingHours: '11:00 AM - 11:00 PM'
      },
      { 
        id: 6, 
        name: 'Subway',
        image: "https://b.zmtcdn.com/data/pictures/chains/0/15090/6447b1993073d7a35781c0a3e1746d79.jpg?output-format=webp&fit=around|771.75:416.25&crop=771.75:416.25;*,*", 
        address: '123, Central Street, Jalandhar', 
        category: 'Fast Food', 
        type: 'veg', 
        price: 180,
        ratings: 4.1,
        openingHours: '9:00 AM - 10:00 PM'
      },
      { 
        id: 7, 
        name: 'Dominos',
        image: "https://stores.dominos.co.in/files/outlet/outlet_facebook_images/outlet_cover_photo/287629/EDV_microsite_creative_1_jpg.jpg", 
        address: '123, Side Street, Jalandhar', 
        category: 'Pizza', 
        type: 'non-veg', 
        price: 280,
        ratings: 4.0,
        openingHours: '10:00 AM - 12:00 AM'
      },
      { 
        id: 8, 
        name: 'Cafe Coffee Day',
        image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTDNLpJfAS4t7htEHVQcDIEZ-0uSGTXGdkaw3C9zR1ioQ&s", 
        address: '123, Corner Street, Jalandhar', 
        category: 'Cafe', 
        type: 'veg', 
        price: 120,
        ratings: 3.9,
        openingHours: '8:00 AM - 10:00 PM'
      },
      { 
        id: 9, 
        name: 'Starbucks',
        image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRuTLwqUxhZFcOXOtgxYDReNu21FkFSHVD-J1uVEM23QGC6STak-wjpvoG9pxd8V2WCXgs&usqp=CAU", 
        address: '123, Mall Street, Jalandhar', 
        category: 'Cafe', 
        type: 'veg', 
        price: 150,
        ratings: 4.3,
        openingHours: '9:00 AM - 11:00 PM'
      },
      { 
        id: 10, 
        name: 'Baskin Robbins',
        image: "https://b.zmtcdn.com/data/pictures/chains/4/51454/a806cca22bb4d73d7d7a9014cb653f09.jpg?output-format=webp&fit=around|771.75:416.25&crop=771.75:416.25;*,*",  
        address: '123, Food Street, Jalandhar', 
        category: 'Dessert', 
        type: 'veg', 
        price: 100,
        ratings: 4.5,
        openingHours: '10:00 AM - 11:00 PM'
      },
      { 
        id: 11, 
        name: 'Haldiram',
        image: "https://gumlet-images.assettype.com/afaqs%2F2023-09%2F8f6b2675-8f6c-40d0-b02d-022c6524dd96%2Fhaldiram.jpg?rect=0%2C0%2C1280%2C720&format=webp&w=480&dpr=2.6",  
        address: '123, Sweets Street, Jalandhar', 
        category: 'Indian Sweets', 
        type: 'veg', 
        price: 180,
        ratings: 4.2,
        openingHours: '8:00 AM - 10:00 PM'
      },
      { 
        id: 12, 
        name: 'The Brew House',
        image: "https://b.zmtcdn.com/data/pictures/7/20065687/833c022b6406c4dc59e5c005bae034df.jpg",  
        address: '123, Brew Street, Jalandhar', 
        category: 'Cafe', 
        type: 'veg', 
        price: 160,
        ratings: 4.0,
        openingHours: '9:00 AM - 11:00 PM'
      }, 
      { 
        id: 13, 
        name: 'The Grill House',
        image: "https://cdn.vectorstock.com/i/1000v/71/97/logo-for-grill-house-vector-24357197.jpg",  
        address: '123, Grill Street, Jalandhar', 
        category: 'Fast Food', 
        type: 'non-veg', 
        price: 220,
        ratings: 3.7,
        openingHours: '12:00 PM - 10:00 PM'
      }, 
      { 
        id: 14, 
        name: 'Sweet Surrender', 
        image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTukrrWvCQKv5J5qZ0i8WsC_M4P9JM3VZbVGdXN6208pw&s", 
        address: '123, Sweet Street, Jalandhar', 
        category: 'Dessert', 
        type: 'veg', 
        price: 110,
        ratings: 4.4,
        openingHours: '10:00 AM - 10:00 PM'
      },
    ],
    'gla-university-mathura': [
        { 
          id: 1, 
          name: 'Delhi Darbar', 
          address: '123, Phony Street, Mathura', 
          category: 'Indian Traditional', 
          type: 'non-veg', 
          price: 300,
          image: "https://theme-assets.getbento.com/sensei/a7c4085.sensei/assets/images/catering-item-placeholder-704x520.png",
          ratings: 4.1,
          openingHours: '11:00 AM - 11:00 PM'
        },
        { 
          id: 2, 
          name: 'The Dhaba', 
          address: '123, Fake Street, Mathura', 
          category: 'Indian Special', 
          type: 'veg', 
          price: 200,
          ratings: 3.8,
          image: "https://theme-assets.getbento.com/sensei/a7c4085.sensei/assets/images/catering-item-placeholder-704x520.png",
          openingHours: '10:00 AM - 10:00 PM'
        },
        { 
          id: 3, 
          name: 'McDonald\'s', 
          address: '123, Main Street, Mathura', 
          category: 'Fast Food', 
          type: 'non-veg', 
          price: 250,
          ratings: 3.5,
          image: "https://theme-assets.getbento.com/sensei/a7c4085.sensei/assets/images/catering-item-placeholder-704x520.png",
          openingHours: '9:00 AM - 12:00 AM'
        },
        { 
          id: 4, 
          name: 'Burger King', 
          address: '123, Market Street, Mathura', 
          category: 'Fast Food', 
          type: 'veg', 
          price: 180,
          image: "https://theme-assets.getbento.com/sensei/a7c4085.sensei/assets/images/catering-item-placeholder-704x520.png",
          ratings: 3.9,
          openingHours: '10:00 AM - 11:00 PM'
        },
        { 
          id: 5, 
          name: 'Pizza Express', 
          address: '123, High Street, Mathura', 
          category: 'Pizza', 
          type: 'non-veg', 
          price: 350,
          image: "https://theme-assets.getbento.com/sensei/a7c4085.sensei/assets/images/catering-item-placeholder-704x520.png",
          ratings: 4.2,
          openingHours: '11:00 AM - 12:00 AM'
        },
        { 
          id: 6, 
          name: 'Barbeque Nation', 
          address: '123, Central Street, Mathura', 
          category: 'Indian Special', 
          type: 'non-veg', 
          price: 400,
          image: "https://theme-assets.getbento.com/sensei/a7c4085.sensei/assets/images/catering-item-placeholder-704x520.png",
          ratings: 4.5,
          openingHours: '12:00 PM - 11:00 PM'
        },
        { 
          id: 7, 
          name: 'Domino\'s', 
          address: '123, Side Street, Mathura', 
          category: 'Pizza', 
          type: 'veg', 
          price: 280,
          image: "https://theme-assets.getbento.com/sensei/a7c4085.sensei/assets/images/catering-item-placeholder-704x520.png",
          ratings: 4.0,
          openingHours: '10:00 AM - 12:00 AM'
        },
        { 
          id: 8, 
          name: 'Costa Coffee', 
          address: '123, Corner Street, Mathura', 
          category: 'Cafe', 
          image: "https://theme-assets.getbento.com/sensei/a7c4085.sensei/assets/images/catering-item-placeholder-704x520.png",
          type: 'veg', 
          price: 130,
          ratings: 3.8,
          openingHours: '8:00 AM - 10:00 PM'
        },
        { 
          id: 9, 
          name: 'Dunkin\' Donuts', 
          address: '123, Mall Street, Mathura', 
          category: 'Dessert', 
          type: 'veg', 
          image: "https://theme-assets.getbento.com/sensei/a7c4085.sensei/assets/images/catering-item-placeholder-704x520.png",
          price: 120,
          ratings: 4.1,
          openingHours: '9:00 AM - 11:00 PM'
        },
        { 
          id: 10, 
          name: 'Giani\'s', 
          address: '123, Food Street, Mathura', 
          category: 'Indian Sweets', 
          type: 'veg', 
          image: "https://theme-assets.getbento.com/sensei/a7c4085.sensei/assets/images/catering-item-placeholder-704x520.png",
          price: 150,
          ratings: 4.3,
          openingHours: '10:00 AM - 10:00 PM'
        },
        { 
          id: 11, 
          name: 'Kwality Walls', 
          address: '123, Sweets Street, Mathura', 
          category: 'Dessert', 
          type: 'veg', 
          price: 90,
          image: "https://theme-assets.getbento.com/sensei/a7c4085.sensei/assets/images/catering-item-placeholder-704x520.png",
          ratings: 3.9,
          openingHours: '10:00 AM - 11:00 PM'
        },
      ]
  };

  const filterRestaurantsByCategory = (category) => {
    setSelectedCategory(category);
    setShowAllCategories(false);
  };

  const filterRestaurantsByType = (type) => {
    if (selectedFilters.includes(type)) {
      setSelectedFilters(selectedFilters.filter(filter => filter !== type));
    } else {
      setSelectedFilters([...selectedFilters, type]);
    }
  };

  const filterRestaurantsByPriceRange = (value) => {
    setPriceRange([priceRange[0], parseInt(value)]); 
  };

  const filteredRestaurants = restaurants[university]?.filter(restaurant => {
    // Method 1: Filtering logic using showAllCategories
    if (showAllCategories) {
      return true; 
    } else if (selectedCategory && restaurant.category !== selectedCategory) {
      return false;
    }
    if (selectedFilters.length > 0 && !selectedFilters.includes(restaurant.type)) return false;
    if (restaurant.price < priceRange[0] || restaurant.price > priceRange[1]) return false;
    return true;
  });

  const uniqueCategories = [...new Set(restaurants[university]?.map(restaurant => restaurant.category))];
  const uniqueTypes = [...new Set(restaurants[university]?.map(restaurant => restaurant.type))];

  const handleAddToCart = (restaurant) => {
    const whatsappMessage = `I want to order ${restaurant.name} - ${restaurant.category} (${restaurant.type}) for ₹${restaurant.price}`;
    const whatsappLink = `https://wa.me/9876543219?text=${encodeURIComponent(whatsappMessage)}`;
    window.open(whatsappLink, '_blank'); 
  };

  return (
    <div className="nearby-restaurants">
      {/* <Food_Sidebar /> */}
      <div className="main-content">
        <Food_Header />
        <Food_Banner />
        <h2>Nearby Restaurants for {university.replace('-', ' ')}</h2>
        <div className="categories">
          <select
            value={showAllCategories ? 'all' : selectedCategory}
            onChange={(e) => {
              if (e.target.value === 'all') {
                setShowAllCategories(true);
                setSelectedCategory(null);
              } else {
                setShowAllCategories(false);
                setSelectedCategory(e.target.value);
              }
            }}
          >
            <option value='all'>All Categories</option>
            {uniqueCategories?.map((category) => (
              <option key={category} value={category}>
                {category}
              </option>
            ))}
          </select>
        </div>
        <div className="filters">
          <h3>Food Type</h3>
          {uniqueTypes?.map((type) => (
            <button
              key={type}
              onClick={() => filterRestaurantsByType(type)}
              className={selectedFilters.includes(type) ? "active" : ""}
            >
              {type}
            </button>
          ))}
          <h3>Price Range</h3>
          <input
            type="range"
            min={0}
            max={500}
            step={10}
            value={priceRange[1]}
            onChange={(e) => filterRestaurantsByPriceRange(e.target.value)}
          />
          <p>Price Range: ${priceRange[0]} - ${priceRange[1]}</p>
        </div>
        <hr />
        <h2 className="restaurant-heading">Menu</h2>
        <div className="restaurant-cards">
          {filteredRestaurants?.map((restaurant) => (
            <div key={restaurant.id} className="restaurant-card">
              <img src={restaurant.image} alt={restaurant.name} />
              <h3>{restaurant.name}</h3>
              {/* <p className='address'>{restaurant.address}</p> */}

                <div className="vendor-and-cat">
                    <p className='cat'> <span><i class="fas fa-utensils"></i></span>{restaurant.category}</p>
                    <span className="vendor"><i class="fas fa-store"></i> {restaurant.vendor}</span>
                </div>
  
              <p className='type'
                style={
                  {
                    background: restaurant.type === 'non-veg' ? 'red' : 'green'
                  }
                }
              >
                {restaurant.type}
              </p>
  
              <div className="price-and-ratting">
                <p className='price'>
                  <span>
                    <i class="fas fa-rupee-sign"></i>
                  </span>
                  {restaurant.price}</p>
                <p className='rating'>
                  {restaurant.ratings}/5</p>
              </div>
  
              <hr />
  
              <p className='working hours'>
                <span> <i class="fas fa-clock"></i> </span>
                {restaurant.openingHours}</p>
              <div className="cta-buttons">
                <button onClick={() => handleAddToCart(restaurant)}>Add to Cart</button>
                <button onClick={() => handleAddToCart(restaurant)}> Order Now <i class="fab fa-whatsapp"></i></button>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Food_OrderSummary />
    </div>
  );
  
};

export default NearbyRestaurants;