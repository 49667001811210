import React from "react";
import Dashboard from "../components/StartUp/Dashboard/Dashboard";
import StartupAppbar from "../components/StartUp/Appbar/StartupAppbar";
import TopStartUpSliders from "../components/StartUp/TopStartUpSliders/TopStartUpSliders";
import RoadMap from "../components/StartUp/RoadMap/RoadMap";
import '../style/Startup/Startup.scss';

function Startup() {
    return (
        <div className="Startup">
            <div className="blurbg">
                <StartupAppbar />
                <Dashboard />
                <TopStartUpSliders/>
                <RoadMap />
            </div>
        </div>
    );
}

export default Startup;
