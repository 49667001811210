import React from 'react';
import DatingContent from './DatingContent/DatingContent';
import './TabContents.scss';
import ProfileContent from './ProfileContent/ProfileContent';

const TabContents = ({ buttonClickState }) => {
    return (
        <div className='TabContents'>
            {buttonClickState === 'Dating' && <DatingContent />}
            {buttonClickState === 'Profile' && <ProfileContent />}
            {buttonClickState === 'Password & Security' &&
                (
                    <div style={{ textAlign: 'center', padding: "20px", fontSize: '1rem', }}>
                        <p >Coming Soon!</p>
                        <br /><br />
                        <a href='password-reset' 
                        style={{ 
                            padding: "10px 30px",  
                            textDecoration: 'underline', 
                            cursor: 'pointer', 
                            fontSize: '1.2rem',
                            fontWeight: 'bold',
                            background: "#286eff",
                            color: "white",
                            borderRadius: "5px",
                            textDecoration: "none"
                            }}
                        >Forgot Password?</a>
                    </div>

                )}
        </div>
    );
};

export default TabContents;
