import React from "react";
import '../style/CircularProgressBar.scss'

const CircularProgressBar = ({ percentage }) => {
  return (
    <div className="circular-progress-container">
      <div
        className="circular-progress"
        style={{ background: `conic-gradient(#EB2562 0% ${percentage}%, #f2f2f2 ${percentage}% 100%)` }}
      >
        <span className="progress-text">{percentage}%</span>
      </div>
    </div>
  );
};

export default CircularProgressBar;
