import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import {
  collection,
  query,
  getDocs,
  where,
  limit,
  orderBy,
} from "firebase/firestore";
import { db } from "../firebase";
import ManageUser from "./ManageUser";
import "../admin/scss/ManageUsers.scss"

const Users = () => {
  const { currentUser, userDataFromDb } = useContext(AuthContext);
  const navigate = useNavigate();
  const [usersData, setUsersData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userName, setUserName] = useState("");
  const [whatToSearch, setWhatToSearch] = useState("displayName");
  const [selectedUser, setSelectedUser] = useState(null);

  const getUsers = async () => {
    try {
      const usersCollectionRef = collection(db, "users");
      const usersQuery = query(
        usersCollectionRef,
        orderBy("accountCreated", "desc"),
        limit(50)
      );

      if (userName) {
        if (/^\d+$/.test(userName)) {
          setWhatToSearch("userName");
        } 
        else if (/^[a-zA-Z0-9]{28}$/.test(userName)){
          setWhatToSearch("uid");
        }
        else {
          setWhatToSearch("displayName");
        }

        const q = query(
          usersCollectionRef,
          where(whatToSearch, ">=", userName),
          where(whatToSearch, "<=", userName + "\uf8ff")
        );
        const usersSnapshot = await getDocs(q);
        const usersData = usersSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setUsersData(usersData);
      } else {
        // Fetch the latest 10 users when no search term is entered
        const usersSnapshot = await getDocs(usersQuery);
        const usersData = usersSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setUsersData(usersData);
      }

      setLoading(false);
    } catch (error) {
      console.error("Error fetching users: ", error);
    }
  };

  useEffect(() => {
    // Check if userDataFromDb is available and user is an admin
    if (userDataFromDb && userDataFromDb.isAdmin === false) {
      console.log("Redirecting to login...");
      navigate("/login");
    } else {
      getUsers();
    }
  }, [userDataFromDb, navigate]);

  if (loading) {
    // Handle the case when userDataFromDb is still loading or not available
    console.log("Loading user data...");
    return <div>Loading...</div>;
  }

  const handleSearch = () => {
    getUsers();
    setSelectedUser(null);
  };

  const manageUsers = (user) => {
    setSelectedUser(user);

  };

  const selectedusersCss = {
    backgroundColor: "#286eff",
    color: "#fff",
    fontWeight: 600,
    borderLeft: "5px solid #D34848",
    
  }

  return (
    <div className="searchUsers">
      <div className="search-and-list">
        <div className="search">
          <input
            type="text"
            placeholder="@Username & Display Name"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
          />
          <button onClick={handleSearch}>Search</button>
        </div>
        <h3>New Users</h3>
        <div className="list">
          {usersData.map((user) => (
            <div key={user.id} 
            onClick={() => manageUsers(user)}
            className={`userData`}
            style={selectedUser?.id === user.id ? { ...selectedusersCss } : {}}
            >
              {/* Render user data here */}
              <div className="image">
                <img src={user.photoURL} alt="" />
              </div>
              <div className="data">
                <p>{user.displayName}</p>
                <p>{user.userName}</p>
              </div>
              <div className="control">
                {user.isBlocked ? <p>Blocked</p> : <p>Active</p>}
              </div>

              {/* Add other user details you want to display */}
            </div>
          ))}
        </div>
      </div>
      <div className="manage-user">
        
        {
          selectedUser && ( <ManageUser user={selectedUser} /> ) 
        }
        
      </div>
    </div>
  );
};

export default Users;
