import React, { useState, useEffect } from "react";
import "../../style/GroupChat/ExistRoomPage.scss";
import Logo from "../../components/GroupChat/Logo";
import { useFormik } from "formik";
import { Toaster, toast } from "react-hot-toast";
import { validateExistRoom } from "../../helper/ValidateForm";
import { useNavigate } from "react-router-dom";
import { nanoid } from "nanoid";

function ExistRoomPage() {
  const navigate = useNavigate();

  const [roomList, setRoomList] = useState([]);

  useEffect(() => {
    // Fetch the list of new rooms when the component mounts
    fetchNewRoomList();
  }, []);

  const fetchNewRoomList = async () => {
    try {
      const response = await fetch("/exroom");
      if (response.ok) {
        const data = await response.json();
        setRoomList(data);
        console.log("data: ", data);
      }
    } catch (error) {
      console.error("Error fetching new room list:", error);
    }
  };

  const formik = useFormik({
    initialValues: {
      userName: "",
      roomCode: "",
    },
    validate: validateExistRoom,
    onSubmit: async (values) => {
      try {
        const userId = nanoid();
        navigate(`/chat/${values.roomCode}`, {
          state: {
            IS_NEW_ROOM: false,
            USER_NAME: values.userName,
            USER_ID: userId,
            ROOM_CODE: values.roomCode,
          },
          replace: true,
        });
      } catch (error) {
        toast.error(error.message);
      }
    },
  });

  return (
    <div className="exroom__page">
      <Toaster position="top-center" reverseOrder={false}></Toaster>
      <div className="exroom__contents">
        <h2 className="exroom__heading">Join Via Code</h2>
        <form className="exroom__form" onSubmit={formik.handleSubmit}>
          <input
            type="text"
            placeholder="User Name"
            id="userName"
            name="userName"
            required
            autoFocus
            maxLength={20}
            onChange={formik.handleChange}
            value={formik.values.userName}
          />

          <input
            type="text"
            placeholder="Room Code"
            id="roomCode"
            name="roomCode"
            required
            autoComplete="off"
            onChange={formik.handleChange}
            value={formik.values.roomCode}
          />
          <div className="buttons">
            <button className="join__room__btn" type="submit">
              Let's Go
            </button>
          </div>
        </form>
        {formik.errors.userName && (
          <p className="input__error">{formik.errors.userName}</p>
        )}
        {formik.errors.roomCode && (
          <p className="input__error">{formik.errors.roomCode}</p>
        )}

        <hr />

        <div className="moreRoom">
          <h2>Public Room</h2>
          <div className="public-rooms">
            {roomList.map((room) => (
              <div className="room-card" key={room.roomCode}>
                <div className="room-card__header">
                  <h3>{room.roomName}</h3>
                </div>
                <div className="room-card__footer">
                  <p>{room.roomCode}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExistRoomPage;
