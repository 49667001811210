
		
import React, { useState, useEffect, useContext } from "react";
		
import { Link, useNavigate } from "react-router-dom";
		
import { AuthContext } from "../context/AuthContext";
		
import {
		
  doc,
		
  setDoc,
		
  getDoc,
		
  collection,
		
  Timestamp,
		
  query,
		
  where,
		
} from "firebase/firestore";
		
import { db, storage } from "../firebase";
		
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
		
import { v4 as uuid } from "uuid";
		
import "../admin/scss/UpdateNews.scss";
		
import coverImagePlaceholder from "../img/coverImage.jpg";
		
import ManageAndApproveNews from "./ManageAndApproveNews";
		

		
function UpdateNews() {
		
  const [newsheading, setNewsHeading] = useState("");
		
  const [newsType, setNewsType] = useState("");
		
  const [authorname, setAuthorName] = useState("");
		
  const [coverImage, setCoverImage] = useState(null); // Store the selected image file
		
  const [newsContent, setNewsContent] = useState("");
		
  const [uploadProgress, setUploadProgress] = useState(0); // Progress bar value
		
  const [uploadComplete, setUploadComplete] = useState(false); // Upload completion state
		
  const [existingDocument, setExistingDocument] = useState(false); // Whether the document already exists in Firestore
		
  const [errors, setErrors] = useState(""); // Store error messages
		

		
  const { currentUser, userDataFromDb } = useContext(AuthContext);
		
  const navigate = useNavigate();
		

		
  useEffect(() => {
		
    // Check if userDataFromDb is available and user is an admin
		
    //console.log("userDataFromDb:", userDataFromDb);
		
    if (userDataFromDb && userDataFromDb.isAdmin === false) {
		
      navigate("/login");
		
    }
		
  }, [userDataFromDb, navigate]);
		

		
  useEffect(() => {
		
    // Check if the document already exists in Firestore
		
    const checkDocument = async () => {
		
      const date = new Date();
		
      const month = date.getMonth() + 1; // Months are 0-indexed, so add 1
		
      const day = date.getDate();
		
      const year = date.getFullYear();
		
      const documentName = `${month}-${day}-${year}`;
		

		
      const q = query(collection(db, "news"), where("__name__", "==", documentName));
		
      const querySnapshot = await getDoc(q);
		

		
      if (querySnapshot.exists()) {
		
        // Document already exists, set the state accordingly
		
        setExistingDocument(true);
		
      }
		
    };
		

		
    checkDocument();
		
  }, []);
		

		
  // Push Function
		
  const Publish = async (e) => {
		
    e.preventDefault(); // Prevent default form submission behavior
		

		
    // Perform validation
		
    if (!newsheading || !newsType || !authorname || !coverImage || !newsContent) {
		
      //alert("Please fill in all the fields.");
		
      setErrors("Please fill in all the fields.");
		
      return;
		
    }
		

		
    try {
		
      // Save data to the "news" collection in Firestore under "month-day-year" document
		
      const date = new Date();
		
      const month = date.getMonth() + 1; // Months are 0-indexed, so add 1
		
      const day = date.getDate();
		
      const year = date.getFullYear();
		
      const documentName = `${month}-${day}-${year}`;
		
      const docRef = doc(db, "news", documentName);
		

		
      // Save the image to Firebase Storage and get the download URL
		
      const storageRef = ref(storage, `news/${year}/${month}/${coverImage.name}`);
		
      const uploadTask = uploadBytesResumable(storageRef, coverImage);
		
      uploadTask.on(
		
        "state_changed",
		
        (snapshot) => {
		
          // Update the progress bar value
		
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
		
          setUploadProgress(progress);
		
        },
		
        (error) => {
		
          console.error("Error uploading image:", error);
		
          //alert("An error occurred while uploading the image.");
		
          setErrors("An error occurred while uploading the image.");
		
        },
		
        async () => {
		
          // Image upload successful, now get the download URL
		
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
		

		
          // Generate a new news item with a unique ID
		
          const newsItemId = uuid();
		
          const newNewsItem = {
		
            id: newsItemId,
		
            newsheading: newsheading,
		
            newsType: newsType,
		
            author: authorname,
		
            coverImage: downloadURL,
		
            newsContent: newsContent,
		
            isVerified: false,
		
            isPublished: false,
		
            date: new Date().getTime(),
		
          };
		

		
          // Check if the document already exists
		
          const docSnapshot = await getDoc(docRef);
		

		
          if (docSnapshot.exists()) {
		
            // If the document exists, get the existing news array
		
            const existingNewsArray = docSnapshot.data().news || [];
		

		
            // Add the new news item to the existing array
		
            existingNewsArray.push(newNewsItem);
		

		
            // Update the document with the updated news array
		
            await setDoc(docRef, {
		
              news: existingNewsArray,
		
            });
		
          } else {
		
            // If the document doesn't exist, create a new document with the news array
		
            await setDoc(docRef, {
		
              news: [newNewsItem],
		
            });
		
          }
		

		
          //console.log("Data saved successfully!");
		

		
          setUploadComplete(true);
		
        }
		
      );
		
    } catch (error) {
		
      console.error("Error saving data:", error);
		
      //alert("An error occurred while saving data.");
		
      setErrors("An error occurred while saving data.");
		
    }
		
  };
		

		
  useEffect(() => {
		
    if (errors) {
		
      const errorTimer = setTimeout(() => {
		
        setErrors("");
		
      }, 5000);
		

		
      return () => clearTimeout(errorTimer);
		
    }
		
  }, [errors]);
		

		
  return (
		
    <div className="News">
		
      <div className="left">
		
        <div className="form">
		
          <h3 className="Heading">Publish News</h3>
		
          <form onSubmit={Publish}>
		
            <div className="input">
		
              <input
		
                placeholder="News Heading"
		
                value={newsheading}
		
                onChange={(e) => setNewsHeading(e.target.value)}
		
              />
		
            </div>
		

		
            <div className="input">
		
              <input
		
                placeholder="News Type"
		
                value={newsType}
		
                onChange={(e) => setNewsType(e.target.value)}
		
              />
		
            </div>
		

		
            <div className="input">
		
              <input
		
                placeholder="Author Name"
		
                value={authorname}
		
                onChange={(e) => setAuthorName(e.target.value)}
		
              />
		
            </div>
		

		
            <div className="">
		
              {!coverImage && (
		
                <label htmlFor="coverImage">
		
                  <img
		
                    src={coverImagePlaceholder}
		
                    className="coverImage"
		
                    alt="coverimage"
		
                  />
		
                  Choose a cover Image
		
                </label>
		
              )}
		

		
              <input
		
                hidden
		
                type="file"
		
                id="coverImage"
		
                onChange={(e) => setCoverImage(e.target.files[0])} // Store the selected image file
		
              />
		
              {coverImage && (
		
                <img
		
                  src={URL.createObjectURL(coverImage)}
		
                  alt="Cover"
		
                  className="coverImage"
		
                />
		
              )}
		
            </div>
		

		
            <div className="textarea">
		
              <textarea
		
                cols="30"
		
                rows="3"
		
                placeholder="News Content"
		
                value={newsContent}
		
                onChange={(e) => setNewsContent(e.target.value)}
		
              ></textarea>
		
            </div>
		

		
            <div className="button">
		
              <button type="submit">Publish</button>
		
            </div>
		

		
            {uploadProgress > 0 && (
		
              <div className="progress-bar">
		
                <progress value={uploadProgress} max="100" />
		
                {Math.round(uploadProgress)}%
		
              </div>
		
            )}
		

		
            {uploadComplete && (
		
              <div className="upload-complete">Upload Complete!</div>
		
            )}
		

		
            {errors && <div className="error">{errors}</div>}
		
          </form>
		
        </div>
		
      </div>
		
      <div className="right">
		
        {/* <Link to="/manageNews">Manage News</Link> */}
		

		
        <ManageAndApproveNews />
		
      </div>
		
    </div>
		
  );
		
}
		

		
export default UpdateNews;