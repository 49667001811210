import React, { useState, useEffect } from "react";
import { createUserWithEmailAndPassword, updateProfile, sendEmailVerification, onAuthStateChanged, fetchSignInMethodsForEmail } from "firebase/auth";
import { auth, db, storage } from "../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { doc, setDoc, getDoc, getDocs, collection } from "firebase/firestore";
import { useNavigate, Link } from "react-router-dom";

import avatar1 from "../img/avatar-1.jpeg";
import avatar2 from "../img/avatar-2.jpeg";
import avatar3 from "../img/avatar-3.jpeg";
import avatar4 from "../img/avatar-4.jpeg";
import avatar5 from "../img/avatar-5.jpeg";
import avatar6 from "../img/avatar-6.jpeg";
import avatar7 from "../img/avatar-7.jpeg";
import avatar8 from "../img/avatar-8.jpeg";
import avatar9 from "../img/avatar-9.jpeg";
import avatar10 from "../img/avatar-10.jpeg";
import avatar11 from "../img/avatar-11.jpeg";


const Register = () => {
  const [err, setErr] = useState("");
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [emailExists, setEmailExists] = useState(false);
  const [progress, setProgress] = useState(0);
  const [selectedImage, setSelectedImage] = useState(null);
  const [email, setEmail] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [userName, setUserName] = useState("");
  const [allUserMessage, setAllUserMessage] = useState("");
  const [avatar, setAvatar] = useState(avatar1);
  const [selectGoodOne, setSelectGoodOne] = useState("");

  const navigate = useNavigate();

  // Function to clear error messages
  const clearErrorMessages = () => {
    setAllUserMessage("");
    setErr("");
  };

  const extractNameAndUsername = (email) => {
    const regex = /^([A-Za-z0-9._%+-]+)@(lpu\.in|lpu\.co\.in|kit\.ac\.in)$/;
    const match = email.match(regex);

    if (match) {
      const displayName = match[1].split(".")[0].replace(/\d+/g, "").replace(/\b\w/g, (l) => l.toUpperCase());
      const userName = match[1].replace(/\D+/g, "");
      return { displayName, userName };
    }
    return { displayName: "", userName: "" };
  };

  const handleImageChange = (e) => {
    e.preventDefault();
    if (e.target.files[0] === undefined || e.target.files[0] === null || e.target.files[0] === "") {
      setAllUserMessage("Please select an image");
      setSelectedImage(null);
      return;
    }

    const file = e.target.files[0];
    const fileSizeLimit = 2 * 1024 * 1024;

    if (file.size > fileSizeLimit) {
      setAllUserMessage("File size must not exceed 2MB.");
      setSelectedImage(null);
      return;
    }

    const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
    if (!allowedTypes.includes(file.type)) {
      setAllUserMessage("Only PNG, JPEG, and JPG file formats are allowed.");
      setSelectedImage(null);
      return;
    }

    setSelectedImage(file);
  };

  const handleValidation = () => {
    let valid = true;
    setErr("");
    setEmailExists(false);

    if (!email.endsWith("@lpu.in") && !email.endsWith("@lpu.co.in") && !email.endsWith("@gla.ac.in") && !email.endsWith("@kit.ac.in")) {
      setAllUserMessage("Only university emails ending with @lpu.in, @lpu.co.in and @kit.ac.in are allowed!");
      valid = false;
    } else {
      fetchSignInMethodsForEmail(auth, email)
        .then((signInMethods) => {
          if (signInMethods && signInMethods.length > 0) {
            setEmailExists(true);
            setAllUserMessage("Email already exists. Please choose a different one.");
            valid = false;
          }
        })
        .catch((error) => {
          console.error("Error checking email existence:", error);
          setAllUserMessage("An error occurred. Please try again later.");
          valid = false;
        });
    }

    return valid;
  };



  const handleUserNameValidation = async () => {
    if (!userName) {
      setAllUserMessage("Please enter a username.");
      return false;
    }

    if (userName.length !== 8 && userName.length !== 5 && userName.length !== 9) {
      setAllUserMessage("Seems like your username is incorrect. Please enter a valid username.");
      return false;
    }

    try {
      const querySnapshot = await getDocs(collection(db, "users"));
      const usernames = querySnapshot.docs.map((doc) => doc.data().userName);

      if (usernames.includes(userName)) {
        setAllUserMessage("Username already exists. Please choose a different one.");
        return false;
      }

      return true;
    } catch (error) {
      console.error("Error checking username:", error);
      setAllUserMessage("An error occurred. Please try again later.");
      return false;
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    clearErrorMessages();

    if (e.target[0].value === "" || e.target[1].value === "" || e.target[3].value === "") {
      setAllUserMessage("Please fill in all required fields.");
      return;
    }

    if (!handleValidation()) {
      return;
    }

    if (!email || !displayName || !selectedImage || !e.target[4].value) {
      setAllUserMessage("Please fill in all required fields.");
      return;
    }


    setLoading(true);
    const displayNameValue = displayName;
    const emailValue = email;
    const password = e.target[4].value;
    const file = selectedImage;
    const randomNum = Math.floor(Math.random() * 1000000);

    const emailRegex = /^([A-Za-z0-9._%+-]+)@(lpu\.in|lpu\.co\.in|kit\.ac\.in)$/;
    const emailMatch = email.match(emailRegex);

    if (emailMatch) {
      const userName = emailMatch[1].replace(/\D+/g, "");
      setUserName(userName);
    }

    const userNameValidation = await handleUserNameValidation();
    if (!userNameValidation) {
      setLoading(false);
      return;
    }

    try {
      const res = await createUserWithEmailAndPassword(auth, emailValue, password);

      const date = new Date().getTime();
      const storageRef = ref(storage, `${"Profile_Pictures/" + displayName + "/" + displayNameValue + randomNum + date}`);

      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progressPercentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgress(progressPercentage);
        },
        (error) => {
          console.error("Error uploading avatar:", error);
          setAllUserMessage("An error occurred while uploading the avatar. Please try again later.");
          setLoading(false);
        },
        async () => {
          try {
            const downloadURL = await getDownloadURL(storageRef);
            await updateProfile(res.user, {
              displayName: displayNameValue,
              photoURL: downloadURL,
            });

            await sendEmailVerification(res.user);

            await setDoc(doc(db, "users", res.user.uid), {
              uid: res.user.uid,
              displayName: displayNameValue,
              email: emailValue,
              photoURL: downloadURL,
              accountCreated: date,
              userName: userName,
              isVerified: false,
              isAdmin: false,
              isblocked: false,
              isDating: false,
              gender: "",
            });

            await setDoc(doc(db, "userChats", res.user.uid), {});

            setLoading(false);
            setEmailSent(true);
          } catch (err) {
            console.error("Error updating profile or sending verification email:", err);
            setAllUserMessage("An error occurred. Please try again later.");
            setLoading(false);
          }
        }
      );
    } catch (err) {
      console.error("Error creating user:", err);
      setAllUserMessage("An error occurred. Please try again later.");
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   onAuthStateChanged(auth, (user) => {
  //     if (user && user.emailVerified) {
  //       navigate("/");
  //     }
  //   });
  // }, [navigate]);

  const imageLinks = [avatar1, avatar2, avatar3, avatar4, avatar5, avatar6, avatar7, avatar8, avatar9, avatar10, avatar11];

  const getRandomImage = () => {
    const randomIndex = Math.floor(Math.random() * imageLinks.length);
    return imageLinks[randomIndex];
  };

  useEffect(() => {
    const avatarChangeInterval = setInterval(() => {
      setAvatar(getRandomImage());
    }, 5000);

    setTimeout(() => {
      setSelectGoodOne("");
    }, 10000);

    return () => clearInterval(avatarChangeInterval);
  }, []);

  const getMessageStyle = (type) => {
    switch (type) {
      case "success":
        return { color: "green", fontSize: "12px" };
      case "error":
        return { color: "red", fontSize: "12px" };
      case "info":
        return { color: "blue", fontSize: "12px" };
      default:
        return { fontSize: "12px" };
    }
  };

  const AgreeTandC = () => {
    document.querySelector(".formWrapper").style.display = "flex";
    document.querySelector(".form-body-instruction").style.display = "none";
  }

  return (
    <div className="formContainer" >
      <div className="formWrapper" style={{ display: "none" }}>
        <div className="form-head">
          <span className="logo">
            <span className="u">U</span>ni<span className="c">C</span>hat
          </span>
        </div>
        <div className="form-body">
          <span className="title">Register</span>
          <form onSubmit={handleSubmit}>
            <div className="displayImage">
              <label htmlFor="file" className="imageUpload">
                {selectedImage ? (
                  <div className="image">
                    <i className="fas fa-check-circle"></i>
                    <img src={URL.createObjectURL(selectedImage)} alt="Selected Avatar" className="selectedImage" />
                    <p>{selectGoodOne}</p>
                  </div>
                ) : (
                  <div className="image">
                    <i className="fas fa-plus-circle"></i>
                    <img src={avatar} alt="user profile" className="placeholder" />
                    <p>1. Add an avatar</p>
                  </div>
                )}
              </label>
            </div>

            <input
              style={{ display: "none" }}
              type="file"
              id="file"
              accept="image/png, image/jpeg, image/jpg"
              onChange={handleImageChange}
              required
            />
            <label htmlFor="email">2. 
            <input
            id="email"
              required
              type="email"
              placeholder="Email (e.g., prashant.1201XXXX@lxu.in or prashant.1201XXXX@lxu.co.in)"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                const { displayName, userName } = extractNameAndUsername(e.target.value);
                setDisplayName(displayName);
                if (!userName.match(/^\d+$/)) {
                  setUserName(userName);
                }
                setErr("");
              }}
            /></label>
            
            <label htmlFor="name">
              3. 
            <input
            id="name"
              required
              type="text"
              placeholder="Display Name (e.g., Prashant)"
              value={displayName}
              onChange={(e) => setDisplayName(e.target.value)}
            />
            </label>

              <label htmlFor="usernames">
                4.
              {userName === "" || userName.match(/^\d+$/) ? (
              <input
                required
                type="text"
                placeholder="University Registration No: (e.g., 1201*****)"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
              />
            ) : (
              <input
                required
                type="text"
                placeholder="University Registration No: (e.g., 1201*****)"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
                disabled
              />
            )}
              </label>

              <label htmlFor="pass">
                5. 
              <input required type="Password" placeholder="Password" />
              </label>
            

            <button disabled={loading}>Sign up</button>

            {loading && (
              <>
                <label>Please Wait... {progress.toFixed(0)}%</label>
                <progress className="uploadProgress" value={progress.toFixed(0)} max="100" />
              </>
            )}
          </form>
          <div className="message-container">
            {allUserMessage && <p style={getMessageStyle("error")}>{allUserMessage}</p>}
            {/* {!selectedImage && <p style={getMessageStyle("info")}>Select a good profile picture for a future dating feature.</p>} */}
          </div>

          {emailSent && (
            <>
              <br />
              <p className="Warning">Email verification link sent. Check inbox, verify email. Check Junk/Spam folder.</p>
            </>
          )}
          <p>
            You do have an account? <Link to="/login">Login</Link>
          </p>
          <p>Unable to register? <a href="./doc/unableToRegister.pdf" target="_blank">Read This</a> </p>
          <p>
            Something Wrong? <Link to="/support">Support</Link>
          </p>
        </div>

      </div>

      <div className="form-body-instruction">
        <div className="instructionHead">
          <h3>Must Read!</h3>
          <p></p>
        </div>

        <div className="instructionBody">
          <div className="instructions">
            <p><span>1. </span> Registration is exclusively open to users with university email addresses.</p>
            <p><span>2. </span> While selecting a display name, it is highly encouraged to use your official name and username. This ensures that others can easily find and connect with you for dating or chatting purposes.</p>
            <p><span>3. </span> Your username must match the one provided by your university, facilitating communication with fellow university users.</p>
            <p><span>4. </span> If we detect a mismatch between the name on your email and your registration number, we will take immediate action by banning the email without prior notice.</p>
            <p><span>5. </span> Rest assured, your privacy is our priority, and we have implemented comprehensive measures to safeguard it.</p>
          </div>

          <p><span>Warning: </span> Please ensure you read these instructions thoroughly before registering, as any violations will result in a permanent ban.</p>

          <button onClick={AgreeTandC}>I Agree!</button>

        </div>

      </div>

    </div>
  );
};

export default Register;
