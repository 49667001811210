import React, {useEffect} from "react";
import './Dashboard.scss';
import Tilt from 'react-parallax-tilt';
import startupgif from '../../../assets/startup.gif';
import StartUpCard from "../StartupsCard/StartupCard";

function Dashboard() {
    

    return (
        <>
            <div className="SDashboard" >
                <div className="hero">
                    <div className="left">
                        <h2>
                            <Tilt>
                                <i className="fad fa-rocket"></i>
                            </Tilt>
                            <span>Startups</span>
                        </h2>
                        <p>Find the best startups and their ideas here. You can also pitch your own idea and find a team to work on it.</p>

                        <h2>
                            <Tilt>
                                <i className="fad fa-users"></i>
                            </Tilt>
                            <span>Developers</span>
                        </h2>

                        <p>Find the best developers to work on your idea. You can also join a team and work on a startup idea.</p>

                    </div>

                    <div className="center ">

                        <img src={startupgif} alt="startup" />

                        
                        <button className="textcontainer"> <span className="particletext bubbles">What's Your Idea?</span>  </button>
                        {/*Scroll to buttom cheron */}
                        <p>Scroll to see more</p>
                        <a href="#SDashboard"><i className="fas fa-chevron-down"></i></a>
                        


                    </div>

                    <div className="right">
                        <h2>
                            <Tilt>
                                <i className="fad fa-hands-helping"></i>
                            </Tilt>
                            <span>Mentors</span>
                        </h2>
                        <p>Find the best mentors to guide you through your journey. You can also become a mentor and help others.</p>

                        <h2>
                            <Tilt>
                                <i className="fad fa-lightbulb"></i>
                            </Tilt>
                            <span>Innovation</span>
                        </h2>

                        <p>Find the best innovative ideas and projects here. You can also share your own innovative ideas and projects.</p>

                    </div>
                </div>

            </div>

            <StartUpCard  />
        </>

    );
}

export default Dashboard;
