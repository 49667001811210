import React, { useEffect } from 'react';
import './StartupCard.scss';
import initparticles from '../../../animation';

const StartUpCard = ({ startup }) => {

    useEffect(() => {
        initparticles();
    }, []);

    return (
        <div className="StartupCard" id='#SDashboard'>
            <div className="heading">
                <h2>Projects</h2>
                <button className='textcontainer'>
                    <span className='particletext'>
                        View All <i className="fad fa-bars"></i>
                    </span>
                </button>
            </div>

            <div className="cards">

            <div className="card">
                    <div className="image">
                        <img src="https://d3gmywgj71m21w.cloudfront.net/90ecd455670cdc65327cf5cc179cb829.jpeg" alt="" />
                    </div>
                    <div className="details">
                        <div className="location-type">
                            <p><i className="fad fa-map-marker-alt"></i>Phawara</p>
                            <p>Startup</p>
                        </div>

                        <hr />
                        <div className="info">
                            <h2 className='textcontainer'>Unichat <span className='particletext confetti'>Hiring</span></h2>
                            <p className="leader"><span>Prashant Kumar</span> - Full-Stack Developer</p>
                        </div>
                    </div>

                    <div className='CTA'>
                        <button>Learn More</button>
                        <button>Back This Project</button> 
                    </div>

                    <progress value="10"  max="100" title='Progress: 20%' progress-data="50%" ></progress>

                </div>


                <div className="card">
                    <div className="image">
                        <img src="https://www.enally.in/backend/projects/Classroom%20Bucket%20-%20Under%20Maintenance_GlMUJCLy_cover3.png" alt="" />
                    </div>
                    <div className="details">
                        <div className="location-type">
                            <p><i className="fad fa-map-marker-alt"></i>Kohima</p>
                            <p>Project</p>
                        </div>

                        <hr />
                        <div className="info">
                            <h2 className='textcontainer'>Classroom Bucket <span className='particletext confetti'>Need Support</span></h2>
                            <p className="leader"><span>Aman Patel</span> - Frontend Developer</p>
                        </div>
                    </div>

                    <div className='CTA'>
                        <button>Learn More</button>
                        <button>Back This Project</button> 
                    </div>

                    <progress value="50" max="100" title='Progress: 50%' progress-data="50%" ></progress>

                </div>


                <div className="card">
                    <div className="image">
                        <img src="https://d3gmywgj71m21w.cloudfront.net/c40383fc04b217c33d6f7717e07bce68.png" alt="" />
                    </div>
                    <div className="details">
                        <div className="location-type">
                            <p><i className="fad fa-map-marker-alt"></i>Phawara</p>
                            <p>Startup</p>
                        </div>

                        <hr />
                        <div className="info">
                            <h2 className='textcontainer'>Link in Blink <span className='particletext confetti'>Need Support</span></h2>
                            <p className="leader"><span>Gauri</span> - UI/UX </p>
                        </div>
                    </div>

                    <div className='CTA'>
                        <button>Learn More</button>
                        <button>Back This Project</button> 
                    </div>

                    <progress value="80" max="100" title='Progress: 80%' progress-data="50%" ></progress>

                </div>
            </div>

        </div>
    )
}

export default StartUpCard;
