import React, { useEffect, useState, useContext } from "react";
import Navbar from "./Navbar";
import Search from "./Search";
import Chats from "./Chats";
import "../style/ChatBoxSidebar.scss";
import { MusicContext } from "../context/MusicContext";


const Sidebar = () => {
  const MusicComponent = useContext(MusicContext);

  return (
    <>
      <div className="sidebar">
        <Navbar />
        <Search />
        <Chats />
        {/* <MusicComponent /> */}
      </div>
    </>
  );
};

export default Sidebar;
