import React, { useState } from 'react';
import './DatingContent.scss';
import imagePlaceholder from './placeholder.jpg';

const DatingContent = () => {
    // State to store selected photos
    const [photos, setPhotos] = useState({
        pic1: '',
        pic2: '',
        pic3: ''
    });

    // Function to handle file selection
    const removePhoto = (pic) => {
        setPhotos(prevPhotos => ({
            ...prevPhotos,
            [pic]: ''
        }));
    }

    // Function to handle file selection
    const handleFileSelect = (event, pic) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        
        reader.onload = function (e) {
            // Update state with selected photo
            setPhotos(prevPhotos => ({
                ...prevPhotos,
                [pic]: e.target.result
            }));
        };
        
        if (file) {
            reader.readAsDataURL(file);
        }
    };

    return (
        <div className='DatingContent'>

            <div className="details">
                <i className="fas fa-badge-check"></i>
                <p>Prashant Kumar</p>
                <p>21</p>
            </div>

            <div className="datingProfile">
                <label htmlFor="pic1">
                    <i className="fas fa-times-circle"  onClick={()=>{removePhoto('pic1')}} ></i>
                    <img src={photos.pic1 || imagePlaceholder} className='Placeholder' alt="" />
                </label>
                <input type="file" id='pic1' onChange={(event) => handleFileSelect(event, 'pic1')} />

                <label htmlFor="pic2">
                    <i className="fas fa-times-circle" onClick={()=>{removePhoto('pic2')}}></i>
                    <img src={photos.pic2 || imagePlaceholder} className='Placeholder' alt="" />
                </label>
                <input type="file" id='pic2' onChange={(event) => handleFileSelect(event, 'pic2')} />

                <label htmlFor="pic3">
                    <i className="fas fa-times-circle" onClick={()=>{removePhoto('pic3')}}></i>
                    <img src={photos.pic3 || imagePlaceholder} className='Placeholder' alt="" />
                </label>
                <input type="file" id='pic3' onChange={(event) => handleFileSelect(event, 'pic3')} />
            </div>

            <div className="bio">
                <textarea name="bio" id="bio" placeholder='Write about yourself...'></textarea>
            </div>

            <div className="controls">
                <button>Disable</button>
                <button>Update</button>
            </div>
            
            <div className="status_section">
                <p id='status'></p>
            </div>
        </div>
    );
}

export default DatingContent;
