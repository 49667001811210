// DatingNavbarTop.jsx
import React, { useContext } from 'react';
import './DatingNavbarTop.scss';
import { ToggleSettingsContext } from '../context/mobileToggleContext';

const DatingNavbarTop = () => {
    const { handleToggleSettings } = useContext(ToggleSettingsContext);

    return (
        <div className="dating-navbar-top">
            <div className="dnav">
                <i className="fas fa-arrow-left"></i>
            </div>
            <div className="dname">
                <p>Campus Cupid</p>
            </div>
            <div className="dsettings">
                <i className="fas fa-heart" onClick={handleToggleSettings}></i>
                <i className="fas fa-sliders-h" ></i>
            </div>
        </div>
    );
}

export default DatingNavbarTop;
