import React, { useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import './Appbar.scss';
import Tilt from 'react-parallax-tilt';
import { Link } from "react-router-dom";

function StartupAppbar() {
    const { userDataFromDb } = useContext(AuthContext);

    const closeSidebarMenu = () => {
        document.querySelector('.Appbar-mobile').style.display = 'none';
    }

    return (
        <>
            <div className="Appbar">
                <div className="news-text">
                    <div className="scrolling-text">
                        Welcome back, {userDataFromDb.displayName}!
                        <i className="fas fa-rocket"></i> Startup Hub is your launchpad - pitch your ideas, find your dream team, and make it happen!
                        <button>
                            Invite your friends
                        </button>
                        <i className="fas fa-lightbulb"></i>
                    </div>
                </div>

                <div className="Navbar">

                    <div className="university-filter-select">
                        <p>From: </p>
                        <select name="university" id="university">
                            <option value="">All Universities</option>
                            <option value="lovely Professional University">LPU - Punjab</option>
                            <option value="GLA Mathura">GLA - Mathura</option>
                        </select>
                    </div>

                    <ul>
                        <li><Link to="/startup" ><i className="fad fa-university"></i> Universitis </Link></li>
                        <li><Link to="/startup" ><i class="fad fa-rocket"></i> Startups </Link></li>
                        <li> <Link to="/startup" ><i class="fad fa-hands-helping"></i> Mentors </Link></li>
                        <li><Link to="/startup" ><i class="fad fa-newspaper"></i> News </Link></li>
                    </ul>

                    <div className="user-and-notification">
                        <div className="user" >
                            <button class="shine"> <i className="fas fa-lightbulb"></i> Pitch Your Idea</button>
                        </div>

                    </div>
                </div>

            </div>

            <div className="mobile-title">
                <h2>Startup Hub</h2>
                <i className="fas fa-bars" onClick={() => document.querySelector('.Appbar-mobile').style.display = 'block'}></i>
            </div>

            <div className="Appbar-mobile">
                <div className="Navbar">
                    
                    <div className="close" onClick={closeSidebarMenu}>
                        <i className="fas fa-times"></i>
                    </div>

                    <div className="user-and-notification">
                        <div className="user" >
                            <button class="shine"> <i className="fas fa-lightbulb"></i> Pitch Your Idea</button>
                        </div>
                    </div>

                    <ul>
                        <li><Link to="/startup" ><i className="fad fa-university"></i> Universitis </Link></li>
                        <li><Link to="/startup" ><i class="fad fa-rocket"></i> Startups </Link></li>
                        <li> <Link to="/startup" ><i class="fad fa-hands-helping"></i> Mentors </Link></li>
                        <li><Link to="/startup" ><i class="fad fa-newspaper"></i> News </Link></li>
                    </ul>

                    

                    <div className="university-filter-select">
                        <p>From: </p>
                        <select name="university" id="university">
                            <option value="">All Universities</option>
                            <option value="lovely Professional University">LPU - Punjab</option>
                            <option value="GLA Mathura">GLA - Mathura</option>
                        </select>
                    </div>

                </div>
            </div>
        </>

    );
}

export default StartupAppbar;
