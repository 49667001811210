import React, { useContext, useEffect, useState } from "react";
import Messages from "./Messages";
import Input from "./Input";
import { ChatContext } from "../context/ChatContext";

const Chat = () => {
  const { data } = useContext(ChatContext);
  const [mouseYPosition, setMouseYPosition] = useState(20);

  const handleMouseMove = (e) => {
    // Get the height of the .chatInfo element
    const chatInfoHeight = e.currentTarget.clientHeight;

    // Get the vertical position of the mouse inside the .chatInfo element
    const mouseX = e.nativeEvent.offsetY;

    // Calculate the percentage position
    const percentage = (mouseX / chatInfoHeight) * 100;

    // Update the state with the new percentage value
    setMouseYPosition(percentage);
  };

  // Function to log all properties of an object
  const logObjectProperties = (obj, prefix = "") => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];
        if (typeof value === "object" && value !== null) {
          logObjectProperties(value, `${prefix}${key}.`);
        } else {
          //console.log(`${prefix}${key}:`, value);
        }
      }
    }
  };

  // Log all properties of the 'data' object
  logObjectProperties(data);

  const ChatingWith = data.user?.displayName;
  // const ChatingWithUID = data.user?.uid;
  const ChatingWithPhotoURL = data.user?.photoURL;
  const divStyle = {
    display: data.chatId === "null" || data.chatId === null ? "none" : "",
  };

  const revertLayout = () => {
    const sidebar = document.querySelector(".home .container .sidebar");
    const sidebar_chats = document.querySelector(".home .container .chats");
    const sidebar_search = document.querySelector(".home .container .search");
    const chat = document.querySelector(".home .container .chat");
    const userData = document.querySelector(".home .container .sidebar .navbar .user");
    const navbar = document.querySelector(".App .sidebarLeft");

    const centeralComponents = document.querySelector(".App .centerComponent");
    const home = document.querySelector(".home");
    const container = document.querySelector(".home .container");

    navbar.style.display = "flex";
    userData.style.display = "flex";
    sidebar_search.style.display = "block";
    sidebar_chats.style.display = "block";
    sidebar.style.width = "100%";
    chat.style.width = "0%";

  };

  function isMobileDevice() {
    return window.innerWidth <= 768; // Adjust the width threshold as needed
  }


  return (
    <div className="chat">

      <div className="chatInfo" style={divStyle}>
        <div className="back" onClick={() => { revertLayout() }}>
          <i className="fas fa-arrow-left"></i>
        </div>
        <div className="blur"
          style={{
            backgroundImage: `url(${ChatingWithPhotoURL})`,
            filter: "blur(5px)",
            backgroundPositionY: `${mouseYPosition}%`,
          }}
          onMouseMove={(e) => handleMouseMove(e)}
        ></div>
        <div className="userProfile">
          <img src={ChatingWithPhotoURL} alt="User Profile" />
          <h4 className="chatName">{ChatingWith}</h4>
        </div>

        <div className="chatIcons">
          <i className="fal fa-video" onClick={() => { alert("Working on this feature"); }}></i>
          <i className="fal fa-phone-alt" onClick={() => { alert("Working on this feature"); }}></i>
        </div>
      </div>
      <Messages />
      <Input />
    </div>
  );
};

export default Chat;
