import React, { useContext, useEffect, useState, useRef } from "react";
import { AuthContext } from "../context/AuthContext";
import { ChatContext } from "../context/ChatContext";
import {
  arrayUnion,
  doc,
  serverTimestamp,
  Timestamp,
  updateDoc,
} from "firebase/firestore";
import { v4 as uuid } from "uuid";
import { db, storage } from "../firebase";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import EmojiPicker from "emoji-picker-react";
import "../style/ChatBoxSendInput.scss";
import CircularProgressBar from "./CircularProgressBar";
import CryptoJS from "crypto-js";

const Input = () => {
  const [text, setText] = useState("");
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [showEmojis, setShowEmojis] = useState(false);
  const emojiPickerRef = useRef(null);
  const [isTextareaFocused, setIsTextareaFocused] = useState(false);

  const { currentUser, userDataFromDb, userDataFromMongoDb } = useContext(AuthContext);
  const { data } = useContext(ChatContext);

  const [AllUserAlertMessage, setAllUserAlertMessage] = useState("");
  const [emojiIndex, setEmojiIndex] = useState(0);
  const date = new Date();
  const formattedDate = `${date.getDate()} ${date.toLocaleString("default", { month: "short" })} ${date.getFullYear()}`;

  const secretKey = process.env.REACT_APP_CHAT_DECRYPT_KEY;

  const encrypttheData = (normalText, secretkey) => {

    if(normalText === ""){
      return "";
    }
    try {
      const ciphertext = CryptoJS.AES.encrypt(normalText, secretkey).toString();
      return ciphertext ;
    } catch (error) {
      console.error("Encryption Error:", error.message);
    }
  };

  if (AllUserAlertMessage !== "") {
    setTimeout(() => {
      setAllUserAlertMessage("");
    }, 3000);
  }

  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      if (timeoutId) clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };
  

  useEffect(() => {
    const debouncedHandleSend = debounce(handleSend, 1000);
    return () => {
      clearTimeout(debouncedHandleSend);
    };
  }, [text, file]);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];

    if (!selectedFile) {
      setFile(null);
      setPreview(null);
      return;
    }

    // Check if the selected file is a supported image or PDF
    const supportedTypes = ["image/png", "image/jpg", "image/jpeg", "image/gif", "application/pdf"];
    if (!supportedTypes.includes(selectedFile.type)) {
      // Alert the user or display an error message
      setAllUserAlertMessage("Unsupported file type. Please select an image (PNG, JPG, JPEG, GIF) or a PDF.");
      setFile(null);
      setPreview(null);
      return;
    }

    // Check if the selected file size is less than 5MB (5 * 1024 * 1024 bytes)
    if (selectedFile.size > 5 * 1024 * 1024) {
      // Alert the user or display an error message
      setAllUserAlertMessage("File size exceeds the limit. Please select a file smaller than 5MB.");
      setFile(null);
      setPreview(null);
      return;
    }

    setFile(selectedFile);

    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview(reader.result);
    };
    reader.readAsDataURL(selectedFile);
  };


  const handleSend = async () => {
    if (!text && !file) {
      // alert("Please type a message or select a file.");
      return;
    }

    if(userDataFromDb.demoUser){
      alert("Sending Message With this account is not allowed!");
      return;
    }

    setUploading(true);
    let downloadURL = null;
    let fileOriginalName = null;

    try {
      if (file) {
        const storageRef = ref(storage, "Chats_files/" + formattedDate +"/"+ data.chatId + "/" + uuid());
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            setUploadPercentage(progress);
          },
          (error) => {
            setUploading(false);
            // alert("File upload failed. Please try again.");
            setAllUserAlertMessage("File upload failed. Please try again.")
          },
          async () => {
            downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            fileOriginalName = file.name;
            await updateChatData(downloadURL, fileOriginalName);
            setUploading(false); // Set uploading to false here
          }
        );
      } else {
        await updateChatData();
        setUploading(false); // Set uploading to false here
      }
    } catch (error) {
      // alert("An error occurred. Please try again.");
      setAllUserAlertMessage("An error occurred. Please try again.");
      setUploading(false); // Set uploading to false here as well if there's an error
    }
  };

  const updateChatData = async (downloadURL, fileOriginalName) => {
    try {
      const truncatedText =
        text.length === 0
          ? "New File Received"
          : text.length > 20
            ? text.slice(0, 20) + "..."
            : text;

      const newMessage = {
        id: uuid(),
        text: encrypttheData(text, secretKey),
        senderId: currentUser.uid,
        date: Timestamp.now(),
      };

      if (downloadURL) {
        if (file.type.includes("image")) {
          newMessage.img = downloadURL;
        } else {
          newMessage.file = downloadURL;
        }
      }

      if (fileOriginalName) {
        newMessage.fileOriginalName = fileOriginalName;
      }

      await Promise.all([
        updateDoc(doc(db, "chats", data.chatId), {
          messages: arrayUnion(newMessage),
        }),
        updateDoc(doc(db, "userChats", currentUser.uid), {
          [data.chatId + ".lastMessage"]: {
            text:  encrypttheData(truncatedText, secretKey),
            lastSeen: true,
          },
          [data.chatId + ".date"]: serverTimestamp(),
        }),
        updateDoc(doc(db, "userChats", data.user.uid), {
          [data.chatId + ".lastMessage"]: {
            text: encrypttheData(truncatedText, secretKey),
            lastSeen: false,
          },
          [data.chatId + ".date"]: serverTimestamp(),
        }),
      ]);

      setText("");
      setFile(null);
      setPreview(null);
      setUploadPercentage(0);
    } catch (error) {
      // alert("Failed to send message. Please try again.");
      setAllUserAlertMessage("Failed to send message. Please try again.");
    }
  };


  let textareaData = "";

  const handleKeyPress = (e) => {

    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      textareaData = text;
      setText("");
      handleSend();
    } else if (e.key === "Enter" && e.shiftKey) {
      setText((prevText) => prevText + "\n");
    }
  };

  const toggleEmojiPicker = () => {
    setShowEmojis(!showEmojis);
  };

  const handleSelectEmoji = (emoji) => {
    setText((prevText) => prevText + emoji.emoji);
  };

  const handleClickOutside = (event) => {
    if (emojiPickerRef.current && !emojiPickerRef.current.contains(event.target)) {
      setShowEmojis(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const clearFileSelection = () => {
    setFile(null);
    setPreview(null);
  };
  const emojis = [
    "😆", "😘", "😜", "🤩", "😠",
    "❤️", "😍", "🥰", "😂", "😎",
    "😊", "🤗", "😋", "🥳", "😇",
  ];
  useEffect(() => {
    const interval = setInterval(() => {
      setEmojiIndex((prevIndex) => (prevIndex + 1) % emojis.length);
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, []);
  const currentEmoji = emojis[emojiIndex];

  // textarea focus
  const handleFocus = () => {
    setIsTextareaFocused(true);
  };

  const handleBlur = () => {
    setIsTextareaFocused(false);
  };

  return (
    <div className="input" hidden={data.chatId === "null" || data.chatId === null}>
      <p className="alert">{AllUserAlertMessage} {file && <span>{file.name.slice(0, 15)}</span>}</p>
      <div className="inputs">
        <div style={{ position: "relative", width: "100%" }}>
          {showEmojis && (
            <div ref={emojiPickerRef} style={{ position: "absolute", top: "-820%", right: "0%" }}>
              <EmojiPicker onEmojiClick={handleSelectEmoji} disableAutoFocus={true} />
            </div>
          )}
          <textarea
            placeholder="Type something..."
            onChange={(e) => setText(e.target.value)}
            value={text}
            onKeyPress={handleKeyPress}
            id="textarea"
            onFocus={handleFocus}
        onBlur={handleBlur}
          />
        </div>
        <div className="send" id="sendbtn" style={{ display: isTextareaFocused ? 'none' : 'flex' }}>
          {preview && !file.type.includes("image") && <div className="pdf-icon">PDF</div>}
          {/* {file && <span>{file.name.slice(0, 5)}</span>} */}
          {uploadPercentage !== null && uploadPercentage !== 0 && (
            <CircularProgressBar percentage={uploadPercentage} />
          )}
          <div className="preview-image">
            {preview && file.type.includes("image") && <img src={preview} alt="Preview" className="previewImage" />}
            {file && uploadPercentage >= 0 && uploadPercentage <= 1 && (
              <button onClick={clearFileSelection} style={{ marginLeft: "10px" }}>
                x
              </button>
            )}
          </div>

          <input
            type="file"
            style={{ display: "none" }}
            id="file"
            onChange={handleFileChange}
          />
          <span onClick={toggleEmojiPicker} className="emojis">
            {currentEmoji}
          </span>
          <label htmlFor="file">
            <i className="lni lni-paperclip"></i>
          </label>
          <button
            onClick={() => {
              textareaData = text; // Store textarea data before emptying it
              setText(""); // Empty the textarea immediately after sending the message
              handleSend(); // Call handleSend function
            }}
            disabled={uploading}
          >
            {uploading ? "Sending.." : <span className="material-symbols-outlined">send</span>}
          </button>
        </div>
      </div>

    </div>
  );
};

export default Input;
