import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import { collection, query, getDocs, where, doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";
import "../style/DatingSlider.scss";
import CardSlider from "./Card-Slider";
import { useNavigate, Link } from "react-router-dom";
import datingPic from "../img/datingbg.png";

const Dating = () => {
  const { userDataFromDb, currentUser } = useContext(AuthContext);
  const [usersData, setUsersData] = useState([]);
  const [hiddenBtn, setHiddenBtn] = useState(false);
  const [romanticButtonText, setRomanticButtonText] = useState("Start Mingling");
  const [romanticbtn, setRomanticbtn] = useState(true);
  const [noUserLeft, setNoUserLeft] = useState(false);

  useEffect(() => {
    const randomWord = randomRomanticButtontext();
    setRomanticButtonText(randomWord);
  }, []);

  const getUsers = async () => {
    try {
      const usersCollectionRef = collection(db, "users");
      const usersQuery = query(
        usersCollectionRef,
        where("isDating", "==", true),
        where("gender", "!=", userDataFromDb.gender)
      );

      const usersSnapshot = await getDocs(usersQuery);
      const fetchedUsersData = usersSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      const currentUserDocRef = doc(db, "dating", currentUser.uid);
      const currentUserDocSnapshot = await getDoc(currentUserDocRef);

      if (currentUserDocSnapshot.exists()) {
        const currentUserData = currentUserDocSnapshot.data();
        const likedArray = currentUserData.liked || [];
        const notInterestedArray = currentUserData.notInterested || [];

        const filteredUsersData = fetchedUsersData.filter(
          (user) =>
            !likedArray.includes(user.id) &&
            !notInterestedArray.includes(user.id)
        );

        setUsersData(filteredUsersData);
        setHiddenBtn(true);
        setRomanticbtn(false);

        if (filteredUsersData.length === 0) {
          setNoUserLeft(true);
        }
      } else {
        // If the "dating" document doesn't exist, set usersData directly from fetchedUsersData
        setUsersData(fetchedUsersData);
        setHiddenBtn(true);
        setRomanticbtn(false);
      }
    } catch (error) {
      console.error("Error fetching users: ", error);
    }
  };

  function randomRomanticButtontext() {
    const buttonArray = [
      "Explore Romance",
      "Discover Matches",
      "Love Connection",
      "Start Mingling",
    ];

    const randomIndex = Math.floor(Math.random() * buttonArray.length);
    return buttonArray[randomIndex];
  }

  return (
    <div className="Dating">
      <h3>Discover</h3>
      <img
        src={datingPic}
        className="datingPic"
        style={{ width: "90%" }}
        hidden={hiddenBtn}
        alt="Dating Background"
      />
      <p className="quote" hidden={hiddenBtn}>
        Just be honest. It'll save everyone's time.
      </p>
      <button onClick={getUsers} hidden={hiddenBtn} className="ShowCards">
        Start Swiping
      </button>
      {usersData.length > 0 && <CardSlider usersData={usersData} />}
      {noUserLeft && (
        <>
          <img
            src={datingPic}
            className="datingPic"
            style={{ width: "90%" }}
            alt="No Users Left"
          />
          <p className="error">
            There are no more users available for swiping at the moment. Feel
            free to try again later.
          </p>
        </>
      )}
    </div>
  );
};

export default Dating;
