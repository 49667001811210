import './TopStartUpSliders.scss';

const TopStartUpSliders = () => {
    return (
        <div className="TopStartUpSliders">
            <div className="topStartUp">
                <div className="slide-track">
                    <div className="slide">
                        <img src="./assets/startup1.png" height="100" width="250" alt="" />
                    </div>
                    <div className="slide">
                        <img src="./assets/startup2.png" height="100" width="250" alt="" />
                    </div>
                    <div className="slide">
                        <img src="./assets/startup3.png" height="100" width="250" alt="" />
                    </div>
                    <div className="slide">
                        <img src="./assets/startup4.png" height="100" width="250" alt="" />
                    </div>
                    <div className="slide">
                        <img src="./assets/startup1.png" height="100" width="250" alt="" />
                    </div>
                    <div className="slide">
                        <img src="./assets/startup2.png" height="100" width="250" alt="" />
                    </div>
                    <div className="slide">
                        <img src="./assets/startup3.png" height="100" width="250" alt="" />
                    </div>
                    <div className="slide">
                        <img src="./assets/startup4.png" height="100" width="250" alt="" />
                    </div>
                    <div className="slide">
                        <img src="./assets/startup1.png" height="100" width="250" alt="" />
                    </div>
                    <div className="slide">
                        <img src="./assets/startup2.png" height="100" width="250" alt="" />
                    </div>
                    <div className="slide">
                        <img src="./assets/startup3.png" height="100" width="250" alt="" />
                    </div>
                    <div className="slide">
                        <img src="./assets/startup4.png" height="100" width="250" alt="" />
                    </div>
                    <div className="slide">
                        <img src="./assets/startup1.png" height="100" width="250" alt="" />
                    </div>
                    <div className="slide">
                        <img src="./assets/startup2.png" height="100" width="250" alt="" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TopStartUpSliders;