import React from "react";
import "../../style/GroupChat/IndexPage.scss";
import Logo from "../../components/GroupChat/Logo";
import GroupChat from "../../assets/groupChat.png";
import GroupChatSideBar from "../../assets/groupchatSIdebar.png";
import JoinRoomPng from "../../assets/JoinRoom.webp";
import CreateRoomPng from "../../assets/CreateRoom.png";
import { useNavigate } from "react-router-dom";
import { Toaster, toast } from "react-hot-toast";
import Footer from "../../components/GroupChat/Footer";

function IndexPage() {
  const navigate = useNavigate();

  const handleNewRoom = function () {
    navigate("/newroom");
  };

  const handleExistRoom = function () {
    navigate("/exroom");
  };

  return (
    <div className="index__page">
      <Toaster position="top-center" reverseOrder={false}></Toaster>
      <div>
        {/* <Logo /> */}
        <div className="index__contents">
          <div className="index__banner__text">
            <h1>
              Now chat <span>anonymously</span> with anyone
            </h1>
            <h3>
              Seek <strong>connection</strong>, <strong>friendship</strong>, and support. Our Group Chat Room connects <span>students</span>, <span>teachers</span>, and more for <span>anonymous</span> or <span>authentic</span> conversations.
              <br /><br />
              <span className="hideOnMobile">
                be it Computer Science, Agriculture, Medical, Business, or any other field.
              </span>
            </h3>

            {/* <div className="groupChat">
              <img src={GroupChat} alt="Chat Banner" />
            </div> */}

            <div className="index__btns">

              <div className="start_a_room" onClick={handleNewRoom}>
                <img src={CreateRoomPng} alt="Create Room" />
                <button className="btn__new__chat nowrap" onClick={handleNewRoom} title="Start Room and Share Room Code">
                  Start Room
                </button>
              </div>

              <div className="join_a_room" onClick={handleExistRoom}>
                <img src={JoinRoomPng} alt="Join Room" />
                <button className="btn__new__chat nowrap" onClick={handleExistRoom} title="Join Room Via Room Code">
                  Join Room
                </button>
              </div>
            </div>
          </div>
          <div className="index__banner__img">
            <img src={GroupChatSideBar} alt="Chat Banner" />
          </div>
        </div>
      </div>
      <div>
      </div>
    </div>
  );
}

export default IndexPage;
