import React, {useContext} from 'react';
import './SNavbar.scss';
import { AuthContext } from '../../../context/AuthContext';

const SNavbar = () => {
    const { currentUser, userDataFromDb } = useContext(AuthContext);

    const triggerAlert = () => {
        alert("You clicked the icons")
    }

    return (
        <div className="SNavbar">
            <div className="search">
                
                <div className="inner">
                <input type="text" placeholder="Search"/> 
                <i class="fas fa-search"></i>
                </div>
            </div>

            <div className="nav">
                <i class="fas fa-bell" onClick={triggerAlert}></i>

                <div className="message">
                    <i class="fas fa-envelope" onClick={triggerAlert}></i>
                </div>
                
                
                <div className="user">
                    <i class="fas fa-circle" onClick={triggerAlert}></i>
                    <p>{userDataFromDb.displayName}</p>
                </div>
            </div>

            <div className="mobile-menu">
                <i class="fas fa-arrow-left"></i>
                <i class="fas fa-bars"></i>
            </div>

        </div>
    )
}

export default SNavbar;