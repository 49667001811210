import React, { useState } from 'react';
import SetConfession from '../components/SetConfession';
import '../style/Confession.scss';
import catimage from '../img/cat.png';
import ConfessionCard from '../components/ConfessionCard';
import TimeComponent from '../components/Timer';

const Confession = () => {
    const [setvisibility, setSetvisibility] = useState(false);

    const confessSomething = () => {
        setSetvisibility(true); // Show the confession modal
    }

    return (
        <div className='Confession'>

            <div className="top">
                    <TimeComponent />
        
                <img src={catimage} alt="cat" className='cat' />
            </div>  

            <div className="bottom">
            
                <div className="Cbutton">
                    {setvisibility && <SetConfession setSetvisibility={setSetvisibility} />}
                    <button onClick={confessSomething}>I want to Confess Something!</button>
                </div>

                <div className="rules">
                    <p>#Rules: <span>Avoid mentioning specific university names, personal information, or any content that directly reveals identities. You may utilize names while refraining from excessive disclosure.</span> </p>
                </div>
            </div>

        </div>
    )
}

export default Confession;
