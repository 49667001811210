import React, { useState, useEffect } from 'react';
import './UMusic.scss';

const UMusic = () => {
    const [videoId, setVideoId] = useState('vMXg4V-fKGw');
    const [videoUrl, setVideoUrl] = useState('');

    useEffect(() => {
        fetchVideo();
    }, []);

    const fetchVideo = async () => {
        try {
            const response = await fetch(`https://www.googleapis.com/youtube/v3/videos?id=${videoId}&key=AIzaSyBx-xfQevErXJr7i6Ywx4BeG-RZDkGoVwQ&part=snippet`);
            const data = await response.json();
            if (data.items && data.items.length > 0) {
                const videoUrl = `https://www.youtube.com/embed/${videoId}`;
                setVideoUrl(videoUrl);
            } else {
                console.error('Video not found');
            }
        } catch (error) {
            console.error('Error fetching video:', error);
        }
    };

    const handlePlay = () => {
        const iframe = document.getElementById('youtube-player');
        iframe.contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*');
    };

    const handlePause = () => {
        const iframe = document.getElementById('youtube-player');
        iframe.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
    };

    const handleInputChange = (event) => {
        setVideoId(event.target.value);
    };

    return (
        <div className='UMusic'>
            <div className="blurbg">
                <div className="controlls">
                    <i className="fad fa-play" onClick={handlePlay}></i>
                    {/* <i className="fad fa-pause" onClick={handlePause}></i> */}
                    <div className="url">
                        <i className="fab fa-youtube"></i>
                        <input
                            type="text"
                            value={videoId}
                            onChange={handleInputChange}
                        />
                    </div>
                </div>
            </div>
            {videoUrl && (
                <iframe
                    id="youtube-player"
                    src={videoUrl}
                    title="Alok & Alan Walker - Headlight (Fajar Asia Remix)"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                ></iframe>
            )}
        </div>
    );
};

export default UMusic;
