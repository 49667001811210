import React, { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { ChatContext } from "../context/ChatContext";
import "../style/RightUpdatesSidebar.scss";
import NewsSlider from "./NewsSlider";
import Dating from "./Dating";
import TimeComponent from "./Timer";

const RightUpdatesSideBar = () => {
    const { currentUser, userDataFromDb } = useContext(AuthContext);
    const { data } = useContext(ChatContext);

    return (
        <div className="right-updates-sidebar">
            <div className="news-slider-right-sidebar">

            </div>
            <div className="dating-slider-right-sidebar">
                <Dating />
                
                <TimeComponent />
            </div>
        </div>
    )
}

export default RightUpdatesSideBar;