import React, { useState, useEffect, useContext } from "react";
import { db } from "../../../../firebase";
import './ProfileContent.scss';
import axios from 'axios';
import { AuthContext } from "../../../../context/AuthContext";
import { doc, updateDoc } from "firebase/firestore";

const ProfileContent = () => {
    const { userDataFromDb, userDataFromMongoDb } = useContext(AuthContext);
    const [imageId, setImageId] = useState("");
    const [imageURL, setImageURL] = useState("");
    const [image, setImage] = useState(null);
    const [isUpdating, setIsUpdating] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);

    const LIVE_URL = process.env.REACT_APP_LIVE_URL;

    const [formData, setFormData] = useState({
        displayName: userDataFromDb.displayName || "",
        dob: "",
        gender: "",
        university: "",
        course: "",
        branch: "",
        specialization: "",
        semester: "",
        phone: "",
        address: "",
        bio: "",
        photoFileId: "",
        email: userDataFromDb.email,
        userName: userDataFromDb.userName,
        accountCreated: userDataFromDb.accountCreated,
        isAdmin: userDataFromDb.isAdmin,
        isDating: userDataFromDb.isDating,
        isVerified: userDataFromDb.isVerified,
        isPremium: userDataFromDb.isPremium,
        photoURL: userDataFromMongoDb.photoURL,
        uid: userDataFromDb.uid,
    });

    useEffect(() => {
        if (Object.keys(userDataFromMongoDb).length !== 0) {
            setFormData(prevState => ({
                ...prevState,
                ...userDataFromMongoDb
            }));
        }
    }, [userDataFromMongoDb]);


    const handleImageChange = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
            setImage(reader.result);
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const handleChange = (event) => {
        const { id, value } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [id]: value
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userDataFromDb.authToken}`
                },
                onUploadProgress: progressEvent => {
                    const { loaded, total } = progressEvent;
                    const progress = Math.round((loaded / total) * 100);
                    setUploadProgress(progress);
                }
            };

            let updatedFormData = { ...formData };

            // Check if the user has selected a new image
            if (image) {
                setIsUpdating(true); 
                
                const imageData = new FormData();
                imageData.append('image', image);
                imageData.append('existingImageId', imageId);
                imageData.append('existingImageURL', imageURL);
                imageData.append('userid', userDataFromMongoDb.uid || userDataFromDb.uid);

                // Make a POST request to upload image
                const imageResponse = await axios.post(`${LIVE_URL}/upload-image`, imageData, config);

                // Update imageURL and imageId states
                setImageId(imageResponse.data.imageId);
                setImageURL(imageResponse.data.imageURL);

                // update imageurl in collection users in firestore "photoURL"
                const userDocRef = doc(db, "users", userDataFromDb.uid);
                await updateDoc(userDocRef, {
                    photoURL: imageResponse.data.imageURL
                });


                // Update formData with new photoURL and photoFileId
                updatedFormData = {
                    ...updatedFormData,
                    photoURL: imageResponse.data.imageURL,
                    photoFileId: imageResponse.data.imageId
                };
            }

            // Make a POST request to save profile settings with updated formData
            await axios.post(`${LIVE_URL}/profile-settings`, updatedFormData, config);

            // If successful, show a success message or redirect to another page
            alert('Profile settings saved successfully');

        } catch (error) {
            // Handle errors
            console.error('Error saving profile settings:', error);
            alert('Failed to save profile settings. Please try again.');
        } finally {
            setIsUpdating(false);
            setUploadProgress(0); 
        }
    };

    return (
        <div className='ProfileContent'>
            <div className="details">
                <i className="fas fa-badge-check"></i>
                <p>{userDataFromMongoDb.displayName}</p>
                <p>21</p>
            </div>

            <div className="profile">
                <div className="profilePic">
                    <label htmlFor="pic1">
                        <img src={image ? image : formData.photoURL || "https://via.placeholder.com/150"} className='Placeholder' alt="profile" />

                        <i className="fa fa-camera"></i>
                    </label>
                    <input type="file" id='pic1' onChange={handleImageChange} />
                </div>

                <div className="basicInfo">
                    <label htmlFor="userName">
                        <p>Username</p>
                        <input type="text" id='userName' value={userDataFromDb.userName} disabled={true} />
                    </label>

                    <label htmlFor="email">
                        <p>Email</p>
                        <input type="email" id='email' value={userDataFromDb.email} disabled={true} />
                    </label>

                    <label htmlFor="displayName">
                        <p>Name & Display Name</p>
                        <input type="text" id='displayName' value={formData.displayName} onChange={handleChange} />
                    </label>

                    <label htmlFor="dob">
                        <p>DOB</p>
                        <input type="date" id='dob' value={formData.dob} onChange={handleChange} />
                    </label>

                    <label htmlFor="gender">
                        <p>Gender</p>
                        <select id="gender" value={formData.gender} onChange={handleChange}>
                            <option value="">Select</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                            <option value="other">Other</option>
                        </select>
                    </label>

                    <label htmlFor="university">
                        <p>University</p>
                        <select id="university" value={formData.university} onChange={handleChange}>
                            <option value="">Select</option>
                            <option value="LPU (Lovely Professional University)">LPU (Lovely Professional University)</option>
                            <option value="GLA Mathura">GLA Mathura</option>
                        </select>
                    </label>

                    <label htmlFor="course">
                        <p>Course</p>
                        <input type="text" id='course' value={formData.course} onChange={handleChange} />
                    </label>

                    <label htmlFor="branch">
                        <p>Branch</p>
                        <input type="text" id='branch' value={formData.branch} onChange={handleChange} />
                    </label>

                    <label htmlFor="specialization">
                        <p>Specialization</p>
                        <input type="text" id='specialization' value={formData.specialization} onChange={handleChange} />
                    </label>

                    <label htmlFor="semester">
                        <p>Semester</p>
                        <input type="number" id='semester' value={formData.semester} onChange={handleChange} />
                    </label>

                    <label htmlFor="phone">
                        <p>Phone</p>
                        <input type="tel" id='phone' value={formData.phone} onChange={handleChange} />
                    </label>

                    <label htmlFor="address">
                        <p>Address</p>
                        <input type="text" id='address' value={formData.address} onChange={handleChange} />
                    </label>

                    <label htmlFor="bio" className="bio">
                        <p>Bio</p>
                        <textarea id="bio" value={formData.bio} onChange={handleChange} placeholder='Write about yourself...'></textarea>
                    </label>
                </div>

                <div className="submit">
                    <button onClick={handleSubmit} disabled={isUpdating} style={{ background: isUpdating ? "#032466" : " " }}>{isUpdating ? "Saving..." : "Save"}</button>
                </div>
                {isUpdating && <progress value={uploadProgress} max="100">{uploadProgress}%</progress>}
            </div>
        </div>
    );
}

export default ProfileContent;
