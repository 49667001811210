import "../style/ConfessionCard.scss";
import React, { useEffect, useState } from "react";
import { collection, getDocs, where } from "firebase/firestore";
import { db } from "../firebase";
import ribbon0 from "../img/ribbon0.png"

const ConfessionCard = () => {
    const [confessions, setConfessions] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const fetchConfessions = async () => {
            const querySnapshot = await getDocs(
                // Use the "where" function to filter documents
                collection(db, "confession"),
                where("isApproved", "==", true)
            );

            const confessionData = [];
            querySnapshot.forEach((doc) => {
                confessionData.push(...doc.data().confessions);
            });

            setConfessions(confessionData);
        };

        fetchConfessions();
    }, []);


    const handleNextClick = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % confessions.length);
    };

    useEffect(() => {
        // Automatically click the "Next" button every 10 seconds
        const intervalId = setInterval(handleNextClick, 10000);
        
        return () => {
            clearInterval(intervalId);
        };
    }, [currentIndex, confessions.length]);

    return (
        <div className="confession-card-Revaled">
            <div className="confess-card-content">
                <img src={ribbon0} alt="" className="ribbonround" />
            </div>
            {confessions.map((confession, index) => (
                <div
                    className="confess-card-content"
                    key={index}
                    style={{ display: index === currentIndex ? "block" : "none" }}
                >
                    <h1>#{index + 1}</h1>
                    <p>Confession by {confession.shared_as} - {confession.type}</p>

                    <div className="confessions">
                        <p>{confession.confession}</p>
                    </div>

                    <img src={ribbon0} alt="" className="ribbonround" />
                    <div className="buttonss">
                        <button className="nextbutton" onClick={handleNextClick}>More</button>
                    </div>

                </div>
            ))}
        </div>
    );
};

export default ConfessionCard;
