import React from "react";
import "../../style/GroupChat/FileItem.scss";
import { handleDownloadMedia } from "../../helper/HandleDownloadMedia";
// import { BsDownload } from "react-icons/bs";

function FileItem(props) {
  const { isAuthor, data } = props;
  

  const getFirstCharacterOfName = (name) => {
    const [firstCharacter] = name;
    return firstCharacter;
  }

  return (
    <div className={`media__item ${isAuthor === "you" ? "you" : "other"}`}>
      <div className="item__meta__data">
        <div className="item__author">
          {isAuthor === "you" ? "You" : getFirstCharacterOfName(data.USER_NAME)}
        </div>
        <div className="item__time">{data.USER_NAME + ` - ` + data.TIME}</div>
      </div>
      
      <div className={`file__content content ${isAuthor === "you" ? "you" : "other"} `} >
        <div className={`documents-file ${isAuthor === "you" ? "you" : "other"} `}>
          <div className="FileName" onClick={() => handleDownloadMedia(data)} >{data.CONTENT_NAME}</div>
          <div className={`file_metaDescriptions ${isAuthor === "you" ? "you" : "other"} `}>
            <div className="file__size">{data.CONTENT_SIZE}</div>
            <div
              className={`download__icon__wrapper`}
              onClick={() => handleDownloadMedia(data)}
            >
              <i className={`fas fa-download ${isAuthor === "you" ? "you" : "other"}`}></i>
            </div>

          </div>
        </div>

      </div>
    </div>
  );
}

export default FileItem;
