import React, { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import { ChatContext } from "../context/ChatContext";
import { Icon } from "@iconify/react";
import { updateDoc } from "firebase/firestore";
import { auth, db, storage } from "../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { doc } from "firebase/firestore";
import '../style/MessageBox.scss';
import CryptoJS from "crypto-js";

const Message = ({ message, onDelete }) => {
  const { currentUser, userDataFromDb, userDataFromMongoDb} = useContext(AuthContext);
  const { data } = useContext(ChatContext); // Assuming chatId is available in ChatContext
  const ChatingWithUID = data.user?.uid;
  const ref = useRef();

  const secretKey = process.env.REACT_APP_CHAT_DECRYPT_KEY;

  // Function to log all properties of an object
  const logObjectProperties = (obj, prefix = "") => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];
        if (typeof value === "object" && value !== null) {
          logObjectProperties(value, `${prefix}${key}.`);
        } else {
          console.log(`${prefix}${key}:`, value);
        }
      }
    }
  };

  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
    //console.log("effect worked");
  }, [message]);

  const getActualFileName = () => {
    if (message.fileOriginalName) {
      return message.fileOriginalName;
    } else if (message.file) {
      const lastSlashIndex = message.file.lastIndexOf("/");
      return message.file.substring(lastSlashIndex + 1);
    }
    return "";
  };

  const formatTime = (time) => {
    const options = {
      hour: "numeric",
      minute: "numeric",
    };
    return new Intl.DateTimeFormat("en-US", options).format(time);
  };

  const isCurrentDate = (messageDate) => {
    const today = new Date();
    return (
      messageDate.getDate() === today.getDate() &&
      messageDate.getMonth() === today.getMonth() &&
      messageDate.getFullYear() === today.getFullYear()
    );
  };

  // Regular expression to match URLs in the message text
  const urlRegex = /(https?:\/\/[^\s]+)/g;

  const handleDecrypt = (normalText, secretkey) => {
    try {
      const bytes = CryptoJS.AES.decrypt(normalText, secretkey);
      const decryptedText = bytes.toString(CryptoJS.enc.Utf8);
      return decryptedText;
    } catch (error) {
      console.error("Decryption Error:", error.message);
      return null;
    }
  };

  const wrapTextWithLinks = (text) => {
    // Decrypt the input text first
    const decryptedText = handleDecrypt(text, secretKey);

    if (decryptedText === null) {
      // Decryption failed, return an error message or handle it as you wish
      return <p>Error Decrypting Message</p>;
    }

    // Split the decrypted text into an array of strings, separating URLs from non-URL parts
    const parts = decryptedText.split(urlRegex);

    // Map over the parts and replace URLs with anchor tags
    const wrappedText = parts.map((part, index) => {
      if (part.match(urlRegex)) {
        return (
          <a href={part} target="_blank" className="stringLink" key={index} rel="noopener noreferrer">
            {part}
          </a>
        );
      } else {
        return part;
      }
    });

    return <>{wrappedText}</>;
  };

  if (!data.chatId) {
    return <div>Please select some chat</div>;
  }

  const messageDate = message.date.toDate();
  const currentTime = new Date();
  const timeDifference = currentTime.getTime() - messageDate.getTime();
  const showTime = timeDifference > 120000; // 2 minutes in milliseconds

  const showMessageTime = showTime
    ? formatTime(messageDate)
    : isCurrentDate(messageDate)
      ? "Just now"
      : "";

  const handleDeleteMessage = async (messageId) => {
    try {
      onDelete();

      const chatRef = doc(db, "chats", data.chatId);

      await updateDoc(chatRef, {
        messages: data.messages.filter((msg) => msg.id !== messageId),
      });

      console.log("Message deleted successfully!");
    } catch (error) {
      console.error("Error deleting message:", error);
    }
  };


  return (
    <div
      ref={ref}
      className={`message ${message.senderId === currentUser.uid && "owner"}`}
    >
      <div className="messageInfo">
        <img
          src={
            message.senderId === currentUser.uid
              ? userDataFromDb.photoURL
              : data.user.photoURL
          }
          alt="userImage"
        />
        {message.senderId === currentUser.uid && (
          <>
            <div className="delete" onClick={() => handleDeleteMessage(message.id)}>
            </div>
          </>
        )}
      </div>

      <div className={`messageContent ${message.senderId === currentUser.uid && "ownerContent"}`}>
        <span className="time">{showMessageTime}</span>

        {message && message.text && (
          <p>{wrapTextWithLinks(message.text)}</p>
        )}
        
        {message.img && <div className="messageImg"> <img src={message.img} alt="Message Image" onClick={() => { window.open(message.img, "_blank") }} /> </div>}
        
        {message.fileOriginalName && message.fileOriginalName.endsWith(".pdf") && (
          <p className="file">
            <a className="link" href={message.file} target="_blank" rel="noopener noreferrer" title="View PDF">
              <Icon icon="vscode-icons:file-type-pdf2" className="icon" /> <span className="FileName">{message.fileOriginalName}</span>
            </a>
          </p>
        )}

        {message.file && message.fileOriginalName && !message.fileOriginalName.endsWith(".pdf") && (
          <p>
            <a href={message.file} download title="Download File">
              {getActualFileName()}
            </a>
          </p>
        )}
      </div>
    </div>
  );
};

export default Message;
