import React, { useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";

const Admin = () => {
  const {userDataFromDb } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    // Check if userDataFromDb is available and user is an admin
    //console.log("userDataFromDb:", userDataFromDb);
    if (userDataFromDb && userDataFromDb.isAdmin === false) {
      console.log("Redirecting to login...");
      navigate("/login");
    }
  }, [userDataFromDb, navigate]);

  if (!userDataFromDb) {
    // Handle the case when userDataFromDb is still loading or not available
    console.log("Loading user data...");
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h1>Admin</h1>
      <Link to="/updatenews">Manage News</Link>
      <Link to="/manageusers">Manage Users</Link>
      <Link to="/manage-support">Manage Support</Link>
    </div>
  );
};

export default Admin;
