// TimerCardAlt.js
import React from "react";
import { Link } from "react-router-dom";
import "../style/TimerCardAlt.scss";

const TimerCardAlt = ({ ribbon, timeRemaining, content }) => {
    return (
        <>  
            {content === "" ? (
                <div className="timer-card-alt">
                    <img src={ribbon} alt="ribbon" className="image" />
                    <h4>confessions will be revealed in</h4>
                    <h3>{timeRemaining}</h3>
                    <Link to="/confession">Check Now</Link>
                </div>
            ) : (
                <div className="timer-card-alt">
                    <img src={ribbon} alt="ribbon" className="image" />
                    <h4>Confession is revealed</h4>
                    <Link to="/confession">Check Now</Link>
                </div>
            )}
        </>
    );
};

export default TimerCardAlt;
