// context/MobileToggleContext.js
import React, { createContext, useState } from 'react';

export const ToggleSettingsContext = createContext();

export const MobileToggleContext = ({ children }) => {
    const [toggleSettings, setToggleSettings] = useState(false);

    const handleToggleSettings = () => {
        setToggleSettings(!toggleSettings);
    }

    return (
        <ToggleSettingsContext.Provider value={{ toggleSettings, handleToggleSettings }}>
            {children}
        </ToggleSettingsContext.Provider>
    );
};
