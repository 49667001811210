import React, { useContext, useState, useEffect } from "react";
import {
  collection,
  query,
  where,
  getDocs,
  setDoc,
  doc,
  updateDoc,
  serverTimestamp,
  getDoc,
} from "firebase/firestore";
import { db } from "../firebase";
import { AuthContext } from "../context/AuthContext";
import "../style/ChatBoxSearch.scss";

const Search = () => {
  const [userName, setUserName] = useState("");
  const [user, setUser] = useState(null);
  const [err, setErr] = useState(false);
  const { currentUser, userDataFromDb,  } = useContext(AuthContext);
  const [searching, setSearching] = useState("");
  const CurrentUserUserName = currentUser.email ? currentUser.email.replace(/[^\d]/g, "") : null;
  const [whatToSearch, setWhatToSearch] = useState("");

  const handleSearch = async () => {

    if (userName.trim() === "") {
      setUser(null);
      setErr(false);
      return;
    }

    if(userName === userDataFromDb.userName){
      return;
    }

    if(/^\d+$/.test(userName)){
      setWhatToSearch("userName")
    }
    else{
      setWhatToSearch("displayName")
    }

    const q = query(collection(db, "users"), where(whatToSearch, ">=", userName), where(whatToSearch, "<=", userName + '\uf8ff'));

    try {
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        setUser(doc.data());
      });
      setSearching("");
      setErr(false); // Reset error state if user is found
    } catch (err) {
      setUser(null);
      setErr(true);
    }
  };

  useEffect(() => {
    // Start searching when the userName state changes
    handleSearch();
  }, [userName]);

  const handleKey = (e) => {
    if (e.code === "Enter") {
      handleSearch();
      setSearching("");
    }
  };

  const handleSelect = async () => {
    //check whether the group(chats in firestore) exists, if not create
    const combinedId =
      currentUser.uid > user.uid
        ? currentUser.uid + user.uid
        : user.uid + currentUser.uid;
    try {
      const res = await getDoc(doc(db, "chats", combinedId));

      if (!res.exists()) {
        //create a chat in chats collection
        await setDoc(doc(db, "chats", combinedId), { messages: [] });

        //create user chats
        await updateDoc(doc(db, "userChats", currentUser.uid), {
          [combinedId + ".userInfo"]: {
            uid: user.uid,
            displayName: user.displayName,
            photoURL: user.photoURL,
          },
          [combinedId + ".date"]: serverTimestamp(),
          [combinedId + ".lastMessage"]: {
            lastSeen: true,
          },
        });

        await updateDoc(doc(db, "userChats", user.uid), {
          [combinedId + ".userInfo"]: {
            uid: currentUser.uid,
            displayName: currentUser.displayName,
            photoURL: currentUser.photoURL,
          },
          [combinedId + ".date"]: serverTimestamp(),
        });
      }
    } catch (err) { }

    setUser(null);
    setUserName("")
  };

  const handleInputChange = (e) => {
    // Allow typing in the input field without resetting the state
    setUserName(e.target.value);

    if (e.target.value.length >= 3) {
      setSearching("Searching... Try in Captalized letters")
      handleSearch();

    }
  };

  const handleSelectValidation = () => {
    if (userDataFromDb.userName !== userName) {
      // once you add this user it will show up in the chat list for both users and you cant delete it as of now
      const isSure = window.confirm("Confirm adding " + user.displayName + " to the chat list? This action is irreversible as of now and will be reflected on both sides.");
      if (isSure) {
        handleSelect();
      }
    }
    else {
      alert("You can't chat with yourself!")
      setUserName("")
    }
  }

  return (
    <div className="search">
      <div className="searchForm">
        <input
          type="text"
          placeholder="@Username or Display Name"
          onKeyDown={handleKey}
          onChange={handleInputChange}
          value={userName}
        />
      </div>
      <p className="Searching">{searching && searching}</p>
      {err && <span className="userNotFound">User not found!</span>}
      {user && (
        <div className="userChat" onClick={handleSelectValidation}>
          <img src={user.photoURL} alt="" />
          <div className="userChatInfo">
            <span>{user.displayName}</span>
            <span className="joineddate">{new Date(user.accountCreated).toLocaleDateString("en-GB", {
              day: "2-digit",
              month: "long",
              year: "2-digit",
            })}</span>
          </div>
        </div>
      )}
      <div className="Messages">
        <h2>Messages</h2>
      </div>

    </div>
  );
};

export default Search;
