import React, { useState, useEffect } from "react";
import { collection, getDocs, doc, updateDoc, deleteDoc } from "firebase/firestore";
import { db, storage } from "../firebase";
import "./scss/ManageAndApproveNews.scss";
import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from "firebase/storage";
const ManageAndApproveNews = () => {
    const [newsData, setNewsData] = useState([]);
    const [marqueeHovered, setMarqueeHovered] = useState(true);

    useEffect(() => {
        const fetchNewsData = async () => {
            try {
                const newsCollectionRef = collection(db, "news");
                const querySnapshot = await getDocs(newsCollectionRef);
                const newsArray = querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setNewsData(newsArray);
            } catch (error) {
                console.error("Error fetching news data:", error);
            }
        };

        fetchNewsData();
    }, []);

    const formatNewsDate = (timestamp) => {
        if (!timestamp) {
            return "Invalid Date";
        }

        const date = new Date(timestamp);
        const day = String(date.getDate()).padStart(2, "0");
        const month = getMonthName(date.getMonth());
        const year = date.getFullYear();
        const hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, "0");
        const period = hours >= 12 ? "PM" : "AM";
        const formattedHours = (hours % 12) || 12;

        return `${day} ${month} ${year} - ${formattedHours}:${minutes} ${period}`;
    };

    const getMonthName = (month) => {
        const monthNames = [
            "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];
        return monthNames[month];
    };

    const deleteEmptyDocuments = async () => {
        try {
            const newsCollectionRef = collection(db, "news");
            const querySnapshot = await getDocs(newsCollectionRef);
            const emptyDocs = querySnapshot.docs.filter((doc) => doc.data().news.length === 0);

            // Delete empty documents
            const deletePromises = emptyDocs.map((doc) => deleteDoc(doc.ref));
            await Promise.all(deletePromises);

            console.log("Empty documents deleted successfully!");
        } catch (error) {
            console.error("Error deleting empty documents:", error);
        }
    };

    const deleteNewsItem = async (newsId, itemIdToDelete) => {
        try {
            const newsDocRef = doc(db, "news", newsId);

            // Get the current news data
            const currentNewsData = newsData.find((newsItem) => newsItem.id === newsId);
            const updatedNews = currentNewsData.news.filter((news) => news.id !== itemIdToDelete);

            // If the item to be deleted has a cover image, delete the image from Firebase Storage
            const itemToDelete = currentNewsData.news.find((news) => news.id === itemIdToDelete);
            if (itemToDelete?.coverImage) {
                const imgRef = ref(storage, itemToDelete.coverImage);
                await deleteObject(imgRef);
                console.log("Image deleted from Firebase Storage!");
            }

            // Update the Firestore document with the modified news array
            await updateDoc(newsDocRef, { news: updatedNews });
            console.log("News item deleted successfully!");

            // Update the state after deletion to reflect the changes in the UI
            setNewsData((prevNewsData) =>
                prevNewsData.map((newsItem) => {
                    if (newsItem.id === newsId) {
                        return {
                            ...newsItem,
                            news: updatedNews,
                        };
                    }
                    return newsItem;
                })
            );

            // Check if the news array is empty and delete the document if so
            if (updatedNews.length === 0) {
                await deleteDoc(newsDocRef);
                console.log("Document deleted successfully!");
                // Remove the deleted document from the state
                setNewsData((prevNewsData) =>
                    prevNewsData.filter((newsItem) => newsItem.id !== newsId)
                );
            }

            // After deleting the news item, check and delete empty documents
            await deleteEmptyDocuments();
        } catch (error) {
            console.error("Error deleting news item:", error);
        }
    };


    const togglePublishStatus = async (newsId, itemIdToToggle) => {
        try {
            const newsDocRef = doc(db, "news", newsId);

            // Get the current news data
            const currentNewsData = newsData.find((newsItem) => newsItem.id === newsId);
            const updatedNews = currentNewsData.news.map((news) =>
                news.id === itemIdToToggle ? { ...news, isPublished: !news.isPublished } : news
            );

            // Update the Firestore document with the modified news array
            await updateDoc(newsDocRef, { news: updatedNews });
            console.log("News item publish status updated successfully!");

            // Update the state after updating the publish status to reflect the changes in the UI
            setNewsData((prevNewsData) =>
                prevNewsData.map((newsItem) => {
                    if (newsItem.id === newsId) {
                        return {
                            ...newsItem,
                            news: updatedNews,
                        };
                    }
                    return newsItem;
                })
            );
        } catch (error) {
            console.error("Error updating news item publish status:", error);
        }
    };

    return (
        <div className="manageAndApproveNews">
            <h3>All News</h3>
            <div className="scrollnews">
                {/* Use the newsData array to populate the div */}
                {newsData && newsData.length > 0 ? (
                    newsData.map((newsItem) => (
                        <div key={newsItem.id} className="newsCard">
                            {newsItem.news.map((news) => (
                                <div key={news.id} className="card"
                                    onMouseEnter={() => setMarqueeHovered(false)}
                                    onMouseLeave={() => setMarqueeHovered(true)}
                                >
                                    {
                                        news.coverImage && (
                                            <div className="newsleft">
                                                <img src={news.coverImage} alt="" />
                                            </div>
                                        )
                                    }

                                    <div className="newsright">
                                        <div className="author-time">
                                            <p className="newstime">
                                                {news.date ? formatNewsDate(news.date) : "Invalid Date"}
                                            </p>
                                            <p className="newsauthor">{news.author}</p>
                                        </div>

                                        <marquee behavior="scroll" direction="left" onMouseEnter={() => setMarqueeHovered(true)} onMouseLeave={() => setMarqueeHovered(false)}>
                                            <h2 className="newshead">{news.newsheading}</h2>
                                        </marquee>

                                        <p className="newstype">{news.newsType}</p>
                                        <p className="newsContent">{news.newsContent}</p>

                                        <div className="buttons">
                                            <button
                                                onClick={() => togglePublishStatus(newsItem.id, news.id)}
                                                style={{ backgroundColor: news.isPublished ? "#04ce33" : "#bd0c0c" }}
                                            >
                                                {news.isPublished ? "Unpublish" : "Publish"}
                                            </button>
                                            {/* <button>
                                                {news.isVerified ? "Verified" : "Unverified"}
                                            </button> 
                                        */}
                                            <button
                                                className="delete"
                                                onClick={() => deleteNewsItem(newsItem.id, news.id)}
                                            >
                                                Delete
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ))
                ) : (
                    <p>No news data available.</p>
                )}
            </div>
        </div>
    );
};

export default ManageAndApproveNews;

