import React, { useState, useEffect } from "react";
import "../style/YTMusic.scss";
import musicData from "../Data/Music.json";

const Music = () => {
    const [player, setPlayer] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [videoTitle, setVideoTitle] = useState("");
    const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
    const videoIds = musicData.videoIds;


    useEffect(() => {
        const tag = document.createElement("script");
        tag.src = "https://www.youtube.com/iframe_api";
        tag.async = true;  // Load asynchronously
        tag.setAttribute("origin", window.location.origin);
        const firstScriptTag = document.getElementsByTagName("script")[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    
        window.onYouTubeIframeAPIReady = () => {
            const playerInstance = new window.YT.Player("ytplayer", {
                height: "200px",
                width: "300px",
                videoId: videoIds[currentVideoIndex],
                events: {
                    onReady: onPlayerReady,
                    onStateChange: onPlayerStateChange,
                },
            });
            setPlayer(playerInstance);
        };
    }, [currentVideoIndex]);

    useEffect(() => {
        const timer = setInterval(() => {
            if (player && isPlaying) {
                setCurrentTime(player.getCurrentTime());
            }
        }, 1000);

        // Check if it's time to load the next video
        if (
            Number(formatTimeModified(currentTime)) ===
            Number(formatTimeModified(duration)) - 1 
        ) {
            // setNextVideoLoop(1); // Set nextVideoLoop to 1 to avoid triggering the next video multiple times
            setTimeout(() => {
                handleNextVideo();
            }, 5000); // Load the next video after a delay of 5 seconds
        }

        return () => clearInterval(timer);
    }, [player, isPlaying, currentTime, duration]);

    const onPlayerReady = (event) => {
        console.log("Player is ready");
        event.target.playVideo();
    };

    const onPlayerStateChange = (event) => {
        setCurrentTime(event.target.getCurrentTime());
        setIsPlaying(event.data === window.YT.PlayerState.PLAYING);
        setVideoTitle(event.target.getVideoData().title);
        setDuration(event.target.getDuration());

        if (event.data === window.YT.PlayerState.ENDED) {
            // Video has ended, load the next video
            handleNextVideo();
        }
    };

    const handlePlayPause = () => {
        if (isPlaying) {
            player.pauseVideo();
        } else {
            player.playVideo();
        }
        setIsPlaying(!isPlaying);
    };

    const handleStop = () => {
        player.stopVideo();
        setIsPlaying(false);
    };

    const handleSkip = () => {
        const newTime = currentTime + 10;
        player.seekTo(newTime, true);
    };

    const handleSkipBackward = () => {
        const newTime = currentTime - 10;
        player.seekTo(newTime, true);
    };

    const handleNextVideo = () => {
        const nextIndex = (currentVideoIndex + 1) % videoIds.length;
        setCurrentVideoIndex(nextIndex);
    
        if (player) {
            player.loadVideoById(videoIds[nextIndex]);
            const videoData = player.getVideoData();
            if (videoData) {
                setVideoTitle(videoData.title);
            }
        }
    };
    

    const handlePreviousVideo = () => {
        const nextIndex = (currentVideoIndex - 1 + videoIds.length) % videoIds.length;
        setCurrentVideoIndex(nextIndex);
        player.loadVideoById(videoIds[nextIndex]);
        setVideoTitle(player.getVideoData().title);
    };


    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
    };

    const formatTimeModified = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes}${seconds < 10 ? "0" : ""}${seconds}`;
    };

    return (
        <>
            <div className="add-music">
                <div className="timeline-title">
                    <div className="timerunning" >
                        <span className="timeline">{formatTime(currentTime)}</span>{"-"}
                        <span className="timeline">{formatTime(duration)}</span>
                    </div>

                    <marquee scrollamount="3"  className="videoTitle">
                        {" "}
                        {videoTitle}{" "}
                    </marquee>
                </div>
            </div>
            <div
                className="ytmusic"
                style={{ animation: isPlaying ? "changeColors 2s infinite" : "" }}
            >
                <div id="ytplayer"></div>
                <div className="buttons">
                    <div className="controls">
                        <button onClick={handleSkipBackward}>
                            <i className="fal fa-backward"></i>
                        </button>
                        <button onClick={handlePreviousVideo}>
                            <i className="fas fa-step-backward"></i>
                        </button>
                        {isPlaying ? (
                            <button onClick={handlePlayPause}>
                                <i className="fas fa-pause"></i>
                            </button>
                        ) : (
                            <button onClick={handlePlayPause}>
                                <i className="fas fa-play"></i>
                            </button>
                        )}
                        <button onClick={handleStop}>
                            <i className="fas fa-stop"></i>
                        </button>
                        <button onClick={handleNextVideo} id="nextButton">
                            <i className="fas fa-step-forward"></i>
                        </button>
                        <button onClick={handleSkip}>
                            <i className="fal fa-forward"></i>
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Music;
