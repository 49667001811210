import React, { useState, useEffect, useContext } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase";
import "../style/Uninews.scss";
import { AuthContext } from "../context/AuthContext";
import AddNews from "../components/AddNews";
import { Link } from "react-router-dom";
const regularPost = "https://media1.giphy.com/media/OatYTMZ3cW8Bq/source.gif"

const UniNews = () => {
    const [newsData, setNewsData] = useState([]);
    const [filteredNewsData, setFilteredNewsData] = useState([]);
    const { currentUser, userDataFromDb, userDataFromMongoDb } = useContext(AuthContext);
    const [showFullContent, setShowFullContent] = useState(false);
    const [expandedNews, setExpandedNews] = useState({});

    const toggleContent = (newsId) => {
        setExpandedNews(prevState => ({
            ...prevState,
            [newsId]: !prevState[newsId]
        }));
    };

    const truncateContent = (content) => {
        const words = content.split(" ");

        if (words.length > 40) {
            return words.slice(0, 40).join(" ") + "...";
        } else {
            return content;
        }
    };

    useEffect(() => {
        const fetchNewsData = async () => {
            try {
                const newsCollectionRef = collection(db, "news");
                const querySnapshot = await getDocs(newsCollectionRef);
                const newsArray = querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));

                setNewsData(newsArray);
                setFilteredNewsData(newsArray);
            } catch (error) {
                console.error("Error fetching news data:", error);
            }
        };

        fetchNewsData();
    }, []);


    const handleFilterNews = (method, value) => {
        const filteredNews = newsData.map((newsItem) => ({
            ...newsItem,
            news: newsItem.news.filter((news) => news[method] === value && news.isPublished === true),
        }));
        setFilteredNewsData(filteredNews);
    };

    const handleClearFilters = () => {
        setFilteredNewsData(newsData);
    };

    const formatNewsDate = (timestamp) => {
        if (!timestamp) {
            return "Invalid Date";
        }

        const date = new Date(timestamp);
        const day = String(date.getDate()).padStart(2, "0");
        const month = getMonthName(date.getMonth());
        const year = date.getFullYear();
        const hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, "0");
        const period = hours >= 12 ? "PM" : "AM";
        const formattedHours = (hours % 12) || 12;

        return `${day} ${month} ${year} - ${formattedHours}:${minutes} ${period}`;
    };

    const getMonthName = (month) => {
        const monthNames = [
            "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];
        return monthNames[month];
    };

    return (
        <div className="NewsPage">
            <h1 className="page-title">Feed</h1>
            <div className="container">
                <div className="left">
                    <AddNews />

                    {/* <div className="regularPoster">
                        <img src={regularPost} alt="regularPost" />
                        <h6>Regular contributors enjoy automatic post approval. Your consistent engagement is highly encouraged!</h6>
                    </div> */}

                    <div className="old-card">
                        <p> <i className="fad fa-newspaper"></i> New NewsPaper UI <Link to="/oldNews">Try Now!</Link></p>
                    </div>

                </div>
                <div className="center">
                    {/* <p className="ctime">{CurrentDate()}</p> */}
                    <div className="content">
                        {filteredNewsData && filteredNewsData.length > 0 ? (
                            filteredNewsData.map((newsItem) => ({
                                ...newsItem,
                                news: newsItem.news
                                    .filter((news) => news.isPublished === true && news.newsType !== "Confession")
                                    .sort((a, b) => b.date - a.date)
                            })).sort((a, b) => {
                                const aLatestDate = a.news.length > 0 ? a.news[0].date : 0;
                                const bLatestDate = b.news.length > 0 ? b.news[0].date : 0;
                                return bLatestDate - aLatestDate;
                            }).map((newsItem) => (
                                <div key={newsItem.id}>
                                    {newsItem.news.map((news) => (
                                        <div className="card" key={news.id}>
                                            <div className="card-top">
                                                <h3>{news.author}</h3>
                                                <p>{news.date ? formatNewsDate(news.date) : "Invalid Date"}</p>
                                            </div>
                                            <div className="card-center">
                                                <h2>{news.newsheading}</h2>
                                                {news.coverImage && (
                                                    <div className="image">
                                                        <img src={news.coverImage} alt="" />
                                                    </div>
                                                )}
                                                {expandedNews[news.id] || news.newsContent.length < 40 ? (
                                                    <div className="news" dangerouslySetInnerHTML={{ __html: news.newsContent }} />
                                                ) : (
                                                    <div className="news" dangerouslySetInnerHTML={{ __html: truncateContent(news.newsContent) }} />
                                                )}
                                                {news.newsContent.split(" ").length > 40 && (
                                                    <p onClick={() => toggleContent(news.id)} className="readMore">
                                                        {expandedNews[news.id] ? " See Less" : "See More"}
                                                    </p>
                                                )}
                                                <p className="type">#{news.newsType}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ))
                        ) : (
                            <p>No news data available.</p>
                        )}
                    </div>

                </div>
                <div className="right">
                    <div className="card">
                        <div className="image">
                            <img src={userDataFromDb.photoURL} alt="" />
                        </div>
                        <h2>{userDataFromDb.displayName}</h2>
                        <p>{userDataFromMongoDb.university &&  
                            userDataFromMongoDb.university} </p>
                        
                    </div>
                    <div className="card">
                        <h4>Categories</h4>
                        <div className="buttons">
                            {newsData && newsData.length > 0 && (
                                <>
                                    <button onClick={() => handleClearFilters()}>Home</button>
                                    {newsData.map((newsItem) => {
                                        const renderedNewsTypes = new Set();
                                        return newsItem.news
                                            .filter((news) => news.isPublished === true)
                                            .filter((news) => news.newsType !== "Confession")
                                            .map((news) => {
                                                if (!renderedNewsTypes.has(news.newsType)) {
                                                    renderedNewsTypes.add(news.newsType);
                                                    return (
                                                        <button
                                                            key={news.id}
                                                            onClick={() => handleFilterNews("newsType", news.newsType)}
                                                        >
                                                            {news.newsType}
                                                        </button>
                                                    );
                                                }
                                                return null;
                                            });
                                    })}
                                </>
                            )}
                        </div>
                        <p className="OutdatedNews">Outdated news will be automatically deleted within a few days.</p>
                    </div>
                </div>
            </div>
        </div>
    );

};

export default UniNews;
