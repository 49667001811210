const TimerCard = ({ ribbon, ribbon4, ribbon1, formatTimeIn12HourFormat, targetTimeSlotStart, targetTimeSlotEnd, timeRemaining }) => {
    return (
        <>
            <div className="timer-running">
                <img src={ribbon} alt="riboon" className='ribbon' />
            </div>
            <div className="timer-running">
                <img src={ribbon4} alt="riboon" className='ribbon4' />
            </div>
            <div className="timer-running">
                <img src={ribbon1} alt="riboon" className='ribbon1' />
            </div>
            <div className="timer-running">
                <img src={ribbon1} alt="riboon" className='ribbon1' />
            </div>
            <div className='timer-running'>
                <img src={ribbon} alt="riboon" className='ribbon' />
                <h3>All confessions will be revealed at {formatTimeIn12HourFormat(targetTimeSlotStart)}</h3>
                <h1>{timeRemaining} <span>Remaining</span> </h1>
                <p>Every day from {formatTimeIn12HourFormat(targetTimeSlotStart)} to {formatTimeIn12HourFormat(targetTimeSlotEnd)}, when emotions come to the forefront, we'll share heartfelt confessions that express love, pain, happiness, and sorrow. Join us during this special time to lighten your heart's load. You can confess and unburden your heart.</p>
                <p className='quote'>Sometimes all you need is someone to understand all your unsaid words.</p>
            </div>
        </>
    )
}

export default TimerCard