import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { AuthContextProvider } from "./context/AuthContext";
import { ChatContextProvider } from "./context/ChatContext";
import { MusicContext, Music } from "./context/MusicContext";
import { Provider } from "react-redux";
import { store } from "./store/store";

setInterval(() => {
  if (!window.location.href.includes('localhost')) {
    console.clear();
    console.info(`

    ██     ██  █████  ██ ████████     ██     ██  █████  ██ ████████     ███    ██  ██████  
    ██     ██ ██   ██ ██    ██        ██     ██ ██   ██ ██    ██        ████   ██ ██    ██ 
    ██  █  ██ ███████ ██    ██        ██  █  ██ ███████ ██    ██        ██ ██  ██ ██    ██ 
    ██ ███ ██ ██   ██ ██    ██        ██ ███ ██ ██   ██ ██    ██        ██  ██ ██ ██    ██ 
     ███ ███  ██   ██ ██    ██         ███ ███  ██   ██ ██    ██        ██   ████  ██████   █ █ █ █ █ █
                                                                                           
       
    🔮🌟 Welcome to UniChat Developer's Realm! 🌟🔮
    
    🧙‍♂️  You have entered a magical world of enchanting code, crafted with passion and care by the wizardly hands of Prashant Kumar! 🎩✨
    
    🏰  As you explore the wonders of UniChat, remember that this realm is carefully guarded and protected. ✨
    
    🚫  Unauthorized meddling with the enchanted code may disrupt the harmony of this mystical land. Beware of the consequences! ⚡
    
    🌌  We encourage you to use UniChat as intended, embrace the magic of connections, and spread positivity throughout the realm! 🌈
    
    💬  If you encounter any mysterious bugs or need support, seek guidance from our magical support wizards at:
         Website: https://enally.in/contact
         LinkedIn: https://www.linkedin.com/in/03prashantpk/
    
    🌟  May your journey through UniChat be filled with magic and delightful experiences! Enjoy your stay! 🌟
    `);
    
    console.info(`
      ⚠️⚠️  Attention, Traveler!  ⚠️⚠️
    
      ❗️  We must warn you not to tamper with the delicate spells woven into the fabric of UniChat. ❗️
    
      ⚡️  Unauthorized attempts to modify the magic may result in unforeseen consequences! ⚡️
    
      🌌  Explore the realm freely, connect with others, and embrace the magic of UniChat, but do so with care and respect for the enchanted code! 🌌
    
      🔮  Let the magic unfold before your eyes, but refrain from attempting to cast your own spells within this domain! 🔮
    
      🧙‍♂️  For support or inquiries, reach out to our skilled wizards at:
           Website: https://enally.in/contact
           LinkedIn: https://www.linkedin.com/in/03prashantpk/
    
      🌟  May your journey through UniChat be safe and filled with enchantment! 🌟
    `);
    
  }
}, 200000);


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
  <AuthContextProvider>
    <ChatContextProvider>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </ChatContextProvider>
  </AuthContextProvider>
  </Provider>
 
);
