import React from 'react';

const Food_Banner = () => {
  return (
    <div className="banner">
      <div className="banner-text">
        <h2>Discover the Best Food Around Your University</h2>
        <p>Explore top-rated restaurants, food vendors, and mess facilities based on real user reviews. Find your next favorite meal and share your experience by adding ratings!</p>
      </div>
    </div>
  );
};

export default Food_Banner;
