import React from 'react'
import Sidebar from '../components/Sidebar'
import Chat from '../components/Chat'
// import { useNavigate, Link } from "react-router-dom";
import '../style/Home.scss'
import RightUpdatesSideBar from '../components/RightUpdatesSideBar';

const Home = () => {
  return (
    <div className='home'>
      <div className="container">
        <Sidebar  />
        <Chat />
      </div>
      <div className="sidebarRight">
        <RightUpdatesSideBar/>
      </div>
    </div>
  )
}

export default Home