import React, { useEffect, useContext, useState } from 'react';
import { AuthContext } from "../context/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import "./scss/ManageSupport.scss";

const ManageSupport = () => {
  const { userDataFromDb, currentUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [supportData, setSupportData] = useState([]);
  const [selectedSupport, setSelectedSupport] = useState(null);
  const [loading, setLoading] = useState(true);
  const [compose, setCompose] = useState(false);

  // Get From Process.env
  const LIVE_URL = process.env.REACT_APP_LIVE_URL;

  const toggleCompose = () => {
    setCompose(!compose);
  };


  useEffect(() => {
    // Check if userDataFromDb is available and user is an admin
    if (userDataFromDb && userDataFromDb.isAdmin === false) {
      console.log("Redirecting to login...");
      navigate("/login");
    }
  }, [userDataFromDb, navigate]);

  function calculateDateAge(dateString) {
    // Convert the date string to a format parseable by Date object
    const formattedDate = dateString.replace(/(\d+) ([a-zA-Z]+) (\d+) - (\d+:\d+[aApPmM]+)/, '$2 $1, $3 $4');

    const currentDate = new Date();
    const givenDate = new Date(formattedDate);

    const timeDifference = currentDate - givenDate;
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) {
      return `${days} day${days > 1 ? 's' : ''} ago`;
    } else if (hours > 0) {
      return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    } else if (minutes > 0) {
      return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    } else {
      return 'just now';
    }
  }


  useEffect(() => {
    const fetchSupportData = async () => {
      try {
        const response = await fetch(`${LIVE_URL}/manage-support`, {
        //const response = await fetch('http://localhost:7000/manage-support', {
          method: "get",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userDataFromDb.authToken}`,
          },
        });

        const data = await response.json();

        // Sort the data by resolved status and then by date
        const sortedData = data.sort((a, b) => {
          if (a.resolved !== b.resolved) {
            return a.resolved ? 1 : -1; // Resolved comes first
          }
          // If both are resolved or both are not resolved, sort by date
          return new Date(b.date) - new Date(a.date);
        });

        setSupportData(sortedData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching support data:', error);
        setLoading(false);
      }
    };

    fetchSupportData();
  }, []);

  const handleSupportClick = (support) => {
    setSelectedSupport(support);
  };

  if (!userDataFromDb) {
    // Handle the case when userDataFromDb is still loading or not available
    console.log("Loading user data...");
    return <div>Loading...</div>;
  }


  return (
    <div className="Manage-Support">
      <div className="support-sidebar">
        <div className="log-user">
          <img src={userDataFromDb.photoURL} alt="user" />
          <div className="userdata">
            <h3>Manage Support </h3>
            <p>{userDataFromDb.displayName}</p>
          </div>
        </div>

        <div className="problems">
          {loading ? (
            <div>Loading data...</div>
          ) : (
            supportData.map((support) => (
              <div
                key={support._id}
                className={`support-sidebar-card ${support._id === selectedSupport?._id ? 'selected' : ''}`}
                onClick={() => handleSupportClick(support)}
              >
                <h3>{support.name} </h3>
                <p className='title'>{support.title.split(' ').slice(0, 6).join(' ')}...</p>
                <p className='date'>{calculateDateAge(support.date)}</p>
                <p className={` ${support.resolved ? "resolved" : "notResolved"}`}>{support.resolved ? "Resolved" : "Pending"}</p>
              </div>
            ))
          )}
        </div>

      </div>

      <div className="support-content">
        {selectedSupport ? (
          <div className='support-content-card'>
            <div className="title">
              <p>{selectedSupport.type}</p>
            </div>

            <div className="details">
              <h2><i class="fas fa-signature"></i>{selectedSupport.name}</h2>
              <p className='email'><i class="fas fa-envelope-open-text"></i> {selectedSupport.email}</p>
              <p className='date'><i class="fas fa-calendar-alt"></i> {selectedSupport.date}</p>
              <p className={`status ${selectedSupport.resolved ? "resolved" : "notResolved"}`}><i class="far fa-meh"></i> Status: {selectedSupport.resolved ? "Resolved" : "Pending"}</p>
            </div>

            <div className="description">
              <h3>{selectedSupport.title}</h3>
              <p>{selectedSupport.description}</p>
            </div>

            <div className="compose" style={{display: compose ? 'flex' : 'none'}}>
              <div className="action"><button onClick={toggleCompose} className='closebtn'><i class="fas fa-times"></i></button> <span>Working on this...</span></div>
              <input type="text" value={`Response to: ${selectedSupport.title}`} disabled />
              <div className="compose-body">
                <textarea name="" id=""></textarea>
              </div>
              <button>Send</button>
            </div>

            <div className="compose-button" style={{display: compose ? 'none' : ' '}}>
              <button onClick={toggleCompose}><i class="fas fa-pen"></i> Compose</button>
            </div>
            

          </div>
        ) : (
          <p>Select a support request to view details</p>
        )}
      </div>
    </div>
  );
}

export default ManageSupport;
