import React from 'react';
import './Magic.scss';

const Magic = () => {
    return (
        <div className='DMagic'>
            <div className="heading">
                <div><p>Magic Paring</p></div>
                <p className='message'>This Valentine (2024)</p>
                <div className="bar"></div>
            </div>

            <div className="Mform">
                <input type="text" placeholder='Pair Code' />
                <button className='magic-button'><i class="fas fa-magic"></i> Magic</button>
            </div>

        </div>
    )   
}

export default Magic;