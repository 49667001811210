import React, { useContext, useEffect, useState } from 'react';
import './Settings.scss';
import Magic from '../Magic/Magic';
import { ToggleSettingsContext } from '../context/mobileToggleContext';

const DatingSettings = () => {
    const { toggleSettings, handleToggleSettings } = useContext(ToggleSettingsContext);

    let newStyle = {
        backgroundColor: "none"
    }

    if(window.innerWidth < 768) {
        newStyle = {
            display: !toggleSettings ? "none" : "flex"
        }
    }



    return (
        <div className="DatingSettings" style={newStyle}>
            <div className="settings">
                <h2>Campus Cupid</h2>
                <p className='tag'>Just Be Honest It will Save Everyone's Time.</p>

                <div className="stats-matches">
                    <button><i class="far fa-chart-line"></i> Stats</button>
                    <button><img src="assets/swipes.png" alt="" /> Matches</button>
                </div>

                <div className="content">

                    <p className='title'>Matches</p>

                    <div className="card">
                        <img src="https://i.ibb.co/c1MPLYd/max-ilienerwise-ZEg-UWPf-V5-Yo-unsplash.jpg" alt="profile" />
                        <div className="details">
                            <h3>Devil Amit</h3>
                            <p>120*****</p>
                        </div>
                        <div className="clipboard">
                            <i class="fas fa-user-times"></i>
                            <i class="fas fa-copy"></i>
                        </div>
                    </div>

                    <div className="card">
                        <img src="https://i.ibb.co/c1MPLYd/max-ilienerwise-ZEg-UWPf-V5-Yo-unsplash.jpg" alt="profile" />
                        <div className="details">
                            <h3>Devil Amit</h3>
                            <p>120*****</p>
                        </div>
                        <div className="clipboard">
                            <i class="fas fa-user-times"></i>
                            <i class="fas fa-copy"></i>
                        </div>
                    </div>


                    <div className="card">
                        <img src="https://i.ibb.co/c1MPLYd/max-ilienerwise-ZEg-UWPf-V5-Yo-unsplash.jpg" alt="profile" />
                        <div className="details">
                            <h3>Devil Amit</h3>
                            <p>120*****</p>
                        </div>
                        <div className="clipboard">
                            <i class="fas fa-user-times"></i>
                            <i class="fas fa-copy"></i>
                        </div>
                    </div>

                    <div className="card">
                        <img src="https://i.ibb.co/c1MPLYd/max-ilienerwise-ZEg-UWPf-V5-Yo-unsplash.jpg" alt="profile" />
                        <div className="details">
                            <h3>Devil Amit</h3>
                            <p>120*****</p>
                        </div>
                        <div className="clipboard">
                            <i class="fas fa-user-times"></i>
                            <i class="fas fa-copy"></i>
                        </div>
                    </div>

                </div>

                <Magic />

            </div>



        </div>
    )
}

export default DatingSettings;