import React, { useState } from 'react';
import "../style/Dashboard.scss";
import dashboard_image from "../img/dashboard-male.jpeg";
import { set } from 'date-fns';
import { Link } from 'react-router-dom';

const Dashboard = () => {
 

    return (
        <div className='Dashboard'>
            <iframe src="https://enally.in/chatapp/download/" style={{border:0}} allowfullscreen="" loading="lazy"></iframe>
        </div>
    )
}

export default Dashboard;
