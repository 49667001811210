import React, {useContext} from 'react';
import { AuthContext } from '../../context/AuthContext';

const Food_Header = () => {
    const { userDataFromDb, currentUser } = useContext(AuthContext);
  return (
    <div className="header">
      <span className="greeting">Hello, {userDataFromDb.displayName}</span>
      <input type="text" className="search-bar" placeholder="Search" />
    </div>
  );
};

export default Food_Header;
