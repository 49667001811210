import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { signInWithEmailAndPassword, onAuthStateChanged, signOut } from "firebase/auth";
import { auth } from "../firebase";

const Login = () => {
  const [err, setErr] = useState(false);
  const [userVerified, setUserVerified] = useState(true); // Track if user's email is verified
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const [emailEnding, setEmailEnding] = useState("");

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async  (user) => {


      if (user) {
        setUserVerified(user.emailVerified);

        try {
          const authToken = await user.getIdToken();
          // console.log("User Authentication Token:", authToken);
        } catch (error) {
          // console.error("Error fetching authentication token:", error);
        }
      }
    });

    return () => unsubscribe();
  }, []);

  const setLoginSession = (email, userId) => {
    const expires = new Date();
    expires.setDate(expires.getDate() + 7);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const email = e.target[0].value;
    const password = e.target[1].value;

    if(e.target[0].value === "" || e.target[1].value === ""){
      setErr(true);
      setErrorMessage("Please fill all the fields");
      return;
    }

    try {
      // Sign in user
      const res = await signInWithEmailAndPassword(auth, email, password);

      // Check if the user's email is verified
      if (res.user && res.user.emailVerified) {
        setErr(false);
        setLoginSession(email, res.user.uid);
        navigate("/");
      } else {
        setErr(true);
      }
    } catch (err) {
      if (err.code === "auth/too-many-requests") {
        setErrorMessage(
          "Too many failed login attempts. Please try again later or reset your password."
        );
      } else {
        setErr(true);
      }
    }
  };

  const handleEmailChange = (e) => {
    setEmailEnding(e.target.value);
  };

  setTimeout(() => {
    setErr("")
    setErrorMessage("")
  },5000)

  const handleLogoutClick = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error("Error logging out:", error);
    }
  }

  useEffect(() => {
    if (!userVerified) {
      handleLogoutClick();
    }
  }, [userVerified]);

  return (
    <div className="formContainer">
      <div className="formWrapper">
        <div className="form-head">
          <span className="logo"><span className="u">U</span>ni<span className="c">C</span>hat</span>
        </div>
        <div className="form-body">
          <span className="title">{!userVerified ? "Verify" : "Login"}</span>
          {!userVerified ? (
            <p className="Warning">
              Email not verified. Check your inbox, spam, or junk folder for verification.  {" "}
            </p>
          ) : (
            <form onSubmit={handleSubmit}>
              <input
                type="email"
                placeholder="Email"
                value={emailEnding}
                onChange={handleEmailChange}
              />
              <input type="password" placeholder="Password" />
              <button>Sign in <i className="fal fa-long-arrow-right"></i></button>
            </form>
          )}
          <p className="error">
            {errorMessage && <span>{errorMessage}</span>}
            {err && !errorMessage && <span>Invalid email or password</span>}
          </p>
          <p className="Warning resend">
            {!userVerified
              ? "Resend verification email "
              : "You don't have an account? "}
            <Link to="/register">Register</Link>
          </p>
          <p>
            <Link to="/password-reset">Forgot Password?</Link>
          </p>
          <p>
            Something Wrong? <Link to="/support">Support</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
