import React from "react";
import "../../style/GroupChat/PictureItem.scss";
import { handleDownloadMedia } from "../../helper/HandleDownloadMedia";
import { BsDownload } from "react-icons/bs";

function PictureItem(props) {
  const { isAuthor, data } = props;

  const getFirstCharacterOfName = (name) => {
    const [firstCharacter] = name;
    return firstCharacter;
  }

  return (
    <div className={`media__item ${isAuthor === "you" ? "you" : "other"}`}>
      <div className="item__meta__data">
        <div className="item__author">
          {isAuthor === "you" ? "You" : getFirstCharacterOfName(data.USER_NAME)}
        </div>
        <div className="item__time">{data.USER_NAME + ` - ` + data.TIME}</div>
      </div>

      <div className="picture__content">
        <div className={`picture ${isAuthor === "you" ? "you" : "other"} `}>
          <img
            src={`data:image/jpeg;base64,${data.CONTENTBASE64}`}
            alt={data.CONTENT_NAME}
          />

          <div className="file_metaDescriptions ">
            <div className="file__size">{data.CONTENT_SIZE}</div>
            <div
              className="download__icon__wrapper"
              onClick={() => handleDownloadMedia(data)}
            >
              <i className={`fas fa-download ${isAuthor === "you" ? "you" : "other"} `}></i>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}

export default PictureItem;
