import React, { useState, useEffect, useContext } from "react";
import "../style/DatingSlider.scss";
import { AuthContext } from "../context/AuthContext";
import { db } from "../firebase";
import { doc, setDoc, updateDoc, arrayUnion, getDoc } from "firebase/firestore";
import { Link } from "react-router-dom";
import datingMatch from "../img/matched.png";
import cheersbg from "../img/cheers.gif";

const CardSlider = ({ usersData }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [swipeDirection, setSwipeDirection] = useState(null);
    const { currentUser } = useContext(AuthContext);
    const [showMatch, setShowMatch] = useState(false);
    const [totalSwipes, setTotalSwipes] = useState(0);
    const [noMoreSwipesLeft, setNoMoreSwipesLeft] = useState(false);

    const handleSwipe = async (direction) => {
        setSwipeDirection(direction);
        setCurrentIndex((prevIndex) => (prevIndex + 1) % usersData.length);
        setTimeout(() => setSwipeDirection(null), 500);

        const currentUserId = currentUser.uid;
        const swipedUserId = usersData[currentIndex].id;

        try {
            const currentUserDocRef = doc(db, "dating", currentUserId);
            const currentUserDocSnap = await getDoc(currentUserDocRef);

            if (!currentUserDocSnap.exists()) {
                await setDoc(currentUserDocRef, { liked: [], likedby: [], notInterested: [] });
            }

            if (direction === "right") {
                await updateDoc(currentUserDocRef, {
                    liked: arrayUnion(swipedUserId),
                });

                const swipedUserDocRef = doc(db, "dating", swipedUserId);
                const swipedUserDocSnap = await getDoc(swipedUserDocRef);

                if (!swipedUserDocSnap.exists()) {
                    await setDoc(swipedUserDocRef, { liked: [], likedby: [], notInterested: [] });
                }

                await updateDoc(swipedUserDocRef, {
                    likedby: arrayUnion(currentUserId),
                });

                if (swipedUserDocSnap.data().liked.includes(currentUserId)) {
                    setShowMatch(true);
                }
            } else if (direction === "left") {
                await updateDoc(currentUserDocRef, {
                    notInterested: arrayUnion(swipedUserId),
                });
            }
        } catch (error) {
            console.error(`Error updating ${direction} list:`, error);
        }
    };

    const handleRightSwipe = () => {
        if (totalSwipes === usersData.length || usersData === 0) {
            alert("No More Users");
            setNoMoreSwipesLeft(true);
            return;
        }
        handleSwipe("right");
        setTotalSwipes(totalSwipes + 1);
    };

    const handleLeftSwipe = () => {
        if (totalSwipes === usersData.length || usersData === 0) {
            alert("No More Users");
            setNoMoreSwipesLeft(true);
            return;
        }
        handleSwipe("left");
        setTotalSwipes(totalSwipes + 1);
    };

    const hiddenName = (displayName) => {
        const truncatedName = displayName.substring(0, 2) + "*".repeat(displayName.length - 2);
        return truncatedName;
    };

    useEffect(() => {
        // Fetch and filter liked and notInterested users here
    }, [currentUser.uid, usersData]);

    return (
        <div>
            {console.log(usersData.length)}
            {noMoreSwipesLeft && (
                <p className="error">No user left! Try Again later or Check </p>
            )}
            {showMatch && (
                <div className="matchFullScreen">
                    <div className="container">
                        <p>Cheer's</p>
                        <h2>It's A Match</h2>
                        <img src={cheersbg} alt="cheers" className="cheers" />
                        <img src={datingMatch} alt="matched" className="startDating" />

                        <div className="center">
                            <div className="link">
                                <Link to="/dating">Reveal</Link>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <div className="card-slider" hidden={noMoreSwipesLeft}>
                <div className="profiles">
                    {usersData.map((user, index) => (
                        <div key={user.id} className={`d-card ${index === currentIndex ? "active" : ""}`}>
                            <div
                                className="data"
                                style={{
                                    backgroundImage: `url("${user.photoURL}")`,
                                    animationName:
                                        swipeDirection === "right"
                                            ? "rotateAndSwipeRight"
                                            : swipeDirection === "left"
                                                ? "rotateAndSwipeLeft"
                                                : "",
                                }}
                            >
                                <div className="blur"></div>
                                <div className="info">
                                    <p>
                                        {hiddenName(user.displayName)} <span> {user.gender.substring(0, 1).toUpperCase()}</span>{" "}
                                    </p>
                                    <p>{hiddenName(user.userName)}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                    <div className="controls">
                        <button className="skip" onClick={handleLeftSwipe}>
                            <i className="far fa-times"></i>
                        </button>
                        <button className="like" onClick={handleRightSwipe}>
                            <i className="fas fa-heart"></i>
                        </button>
                        <button className="star">
                            <i className="fas fa-stars"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CardSlider;
