import React, { useContext, useState } from "react";
import "../../style/GroupChat/NewRoomPage.scss";
import { useFormik } from "formik";
import { Toaster, toast } from "react-hot-toast";
import { validateNewRoom } from "../../helper/ValidateForm";
import { useNavigate } from "react-router-dom";
import { nanoid } from "nanoid";
import { AuthContext } from "../../context/AuthContext";

function NewRoomPage() {
  const { userDataFromDb } = useContext(AuthContext);
  const navigate = useNavigate();
  const [isEnterNameSelected, setIsEnterNameSelected] = useState(false);
  const [hideName, setHideName] = useState(true);

  const formik = useFormik({
    initialValues: {
      userName: "",
      roomName: "", // Added roomName to the form
    },
    validate: validateNewRoom,
    onSubmit: async (values) => {
      try {
        const roomCode = nanoid();
        const userId = nanoid();
        const roomName = values.roomName;
        const roomCodeWithName = roomName + roomCode.slice(0,5);
        navigate(`/chat/${roomCodeWithName}`, {
          state: {
            IS_NEW_ROOM: true,
            USER_NAME: isEnterNameSelected
              ? values.userName
              : userDataFromDb.displayName,
            USER_ID: userId,
            ROOM_CODE: roomCodeWithName,
          },
          replace: true,
        });
      } catch (error) {
        toast.error(error.message);
      }
    },
  });

  const selectName = (e) => {
    setIsEnterNameSelected(e.target.value === "entername");
    if (e.target.value === "") {
      setHideName(true);
    } else if (e.target.value === "entername") {
      setHideName(false);
    }
  };

  return (
    <div className="newchatroom">
      <div className="newroom__page">
        <Toaster position="top-center" reverseOrder={false}></Toaster>

        <div className="newroom__contents">
          <h1 className="newroom__heading">Create Room</h1>

          <form className="newroom__form" onSubmit={formik.handleSubmit}>
            <label htmlFor="type">Mode</label>
            <select onChange={selectName} id="type">
              <option value="" selected>Select</option>
              <option value="entername">Anonymous</option>
              <option value={userDataFromDb.displayName}>
                {userDataFromDb.displayName}
              </option>
            </select>

            {isEnterNameSelected && (
              <div hidden={hideName}>
                <label htmlFor="userName">Username</label>
                <br />
                <input
                  type="text"
                  id="userName"
                  name="userName"
                  required
                  autoFocus
                  maxLength={20}
                  onChange={formik.handleChange}
                  value={formik.values.userName}
                  placeholder="Display Name"
                  style={{ textTransform: "capitalize", width: "90%" }}
                />
              </div>
            )}
            {!isEnterNameSelected && (
              <div hidden={hideName}>
                <label htmlFor="userName">Username</label>
                <br />
                <input
                  type="text"
                  id="userName"
                  name="userName"
                  required
                  autoFocus
                  maxLength={20}
                  onChange={formik.handleChange}
                  value={userDataFromDb.displayName}
                  placeholder="Display Name"
                  style={{ textTransform: "capitalize", width: "90%" }}
                  
                />
              </div>
            )}

            <label htmlFor="roomName">Room Name</label>
            <input
              type="text"
              id="roomName"
              name="roomName"
              placeholder="Room Name"
              required
              autoFocus
              maxLength={20}
              onChange={formik.handleChange}
              value={formik.values.roomName}
            />

            {formik.errors.userName && (
              <p className="input__error">{formik.errors.userName}</p>
            )}

            <div className="buttons">
              <button className="create__room__btn" type="submit">
                Create Room
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default NewRoomPage;
