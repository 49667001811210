import React, { useState, useContext, useEffect } from 'react';
import "./ProfileView.scss";
import DatingNavbarTop from '../Navbar/DatingNavbarTop';
import { ToggleSettingsContext } from '../context/mobileToggleContext';

const ProfileView = () => {
    const { toggleSettings, handleToggleSettings } = useContext(ToggleSettingsContext);
    const [activeIndex, setActiveIndex] = useState(0);

    const setClassToActive = (index) => {
        setActiveIndex(index < 0 ? 2 : index % 3);
    }

    let newStyle = {
        backgroundColor: "none"
    }

    if(window.innerWidth < 768) {
        newStyle = {
            display: toggleSettings ? "none" : "flex"
        }
    }


    return (
        <div className="DatingProfile_view">

            <DatingNavbarTop />

            <div className="Dcontainer" style={newStyle}>

                <div className="profile-images">
                    <img className={`profile_image ${activeIndex === 0 ? 'active' : ''}`} src="https://i.ibb.co/s6sDBpT/max-ilienerwise-h1nmvbfumc-M-unsplash.jpg" alt="profile" />
                    <img className={`profile_image ${activeIndex === 1 ? 'active' : ''}`} src="https://i.ibb.co/F7ZPRT7/max-ilienerwise-yn-GCGVJg-Zmo-unsplash.jpg" alt="profile" />
                    <img className={`profile_image ${activeIndex === 2 ? 'active' : ''}`} src="https://i.ibb.co/c1MPLYd/max-ilienerwise-ZEg-UWPf-V5-Yo-unsplash.jpg" alt="profile" />
                    {/* <img className='none' src="https://tmpfiles.org/dl/4057488/clarisse-meyer-x9cxi2eqlbw-unsplash.jpg" alt="profile" /> */}

                    <div className="bigControls">
                        <div className="left" onClick={() => setClassToActive(activeIndex - 1)}></div>
                        <div className="right" onClick={() => setClassToActive(activeIndex + 1)}></div>
                    </div>

                    <div className="image-counter">
                        <div className={`counter ${activeIndex === 0 ? 'active' : ''}`} onClick={() => setClassToActive(0)}></div>
                        <div className={`counter ${activeIndex === 1 ? 'active' : ''}`} onClick={() => setClassToActive(1)}></div>
                        <div className={`counter ${activeIndex === 2 ? 'active' : ''}`} onClick={() => setClassToActive(2)}></div>
                    </div>
                </div>

                <div className="profile-details">
                    <div className="container">
                        <h2>Angle Amrita <i class="fas fa-badge-check"></i></h2>
                        <p className='branch'>B-Tech CSE</p>
                        <h3>21 F</h3>
                        <p>Lovely Professional University</p>

                        <p className="about">
                            Send momos ❤️🧿 clumsy / old Skol / 📻 radio ab perfect date dress pehne ka mauka dedo yaar 🥲 Hookup/casual vale mujse b bahut dur rhe😳 See mrko publicly accept nhi. krskte toh swipe right mt krna
                        </p>
                    </div>

                    <div className="profile-actions">
                        <button className="skip">
                            <i className="far fa-times"></i>
                        </button>
                        <button className="like">
                            <i className="fas fa-heart"></i>
                        </button>
                        <button className="star">
                            <i className="fas fa-stars"></i>
                        </button>
                    </div>

                    <p className='report-profile'> <span><i class="fas fa-pennant"></i> Report this profile</span> </p>

                </div>

            </div>
        </div>
    );
}

export default ProfileView;