import React, { useState, useEffect } from "react";
import "../../style/GroupChat/UserDetail.scss";
import { FaUserNinja } from "react-icons/fa";
import { BiMessageSquareDots } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

import gavatar from '../../assets/gavatar.jpg';
import gavatar1 from '../../assets/gavatar1.jpg';
import gavatar2 from '../../assets/gavatar2.jpg.jpeg';
import RoomSettings from "./RoomSettings";


function UserDetail(props) {
  const [random, setRandom] = useState(0);
  const { userList, USER_ID, typerID, isSomeoneTyping, ROOM_CODE } = props;
  const navigate = useNavigate();

  const handleLeave = function () {
    navigate("/");
  };

  const images = [
    "https://i.pinimg.com/originals/08/4f/8c/084f8ce849c22afd645f2023e537c250.gif",
    "https://i.pinimg.com/originals/62/05/43/6205434fd8b24b4fa239ef3b6eaa4dce.gif",
    "https://i.pinimg.com/originals/07/41/86/07418637f15a9a35ea11497a1bef09bb.gif", "https://img.freepik.com/premium-photo/light-natural-background-with-white-flowers-blurred-background_476363-1856.jpg?w=2000",
    "https://i0.wp.com/i0.wp.com/i.pinimg.com/originals/7e/e9/4a/7ee94a6a9915ac9b0fa06f06474559c0.jpg",
    "https://images.unsplash.com/photo-1611286079235-f4e84fccacba?auto=format&fit=crop&q=80&w=1000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8ZmFpcnklMjBsaWdodHN8ZW58MHx8MHx8fDA%3D",
  ];


  useEffect(() => {
    const interval = setInterval(() => {
      setRandom(Math.floor(Math.random() * images.length));
    }, 20000);
    return () => clearInterval(interval);
  }, [images]);

  const hideMembers = () => {
    document.querySelector(".chatroom__menu").style.width = "0%";
  }

  window.addEventListener("resize", () => {
    if (window.innerWidth > 768) {
      document.querySelector(".chatroom__menu").style.width = "22%";
    }
    else if (window.innerWidth < 768) {
      document.querySelector(".chatroom__menu").style.width = "0%";
    }
  });

  return (
    <>
      <div className="user__detail">
        <div
          className="blur__bg"

          style={{
            backgroundImage: `url(${images[random]})`,
            filter: "blur(3px)",
            display: "none",
          }}
        ></div>
        <div className="user__detail__wrapper">
          <div className="user__header">
            <i className="fas fa-chevron-left default" onClick={handleLeave}></i>
            <i className="fas fa-chevron-left mobileView" onClick={hideMembers}></i>
            <h2 className="nowrap">
              {userList.length > 1
                ? userList.length + " Members"
                : userList.length + " Member"}
            </h2>

          </div>
          <div className="user__names">
            {userList.map((it, index) => (
              <p key={index} className="user">
                {it[1] === USER_ID &&
                  (
                    <div className="king" title="You">
                      <img src={gavatar2} alt="users" />
                      <i className="fas fa-hat-santa"></i>
                    </div>

                  )
                }
                {it[1] !== USER_ID &&
                  (
                    <img src={gavatar || gavatar1} alt="users" />
                  )

                }
                {it[0]}
                {it[1] === typerID && isSomeoneTyping && (
                  <span>
                    <BiMessageSquareDots /> Typing...
                  </span>
                )}
              </p>
            ))}
          </div>
        </div>
        {userList.length >= 1 && (
          <RoomSettings ROOM_CODE={ROOM_CODE} />
        )}
      </div>
    </>
  );
}

export default UserDetail;
