import React, { useState } from "react";
import "../../style/GroupChat/ChatTopBar.scss";
import { useNavigate } from "react-router-dom";
import copy from "copy-to-clipboard";
import { toast } from "react-hot-toast";
import { HiShare } from "react-icons/hi";
import { RxExit } from "react-icons/rx";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import { IoIosCall } from "react-icons/io";
import { FaVideo } from "react-icons/fa";
import { SERVER_DOMAIN } from "../../utils/Constants";
import { reload } from "firebase/auth";

function ChatTopBar({ ROOM_CODE, userList, isTyping }) {

  const navigate = useNavigate();
  const [isDivVisible, setIsDivVisible] = useState(false);
  const [showUser, setShowUser] = useState(false);

  const handleCopy = () => {
    copy(`${ROOM_CODE}`);
    toast.success("Room Code Copied! 📋");
  };

  const handleLeave = function () {
    // navigate(`${SERVER_DOMAIN}/chat/${ROOM_CODE}`);
    window.location.href = `/chat/`;
  };

  const handleOptionsClick = function () {
    // TOGGLE TOPBAR__FLOAT__MENU DIV VISIBILITY
    // setIsDivVisible((prevVisibility) => !prevVisibility);
    toast.error(`This feature is in development mode. 😬`);
  };

  const handleVoiceCall = function () {
    toast.error(`Voice call feature is in development mode. 😬`);
    setIsDivVisible(false);
  };

  const handleVideoCall = function () {
    toast.error(`Video call feature is in development mode. 😬`);
    setIsDivVisible(false);
  };

  const hideMembers = () => {
    document.querySelector(".chatroom__menu").style.width = "110%";
  }

  if (showUser) {
    setShowUser((prevVisibility) => !prevVisibility);
    hideMembers()
  }

  return (
    <div className="chat__topbar">

      <div className="twoColumn">
        <div className="usersNumber" onClick={hideMembers}>
          <i className="fad fa-users-crown"></i>
          <p>{userList.length}</p>
        </div>
        <div className="roomName">
          <h2>{ROOM_CODE.slice(0, -5)} <br />
          </h2>
        </div>
      </div>

      <div className="topbar__btns">

        <div>
          <i className="fal fa-share-alt topbar__icons" onClick={handleCopy} title="Share Room Code"></i>
        </div>
        <div>
          <i className="fal fa-portal-exit topbar__icons" title="Leave Room" onClick={handleLeave}></i>
        </div>

        <div className="topbar__attachment__menu">
          <i className="far fa-ellipsis-v  topbar__icons" onClick={handleOptionsClick} title="More Options"></i>

          {isDivVisible && (
            <div className="topbar__float__menu">
              <p className="nowrap voice__call" onClick={handleVoiceCall}>
                <IoIosCall className="call__icon" />
                Voice Call
              </p>

              <p className="nowrap video__call" onClick={handleVideoCall}>
                <FaVideo className="call__icon" /> Video Call
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ChatTopBar;
