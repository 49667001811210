import React, { useState, useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { db, storage } from "../firebase";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { v4 as uuid } from "uuid";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import chooseImage from "../img/choose.png";
const MAX_CONTENT_LENGTH = 4000;

const modules = {
    toolbar: {
        container: [
            // [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
            // [{ size: [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['link'],
            ['clean']
        ],
        handlers: {
            // Handlers if needed
        }
    },
    clipboard: {
        matchVisual: false,
    },


};

const formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link',
];

const AddNews = () => {
    const { userDataFromDb } = useContext(AuthContext);

    const [newsheading, setNewsHeading] = useState("");
    const [newsType, setNewsType] = useState("");
    const [coverImage, setCoverImage] = useState(null);
    const [newsContent, setNewsContent] = useState("");
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploadComplete, setUploadComplete] = useState("");
    const [errors, setErrors] = useState("");
    const [characterCount, setCharacterCount] = useState(0);

    const handleNewsContentChange = (content, delta, source, editor) => {
        if (containsFileTags()) {
            return false;
        }
        const text = editor.getText();
        setCharacterCount(text.length);
        setNewsContent(content);
    };

    const handlePublish = async (e) => {
        e.preventDefault();

        // Validation checks
        if (!newsheading || !newsType || !newsContent) {
            setErrors("Please fill in all required fields.");
            return;
        }
        if (newsheading.split(" ").length > 20) {
            setErrors("News heading must be 20 Words or less.");
            return;
        }
        if (newsContent.length > MAX_CONTENT_LENGTH) {
            setErrors(`News content must be ${MAX_CONTENT_LENGTH} characters or less.`);
            return;
        }

        if (newsContent.includes("<img") || newsContent.includes("<video")) {
            setErrors("News content cannot contain images or videos.");
            return;
        }

        if (coverImage && !/image\/(jpeg|jpg|png)/.test(coverImage.type)) {
            setErrors("Cover image must be in JPEG format.");
            return;
        }
        if (coverImage && coverImage.size > 1000000) {
            setErrors("Cover image size must be less than 1 MB.");
            return;
        }

        try {
            const date = new Date();
            const month = date.getMonth() + 1;
            const day = date.getDate();
            const year = date.getFullYear();
            const documentName = `${month}-${day}-${year}`;
            const docRef = doc(db, "news", documentName);

            // Upload cover image if available
            if (coverImage) {
                const storageRef = ref(storage, `news/${year}/${month}/${coverImage.name}`);
                const uploadTask = uploadBytesResumable(storageRef, coverImage);

                uploadTask.on(
                    "state_changed",
                    (snapshot) => {
                        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        setUploadProgress(progress);
                    },
                    (error) => {
                        console.error("Error uploading image:", error);
                        setErrors("An error occurred while uploading the image.");
                    },
                    async () => {
                        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

                        // Create new news item
                        const newsItemId = uuid();
                        const newNewsItem = {
                            id: newsItemId,
                            newsheading: newsheading,
                            newsType: newsType,
                            author: userDataFromDb.displayName,
                            coverImage: downloadURL,
                            newsContent: newsContent,
                            isVerified: false,
                            isPublished: false,
                            date: new Date().getTime(),
                        };

                        // Save news item to Firestore
                        const docSnapshot = await getDoc(docRef);
                        if (docSnapshot.exists()) {
                            const existingNewsArray = docSnapshot.data().news || [];
                            existingNewsArray.push(newNewsItem);
                            await setDoc(docRef, { news: existingNewsArray });
                        } else {
                            await setDoc(docRef, { news: [newNewsItem] });
                        }

                        // Reset form fields and display success message
                        resetForm();
                        setUploadComplete("Posted Successfully! Wait for the Approval");
                    }
                );
            } else {
                // Create new news item without cover image
                const newsItemId = uuid();
                const newNewsItem = {
                    id: newsItemId,
                    newsheading: newsheading,
                    newsType: newsType,
                    author: userDataFromDb.displayName,
                    coverImage: "",
                    newsContent: newsContent,
                    isVerified: false,
                    isPublished: false,
                    date: new Date().getTime(),
                };

                // Save news item to Firestore
                const docSnapshot = await getDoc(docRef);
                if (docSnapshot.exists()) {
                    const existingNewsArray = docSnapshot.data().news || [];
                    existingNewsArray.push(newNewsItem);
                    await setDoc(docRef, { news: existingNewsArray });
                } else {
                    await setDoc(docRef, { news: [newNewsItem] });
                }

                // Reset form fields and display success message
                resetForm();
                setUploadComplete("Posted Successfully! Wait for the Approval");
            }
        } catch (error) {
            console.error("Error saving data:", error);
            setErrors("An error occurred while saving data.");
        }
    };

    // Reset form fields
    function resetForm() {
        setNewsHeading("");
        setNewsType("");
        setCoverImage(null);
        setNewsContent("");
        setUploadProgress(0);
        setErrors("");
    }

    // Clear success and error messages after 5 seconds
    setTimeout(() => {
        setUploadComplete("");
        setErrors("");
    }, 5000);

    const containsFileTags = () => {
        const element = document.getElementById("noImage");
        if (!element) return false; // If the element is not found, return false

        // Loop through all child elements
        for (let i = 0; i < element.children.length; i++) {
            const child = element.children[i];
            if (child.tagName === "IMG" || child.tagName === "VIDEO") {
                // If an img or video tag is found, return true
                return true;
            }
        }
        // If no img or video tags are found, return false
        return false;
    };


    return (
        <div className="addNews">
            <p className="news">Got news? University's waiting!</p>
            <form>
                <div className="input-field">
                    <label htmlFor="selectType">What is it?</label>
                    <select
                        id="selectType"
                        value={newsType}
                        onChange={(e) => setNewsType(e.target.value)}
                    >
                        <option value="">Select Type</option>
                        <option value="Tips">Tips</option>
                        <option value="Love">Love</option>
                        <option value="Placement">Placement</option>
                        <option value="Message">Message</option>
                        <option value="Other">Other</option>
                        <option value="Notes">Notes</option>
                    </select>
                </div>

                <div className="input-field">
                    <input
                        type="text"
                        id="title"
                        placeholder="Heading"
                        value={newsheading}
                        onChange={(e) => setNewsHeading(e.target.value)}
                        required
                    />
                </div>

                <div className="img">
                    {!coverImage && (
                        <label htmlFor="coverImage">
                            <img
                                src={chooseImage}
                                className="coverImage"
                                alt="coverimage"
                            />
                            <p style={{ fontSize: "11px", textAlign: "center" }}>Choose a picture</p>
                        </label>
                    )}

                    <input
                        hidden
                        type="file"
                        id="coverImage"
                        onChange={(e) => setCoverImage(e.target.files[0])}
                    />
                    {coverImage && (
                        <img
                            src={URL.createObjectURL(coverImage)}
                            alt="Cover"
                            className="coverImage"
                        />
                    )}
                </div>

                <div className="textarea">
                    <ReactQuill
                        value={newsContent}
                        onChange={handleNewsContentChange}
                        placeholder="Description Check"
                        modules={modules}
                        formats={formats}
                        id="noImage"
                    />
                </div>

                <div className="characterCount">
                    {/* Character Count: {characterCount}/{MAX_CONTENT_LENGTH} */}
                </div>

                <div className="button">
                    <button type="submit" onClick={handlePublish}>
                        Publish
                    </button>
                </div>

                <div className="Status">
                    {uploadProgress > 0 && (
                        <p className="imageuploadStatus">{uploadProgress}% uploaded</p>
                    )}
                    {uploadComplete && (
                        <p className="postedSuccessfully">{uploadComplete}</p>
                    )}
                    <p className="error">{errors}</p>
                </div>
            </form>
        </div>
    );
};

export default AddNews;
