import React, { useState } from 'react';
import UMusic from '../Music/UMusic';
import './TabButtons.scss';

const TabButtons = ({ handleButtonClick }) => {
    const [activeButton, setActiveButton] = useState('Profile'); // Initialize the active button state

    const handleClick = (buttonName) => {
        setActiveButton(buttonName);
        handleButtonClick(buttonName);
    };

    return (
        <div className="tab-buttons">
            <div className="ubuttons">
                <button className={`tab-button ${activeButton === 'Profile' ? 'active' : ''}`} onClick={() => handleClick('Profile')}>
                    <i class="fas fa-id-card"></i>
                    <div>
                        <h3>Profile</h3>
                        <p>Details About Your Personal Informations</p>
                    </div>

                </button>

                <button className={`tab-button ${activeButton === 'Dating' ? 'active' : ''}`} onClick={() => handleClick('Dating')}>
                    <i class="fas fa-box-heart"></i>

                    <div>
                        <h3>Dating</h3>
                        <p>Details About Your Personal Informations</p>
                    </div>

                </button>

                <button className={`tab-button ${activeButton === 'Password & Security' ? 'active' : ''}`} onClick={() => handleClick('Password & Security')}>
                    <i class="fas fa-shield-alt"></i>

                    <div>
                        <h3>Password & Security</h3>
                        <p>Password Change, 2FA, Visibility</p>
                    </div>

                </button>
            </div>

            <UMusic />
        </div>
    );
};

export default TabButtons;
