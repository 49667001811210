import React, { useEffect, useContext, useState } from 'react';
import { AuthContext } from "../context/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';

const ManageUser = ({ user }) => {
    const { currentUser, userDataFromDb } = useContext(AuthContext);
    const [isUpdating, setIsUpdating] = useState(false);
    const [migrated, setMigrated] = useState(false);
    const LIVE_URL = process.env.REACT_APP_LIVE_URL;
    const navigate = useNavigate();

    // Initialize formData state with user data
    const [formData, setFormData] = useState({
        displayName: user.displayName || "",
        dob: "",
        gender: user.gender || "", // Initialize gender with user's gender
        university: "",
        course: "",
        branch: "",
        specialization: "",
        semester: "",
        phone: "",
        address: "",
        bio: "",
        photoFileId: "",
        email: user.email,
        userName: user.userName,
        accountCreated: user.accountCreated,
        isAdmin: user.isAdmin,
        isDating: user.isDating,
        isVerified: user.isVerified,
        isPremium: user.isPremium,
        photoURL: user.photoURL,
        uid: user.uid,
    });

    // Update formData when user prop changes
    useEffect(() => {
        setFormData(prevState => ({
            ...prevState,
            displayName: user.displayName || "",
            gender: user.gender || "",
            email: user.email,
            userName: user.userName,
            accountCreated: user.accountCreated,
            isAdmin: user.isAdmin,
            isDating: user.isDating,
            isVerified: user.isVerified,
            isPremium: user.isPremium,
            photoURL: user.photoURL,
            uid: user.uid,
        }));
    }, [user]);

    const formatDate = (timestamp) => {
        const date = new Date(timestamp);
        const options = {
            day: "2-digit",
            month: "short",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
        };
        return date.toLocaleString("en-US", options);
    };

    const handleChange = (event) => {
        const { id, value } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [id]: value
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsUpdating(true);

        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userDataFromDb.authToken}`
                }
            };

            // Make a copy of formData to avoid directly mutating state
            const updatedFormData = { ...formData };

            // Send a POST request to the backend to update user profile
            const response = await axios.post(`${LIVE_URL}/profile-settings-by-admin`, updatedFormData, config);

            // Handle successful response
            console.log('Profile update successful:', response.data);

        } catch (error) {
            // Handle errors
            console.error('Error updating profile:', error);
        } finally {
            setIsUpdating(false);
        }
    };

    // get if user is in mongodb or not and return true or false



    return (
        <div className='user-data'>
            <div key={user.uid}>
                <div className="basic">
                    <div className="background" style={{ backgroundImage: `url(${user.photoURL})` }}></div>
                    <div className="image">
                        {user.photoURL && <img src={user.photoURL} alt="displayName" />}
                    </div>
                    <div className="name-username">
                        <h1>{user.displayName}</h1>
                        <p>{user.userName}</p>
                        <p className='datejoined'>{formatDate(user.accountCreated)}</p>
                        <p>{user.uid}</p>
                    </div>
                </div>

                <div className="user-data">
                    <h2>{user.displayName}</h2>
                    <p>Email: {user.email}</p>
                    <p>Username: {user.userName}</p>
                    <p>Gender: {user.gender ? user.gender : "Not Updated"}</p>
                    <p>Is Admin: {user.isAdmin.toString()}</p>
                    <p>Is Dating: {user.isDating ? user.isDating.toString() : 'false'}</p>
                    <p>Is Verified: {user.isVerified ? user.isVerified.toString() : 'false'}</p>
                    <p>Is Blocked: {user.isblocked ? user.isblocked.toString() : 'false'}</p>
                    <p>Ready For Dating: {user.readyForDating ? user.readyForDating.toString() : 'false'}</p>
                </div>

                <div className="update-user">

                    <div className="basicInfo">
                        <label htmlFor="userName">
                            <p>Username</p>
                            <input type="text" id='userName' value={user.userName} disabled={true} />
                        </label>

                        <label htmlFor="email">
                            <p>Email</p>
                            <input type="email" id='email' value={user.email} disabled={true} />
                        </label>

                        <label htmlFor="displayName">
                            <p>Name & Display Name</p>
                            <input type="text" id='displayName' value={formData.displayName} onChange={handleChange} />
                        </label>


                        <label htmlFor="gender">
                            <p>Gender</p>
                            <select id="gender" value={formData.gender} onChange={handleChange}>
                                <option value="">Select</option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                                <option value="other">Other</option>
                            </select>
                        </label>

                        <label htmlFor="university">
                            <p>University</p>
                            <select id="university" value={formData.university} onChange={handleChange}>
                                <option value="">Select</option>
                                <option value="LPU (Lovely Professional University)">LPU (Lovely Professional University)</option>
                                <option value="GLA Mathura">GLA Mathura</option>
                            </select>
                        </label>

                    </div>

                    <div className="submit">
                        <button onClick={handleSubmit} disabled={isUpdating} style={{ backgroundColor: migrated ? "green" : "" }} >
                            {isUpdating ? "Migrating..." : "Migrate"}
                        </button>

                    </div>
                    {
                        migrated && <p style={{ color: "green", textAlign: "center" }} >User migrated successfully</p>
                    }

                    <div className="admin-action">
                        {/* block | dating false |*/}

                        {
                            user.isblocked ? <button type='button'  ><i className="fas fa-user-slash"></i> Unblock User </button> : <button type='button' style={{ backgroundColor: "red" }} ><i className="fas fa-user-slash"></i> Block User </button>
                        }

                        {
                            user.isDating ? <button type='button' ><i className="fas fa-heart-broken"></i> Stop Dating </button> : <button type='button' ><i className="fas fa-heart"></i> Start Dating </button>
                        }

                        {
                            user.isVerified ? <button type='button' ><i className="fas fa-user-check"></i> Unverify User </button> : <button type='button' ><i className="fas fa-user-times"></i> Verify User </button>
                        }

                        {
                            user.isAdmin ? <button type='button' ><i className="fas fa-user-shield"></i> Remove Admin </button> : <button type='button' style={{ backgroundColor: "red" }} ><i className="fas fa-user-shield"></i> Make Admin </button>
                        }

                    </div>

                </div>
            </div>
        </div>
    )
}

export default ManageUser;
