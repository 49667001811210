function initparticles() {
    bubbles();
    hearts();
    lines();
    confetti();
    fire();
    sunbeams();
}

function bubbles() {
    const bubbleElements = document.querySelectorAll(".particletext.bubbles");
    bubbleElements.forEach((element) => {
        const bubblecount = (element.offsetWidth / 50) * 10;
        for (let i = 0; i <= bubblecount; i++) {
            const size = (Math.random() * 4) + 2;
            const top = Math.random() * 60 + 20;
            const left = Math.random() * 95;
            const delay = Math.random() * 3;
            const bubble = document.createElement('span');
            bubble.className = 'particle';
            bubble.style.top = top + '%';
            bubble.style.left = left + '%';
            bubble.style.width = size + 'px';
            bubble.style.height = size + 'px';
            bubble.style.animationDelay = delay + 's';
            element.appendChild(bubble);
        }
    });
}

function hearts() {
    const heartElements = document.querySelectorAll(".particletext.hearts");
    heartElements.forEach((element) => {
        const heartcount = (element.offsetWidth / 50) * 5;
        for (let i = 0; i <= heartcount; i++) {
            const size = (Math.random() * 6) + 6; // Adjust size range as needed
            const top = Math.random() * 60 + 20;
            const left = Math.random() * 95;
            const delay = Math.random() * 3;
            const heart = document.createElement('span');
            heart.className = 'particle';
            heart.style.top = top + '%';
            heart.style.left = left + '%';
            heart.style.width = size + 'px';
            heart.style.height = size + 'px';
            heart.style.animationDelay = delay + 's';
            element.appendChild(heart);
        }
    });
}

function lines() {
    const lineElements = document.querySelectorAll(".particletext.lines");
    lineElements.forEach((element) => {
        const linecount = (element.offsetWidth / 50) * 10;
        for (let i = 0; i <= linecount; i++) {
            const width = Math.random() * 2 + 1;
            const height = Math.random() * 60 + 20;
            const top = Math.random() * 60 - 30;
            const left = Math.random() * 120 - 10;
            const delay = Math.random() * 3;
            const line = document.createElement('span');
            line.className = 'particle';
            line.style.top = top + '%';
            line.style.left = left + '%';
            line.style.width = width + 'px';
            line.style.height = height + '%';
            line.style.animationDelay = '-' + delay + 's';
            element.appendChild(line);
        }
    });
}

function confetti() {
    const confettiElements = document.querySelectorAll(".particletext.confetti");
    confettiElements.forEach((element) => {
        const confetticount = (element.offsetWidth / 50) * 10;
        for (let i = 0; i <= confetticount; i++) {
            const size = Math.random() * 2 + 6;
            const top = Math.random() * 40 + 10;
            const left = Math.random() * 100;
            const delay = Math.random() * 3;
            const confetti = document.createElement('span');
            confetti.className = 'particle c' + Math.ceil(Math.random() * 5);
            confetti.style.top = top + '%';
            confetti.style.left = left + '%';
            confetti.style.width = size + 'px';
            confetti.style.height = size + 'px';
            confetti.style.boxShadow = '0 0 10px rgba(255, 255, 255, 0.3)';
            confetti.style.animationDelay = delay + 's';
            element.appendChild(confetti);
        }
    });
}

function fire() {
    const fireElements = document.querySelectorAll(".particletext.fire");
    fireElements.forEach((element) => {
        const firecount = (element.offsetWidth / 50) * 20;
        for (let i = 0; i <= firecount; i++) {
            const size = Math.random() * 4 + 8;
            const top = Math.random() * 30 + 40;
            const left = Math.random() * 110 - 10;
            const delay = Math.random() * 2;
            const fire = document.createElement('span');
            fire.className = 'particle';
            fire.style.top = top + '%';
            fire.style.left = left + '%';
            fire.style.width = size + 'px';
            fire.style.height = size + 'px';
            fire.style.animationDelay = delay + 's';
            element.appendChild(fire);
        }
    });
}

function sunbeams() {
    const sunbeamElements = document.querySelectorAll(".particletext.sunbeams");
    sunbeamElements.forEach((element) => {
        const linecount = (element.offsetWidth / 50) * 10;
        for (let i = 0; i <= linecount; i++) {
            const width = Math.random() * 2 + 1;
            const height = Math.random() * 80 + 80;
            const top = Math.random() * 50 - 50;
            const left = Math.random() * 100;
            const delay = Math.random() * 3;
            const sunbeam = document.createElement('span');
            sunbeam.className = 'particle';
            sunbeam.style.top = top + '%';
            sunbeam.style.left = left + '%';
            sunbeam.style.width = width + 'px';
            sunbeam.style.height = height + '%';
            sunbeam.style.animationDelay = '-' + delay + 's';
            element.appendChild(sunbeam);
        }
    });
}

export default initparticles;