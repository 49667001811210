import { doc, onSnapshot, updateDoc } from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import { ChatContext } from "../context/ChatContext";
import { db } from "../firebase";
import CryptoJS from "crypto-js";

const Chats = () => {
  const [chats, setChats] = useState([]); // Initialize to an empty array
  const [selectedChatId, setSelectedChatId] = useState(null); // State to keep track of the selected chat ID

  const { currentUser, userDataFromDb } = useContext(AuthContext);
  const { dispatch } = useContext(ChatContext);

  const secretKey = process.env.REACT_APP_CHAT_DECRYPT_KEY;

  // console.log("Chats:", chats)

  useEffect(() => {
    const getChats = () => {
      const unsub = onSnapshot(doc(db, "userChats", currentUser.uid), (doc) => {
        setChats(Object.entries(doc.data())); // Convert the object to an array of key-value pairs
      });

      return () => {
        unsub();
      };
    };

    currentUser.uid && getChats();
  }, [currentUser.uid]);

  const handleSelect = async (chatId, userInfo) => {
    setSelectedChatId(chatId); // Set the selected chat ID in the state
    dispatch({ type: "CHANGE_USER", payload: userInfo });

    try {
      // Update the lastSeen property of the lastMessage in the selected chat
      await updateDoc(doc(db, "userChats", currentUser.uid), {
        [chatId + ".lastMessage.lastSeen"]: true,
      })

      //console.log(userInfo.uid + " User Info")

      await updateDoc(doc(db, "userChats", userInfo.uid), {
        [chatId + ".userInfo.displayName"]: userDataFromDb.displayName,
        [chatId + ".userInfo.photoURL"]: userDataFromDb.photoURL,
      })

      // console.log("Last seen updated successfully!");
    } catch (error) {
      console.error("Error updating last seen. Wait and see if it gets fixed else you can upload the screenshot on support page.",);
    }
  };

  if (chats.length === 0) {
    // If chats array is empty, it means the data is being fetched or there are no chats.
    // You can show a loading indicator or return a message to indicate there are no chats.
    return <div style={{ textAlign: "center" }}>No chats available.</div>;
  }

  const handleDecrypt = (normalText, secretkey) => {
    try {
      const bytes = CryptoJS.AES.decrypt(normalText, secretkey);
      const decryptedText = bytes.toString(CryptoJS.enc.Utf8);
      return decryptedText;
    } catch (error) {
      //console.error("Decryption Error:", error.message);
    }
  };

  function isMobileDevice() {
    return window.innerWidth <= 768; // Adjust the width threshold as needed
  }

  const handleLayout = () => {
    // alert("Layout Changed");

    const sidebar = document.querySelector(".home .container .sidebar");
    const sidebar_chats = document.querySelector(".home .container .chats");
    const sidebar_search = document.querySelector(".home .container .search");
    const chat = document.querySelector(".home .container .chat");
    const userData = document.querySelector(".home .container .sidebar .navbar .user");
    const navbar = document.querySelector(".App .sidebarLeft");

    const centeralComponents = document.querySelector(".App .centerComponent");
    const home = document.querySelector(".home");
    const container = document.querySelector(".home .container");

    // navbar.style.backgroundColor ="red";
    // navbar.style.display = "none";
    userData.style.display = "none";
    sidebar_search.style.display = "none";
    sidebar_chats.style.display = "none";
    sidebar.style.width = "0%";
    chat.style.width = "100%";
  };

 
  return (
    <div className="chats" >
      {chats
        .sort((a, b) => b[1]?.date?.toDate() - a[1]?.date?.toDate()) // Sort chats based on date
        .map((chat, index) => (
          <div
            className={`userChat ${selectedChatId === chat[0] ? "selected-message" : ""}`}
            key={chat[0]}
            onClick={() => {
              handleSelect(chat[0], chat[1].userInfo);
              if (isMobileDevice()) {
                handleLayout();
              }
            }}
          >
            <img src={chat[1].userInfo.photoURL} alt="" className="avatar" />
            <div className="userChatInfo">
              <div className="name-message">
                <p className="name">{chat[1].userInfo.displayName}</p>
                <p className="message">{handleDecrypt(chat[1]?.lastMessage?.text, secretKey)}</p>
              </div>
              <div className="time">
                <p>
                  {chat[1]?.date?.toDate().toLocaleString("en-US", {
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                  })}
                </p>
                <p className="read">{chat[1]?.lastMessage?.lastSeen ? <i className="fal fa-check-double"></i> : <i className="fal fa-check"></i>}</p>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default Chats;
