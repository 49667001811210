import React, { useState, useEffect } from 'react';
import "../style/ConfessionTimer.scss";
import ribbon from "../img/ribbon3.png";
import ribbon4 from "../img/ribbon4.png";
import ribbon1 from "../img/ribbon.png";
import TimerCard from './TimerCard';
import ConfessionCard from './ConfessionCard';
import { useLocation } from "react-router-dom";
import TimerCardAlt from './TimerCardAlt';

const TimeComponent = () => {
    const [formattedTime, setFormattedTime] = useState('');
    const [timeRemaining, setTimeRemaining] = useState('');
    const [showConfessionCard, setShowConfessionCard] = useState(false);
    const [targetTimeSlotStart, setTargetTimeSlotStart] = useState(200000);
    const [targetTimeSlotEnd, setTargetTimeSlotEnd] = useState(210000);
    const location = useLocation();

    useEffect(() => {
        const updateTimer = () => {
            const now = new Date();
            const hours = now.getHours().toString().padStart(2, '0');
            const minutes = now.getMinutes().toString().padStart(2, '0');
            const seconds = now.getSeconds().toString().padStart(2, '0');
            setFormattedTime(`${hours}:${minutes}:${seconds}`);

            const currentTime = parseInt(hours + minutes + seconds, 10);

            if (currentTime < targetTimeSlotStart) {
                const targetTime = new Date();
                targetTime.setHours(Math.floor(targetTimeSlotStart / 10000));
                targetTime.setMinutes(Math.floor((targetTimeSlotStart % 10000) / 100));
                targetTime.setSeconds(targetTimeSlotStart % 100);

                const diff = Math.max(targetTime - now, 0);
                setTimeRemaining(formatTimeDiff(diff) + "");
                setShowConfessionCard(false);
            } else if (currentTime >= targetTimeSlotStart && currentTime <= targetTimeSlotEnd) {
                setShowConfessionCard(true);
                setTimeRemaining("");
            } else {
                const targetTime = new Date();
                targetTime.setDate(targetTime.getDate() + 1);
                targetTime.setHours(Math.floor(targetTimeSlotStart / 10000));
                targetTime.setMinutes(Math.floor((targetTimeSlotStart % 10000) / 100));
                targetTime.setSeconds(targetTimeSlotStart % 100);

                const diff = Math.max(targetTime - now, 0);
                setTimeRemaining(formatTimeDiff(diff) + "");
                setShowConfessionCard(false);
            }

            setTimeout(updateTimer, 1000);
        };

        updateTimer();

        return () => {
            // Clean up the timeout when the component unmounts
            clearTimeout(updateTimer);
        };
    }, [targetTimeSlotStart, targetTimeSlotEnd]);

    const formatTimeDiff = (diff) => {
        const hoursDiff = Math.floor(diff / (60 * 60 * 1000)).toString().padStart(2, '0');
        const minutesDiff = Math.floor((diff % (60 * 60 * 1000)) / (60 * 1000)).toString().padStart(2, '0');
        const secondsDiff = Math.floor((diff % (60 * 1000)) / 1000).toString().padStart(2, '0');
        return `${hoursDiff}:${minutesDiff}:${secondsDiff}`;
    };

    const formatTimeIn12HourFormat = (time) => {
        const hours = Math.floor(time / 10000);
        const minutes = Math.floor((time % 10000) / 100);
        const period = hours >= 12 ? 'PM' : 'AM';
        const formattedHours = hours === 0 ? 12 : hours > 12 ? hours - 12 : hours;
        const formattedMinutes = minutes.toString().padStart(2, '0');
        return `${formattedHours}:${formattedMinutes} ${period}`;
    };

    return (
        <div className='timer-main'>
        {showConfessionCard ? (
            <div>
                {location.pathname.includes("confession") ? (
                    <ConfessionCard />
                ) : (
                    ""
                )}
            </div>
        ) : (
            <>
                {location.pathname.includes("confession") ? (
                    <TimerCard
                        ribbon={ribbon}
                        ribbon4={ribbon4}
                        ribbon1={ribbon1}
                        formatTimeIn12HourFormat={formatTimeIn12HourFormat}
                        targetTimeSlotStart={targetTimeSlotStart}
                        targetTimeSlotEnd={targetTimeSlotEnd}
                        timeRemaining={timeRemaining}
                    />
                ) : (
                    <TimerCardAlt
                        formatTimeIn12HourFormat={formatTimeIn12HourFormat}
                        timeRemaining={timeRemaining}
                        ribbon={ribbon}
                        content={""} // Provide empty content
                    />
                )}
            </>
        )}
    </div>
    );
};

export default TimeComponent;
