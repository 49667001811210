import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faUtensils, faDashboard } from '@fortawesome/free-solid-svg-icons';

const Food_Sidebar = () => {
  const navigate = useNavigate();
  const [university, setUniversity] = useState('lovely-professional-university');

  const handleUniversityChange = (event) => {
    const selectedUniversity = event.target.value;
    setUniversity(selectedUniversity);
    navigate(`/nearby-restaurants/${selectedUniversity}`);
  };

  return (
    <div className="sidebar">
      <Link to="/food" className="menu-item active"><FontAwesomeIcon icon={faDashboard} /> Dashboard</Link>
      <div className="menu-item">
        {/* University icon */}
        <FontAwesomeIcon icon={faHome} />
        <select onChange={handleUniversityChange} value={university}>
          <option value="lovely-professional-university">Lovely Professional University</option>
          <option value="gla-university-mathura">GLA University Mathura</option>
        </select>
      </div>
    </div>
  );
};

export default Food_Sidebar;
